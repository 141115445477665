import { useMutation } from '@apollo/client';
import { Box, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AnswerDocument,
  BasicChoiceFragment,
  ClientTurnFragment,
  GameOptions,
} from '../../../graphql/types';
import { snackbarErrorConfig } from '../../../utils/Snackbars/snackbar.configs';
import GenericButton from '../../GenericButton';
import PlayerChoiceOption from './PlayerChoiceOption';
import CheckIcon from '@mui/icons-material/Check';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';

const ClientGameQuestion: React.FC<{
  currentTurn: ClientTurnFragment;
  gameOptions: GameOptions;
  turnCount: number;
}> = ({ currentTurn, gameOptions, turnCount }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  // The choice currently clicked on
  const [selectedChoice, setSelectedChoice] =
    useState<BasicChoiceFragment | null>(null);

  // The choice submitted as an answer and saved in the database
  const [sentChoice, setSentChoice] = useState<BasicChoiceFragment | null>(
    null,
  );

  // Set display on turn change
  useEffect(() => {
    setSelectedChoice(currentTurn?.myAnswer?.choice ?? null);
    setSentChoice(currentTurn?.myAnswer?.choice ?? null);
  }, [currentTurn?.myAnswer?.choice]);

  // The correct answer to the question
  const [correctChoice, setCorrectChoice] =
    useState<BasicChoiceFragment | null>(null);

  useEffect(() => {
    setCorrectChoice(
      currentTurn.question.choices.filter((choice) => choice.isCorrect)[0],
    );
  }, [currentTurn.endedAt, currentTurn.question.choices]);

  const [sendAnswer] = useMutation(AnswerDocument);

  const sendAnswerCallback = () => {
    if (
      selectedChoice?.id === sentChoice?.id ||
      currentTurn.endedAt ||
      (gameOptions.hasOneTryPerQuestion && sentChoice)
    ) {
      return;
    }

    sendAnswer({
      variables: {
        choiceId: selectedChoice?.id ?? null,
        turnId: currentTurn.id,
      },
    })
      .then(() => {
        setSentChoice(selectedChoice);
      })
      .catch((err) => {
        enqueueSnackbar(err.message, snackbarErrorConfig);
      });
  };

  // Quick Response: automatically send the player response when chosen.
  useEffect(() => {
    if (selectedChoice && gameOptions.quickResponse) {
      sendAnswerCallback();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedChoice, gameOptions.quickResponse]);

  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        textAlign: 'center',
        padding: '20px 10px 60px 10px',
        overflow: 'auto',
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography variant="h2">{currentTurn?.index}</Typography>
          <Typography variant="h5" sx={{ color: 'text.secondary' }}>
            /{turnCount}
          </Typography>
        </Box>
        <Typography variant="h4">{currentTurn.question.title}</Typography>
      </Box>
      <Box
        sx={{
          paddingX: '10px',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          gap: '15px',
        }}
      >
        {currentTurn.question.choices.map((choice, index) => (
          <PlayerChoiceOption
            key={index}
            selected={
              currentTurn.endedAt === null && selectedChoice?.id === choice.id
            }
            sent={currentTurn.endedAt === null && sentChoice?.id === choice.id}
            correct={
              currentTurn.endedAt !== null &&
              correctChoice !== null &&
              choice.id === correctChoice.id
            }
            wrong={
              currentTurn.endedAt !== null &&
              sentChoice !== null &&
              correctChoice !== null &&
              sentChoice.id === choice.id &&
              choice.id !== correctChoice.id
            }
            disabled={
              (gameOptions.hasOneTryPerQuestion === true &&
                sentChoice !== null) ||
              currentTurn.endedAt !== null
            }
            onClick={() => setSelectedChoice(choice)}
            choiceText={choice.value}
          />
        ))}
      </Box>
      {!currentTurn.endedAt ? (
        <Box>
          {!gameOptions.quickResponse && (
            <GenericButton
              onClick={sendAnswerCallback}
              disabled={
                !selectedChoice ||
                (gameOptions.hasOneTryPerQuestion === true &&
                  sentChoice !== null)
              }
              label={
                sentChoice && sentChoice.id === selectedChoice?.id
                  ? t('button.label.sent', 'Sent')
                  : t('button.label.send', 'Send')
              }
              endIcon={
                sentChoice && sentChoice.id === selectedChoice?.id ? (
                  <CheckIcon />
                ) : (
                  <SendOutlinedIcon />
                )
              }
              context={
                sentChoice && sentChoice.id === selectedChoice?.id
                  ? 'success'
                  : 'primary'
              }
            />
          )}
        </Box>
      ) : (
        <Box>
          <Typography variant="h4" sx={{ color: 'white' }}>
            {sentChoice !== null &&
            correctChoice !== null &&
            sentChoice.id === correctChoice.id
              ? t('label.wellPlayed', 'Well done!')
              : t('label.tooBad', 'Oh, no...')}
          </Typography>
          <Typography variant="body1" sx={{ color: 'white' }}>
            {t('lable.nextTurnIncoming', 'The next question will start soon.')}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default ClientGameQuestion;
