import {
  ApolloLink,
  Operation,
  FetchResult,
  Observable,
} from '@apollo/client/core';
import { Subscription, SubscriptionOptions } from 'sse-z';
import { print } from 'graphql';

export class SSELink extends ApolloLink {
  options: SubscriptionOptions;
  subscription?: Subscription;

  constructor(options: SubscriptionOptions) {
    super();
    this.options = options;
  }

  public request({
    query,
    variables,
    operationName,
  }: Operation): Observable<FetchResult> {
    return new Observable((sink) => {
      const token = localStorage.getItem('accessToken');
      const subscription = new Subscription({
        ...this.options,
        eventSourceOptions: {
          headers: {
            authorization: token ? `Bearer ${token}` : null,
          },
        },
        searchParams: {
          query: print(query),
          variables: JSON.stringify(variables),
          operationName,
        },
        onNext: (data) => {
          sink.next(JSON.parse(data));
        },
      });
      this.subscription = subscription;
      return () => subscription.unsubscribe();
    });
  }
}
