import styled from '@emotion/styled';

export const ListItemContainer = styled.div`
  display: flex;
  flex: 1;
  padding: 10px;
  border-radius: 10px;
  height: 160px;
  :hover {
    background-color: #efefef1a;
  }
  :active {
    background-color: #efefef21;
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  width: 100%;
  height: 1.375rem;
`;
