import { OptionsObject } from 'notistack';

export const snackbarErrorConfig: OptionsObject = {
  variant: 'error',
  autoHideDuration: 3000,
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
};

export const snackbarSuccessConfig: OptionsObject = {
  variant: 'success',
  autoHideDuration: 3000,
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
};
