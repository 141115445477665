import styled from '@emotion/styled';
import { Box } from '@mui/material';
import GoldCrown from '../../../../images/gold_crown.svg';
import SilverMedal from '../../../../images/silver_medal.svg';
import BronzeMedal from '../../../../images/bronze_medal.svg';

export const MedalContainer = styled(Box)<{ rank: number }>`
  height: 120px;
  aspect-ratio: ${({ rank }) => (rank === 1 ? '1.35' : '1')};
  background-image: ${({ rank }) => {
    switch (rank) {
      case 1:
        return `url(${GoldCrown})`;
      case 2:
        return `url(${SilverMedal})`;
      case 3:
        return `url(${BronzeMedal})`;
      default:
        return null;
    }
  }};
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`;
