import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
  split,
} from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import {
  getMainDefinition,
  relayStylePagination,
} from '@apollo/client/utilities';
import { StrictTypedTypePolicies } from '../graphql/apollo-helpers';
import generatedIntrospection from '../graphql/introspection';
import { SSELink } from './SSELink';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8080';

export default function createApolloClient(): ApolloClient<NormalizedCacheObject> {
  const url = `${API_URL}/graphql`;

  const httpLink = new HttpLink({
    uri: url,
  });

  const sseLink = new SSELink({
    url,
  });

  const authLink = setContext((_, { headers }) => {
    const token = localStorage.getItem('accessToken');

    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : null,
      },
    };
  });

  // The split function takes three parameters:
  //
  // * A function that's called for each operation to execute
  // * The Link to use for an operation if the function returns a "truthy" value
  // * The Link to use for an operation if the function returns a "falsy" value
  const link = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    sseLink,
    authLink.concat(httpLink),
  );

  const typePolicies: StrictTypedTypePolicies = {
    Game: {
      fields: {
        mcqs: relayStylePagination(),
        ranking: relayStylePagination(),
        turns: relayStylePagination(),
      },
    },
    MCQ: {
      fields: {
        questions: relayStylePagination(),
      },
    },
    Player: {
      fields: {
        history: relayStylePagination(),
      },
    },
    Query: {
      fields: {
        globalMcqs: relayStylePagination(),
        spaces: relayStylePagination(),
      },
    },
    Space: {
      fields: {
        admins: relayStylePagination(),
        games: relayStylePagination(),
        openedGames: relayStylePagination(),
        closedGames: relayStylePagination(),
        mcqs: relayStylePagination(),
        players: relayStylePagination(),
      },
    },
    Turn: {
      fields: {
        answers: relayStylePagination(),
        correctAnswers: relayStylePagination(),
      },
    },
  };

  const cache = new InMemoryCache({
    possibleTypes: generatedIntrospection.possibleTypes,
    typePolicies,
  });

  return new ApolloClient({
    link,
    cache,
  });
}
