import { Theme } from '@emotion/react';
import styled from '@emotion/styled';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

interface ScoreVariationProps {
  variant: number;
}

const omitVariant = (prop: string) => prop !== 'variant';

const scoreVariation = ({
  theme,
  variant,
}: { theme: Theme } & ScoreVariationProps) => {
  if (variant > 0) {
    return theme.colors.lightBlue;
  }
  if (variant < 0) {
    return theme.colors.dangerRed;
  }
  return theme.colors.mediumGrey;
};

export const OldScore = styled.p`
  color: ${({ theme }) => theme.colors.mediumGrey};
`;

export const ScoreVariation = styled('p', {
  shouldForwardProp: omitVariant,
})<ScoreVariationProps>`
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  margin-left: 5px;
  margin-right: 2px;
  color: ${scoreVariation};
`;

export const ScoreIcon = styled(ArrowForwardIcon)`
  width: 2rem;
  height: 2rem;
  color: ${({ theme }) => theme.colors.mediumGrey};
`;

export const NewScore = styled('p', {
  shouldForwardProp: omitVariant,
})`
  font-size: 1.2rem;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${scoreVariation};
`;
