import { Box } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { TimeSlider } from './styled';

type ProgressBarProps = {
  value: number;
  onSeek: (newValue: number) => void;
};

const ProgressBar: React.FC<ProgressBarProps> = ({ value, onSeek }) => {
  const [progressTime, setProgressTime] = useState<number | null>(null);

  const onChange = useCallback((event: Event, newValue: number | number[]) => {
    setProgressTime(newValue as number);
  }, []);

  const onChangeCommitted = useCallback(
    (event: any, newValue: number | number[]) => {
      setProgressTime(null);
      onSeek(newValue as number);
    },
    [onSeek],
  );

  return (
    <Box sx={{ margin: '0 1.5rem 0 1rem', width: '100%', minWidth: '6rem' }}>
      <TimeSlider
        value={progressTime || value}
        onChange={onChange}
        onChangeCommitted={onChangeCommitted}
      />
    </Box>
  );
};

export default ProgressBar;
