import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { BasicParticipationFragment } from '../../../../graphql/types';
import { ParticipantRank, RankImage } from './styled';
import GoldCrown from '../../../../images/gold_crown.svg';
import SilverMedal from '../../../../images/silver_medal.svg';
import BronzeMedal from '../../../../images/bronze_medal.svg';
import { useMemo } from 'react';
import i18n from '../../../../i18n';
import suffixMap from './suffix.map.json';
import PlayerAvatar from '../../../PlayerAvatar';

const PlayerNavRanking: React.FC<{
  me: BasicParticipationFragment;
}> = ({ me }) => {
  const { t } = useTranslation();

  const RankDisplay = useMemo(() => {
    const locale: string = i18n.language;

    let displayRank: string | number = me.rank;

    if (locale === 'en' || locale === 'fr') {
      const localeOrdinalSuffix = new Intl.PluralRules(locale, {
        type: 'ordinal',
      });

      const suffixes: { [key: string]: string } = suffixMap[locale];

      displayRank = `${me.rank}${
        suffixes[localeOrdinalSuffix.select(me.rank)]
      }`;
    }

    switch (me.rank) {
      case 0:
        return <ParticipantRank rank={4}>--</ParticipantRank>;
      case 1:
        return (
          <RankImage
            alt={displayRank.toString()}
            src={GoldCrown}
            rank={me.rank}
          />
        );
      case 2:
        return (
          <RankImage
            alt={displayRank.toString()}
            src={SilverMedal}
            rank={me.rank}
          />
        );
      case 3:
        return (
          <RankImage
            alt={displayRank.toString()}
            src={BronzeMedal}
            rank={me.rank}
          />
        );
      default:
        return <ParticipantRank rank={me.rank}>{displayRank}</ParticipantRank>;
    }
  }, [me.rank]);

  return (
    <Box
      sx={{
        borderRadius: '10px',
        backgroundColor: '#FFFFFF0D',
        padding: '10px',
        display: 'flex',
        flexDirection: 'row',
        gap: '5px',
        alignItems: 'center',
        cursor: 'pointer',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        {RankDisplay}
        <div>
          <Typography
            variant="h5"
            sx={{ fontSize: '14px', fontWeight: 700, display: 'inline' }}
          >
            {me.score}
          </Typography>
          <Typography
            variant="h5"
            sx={{ fontSize: '14px', fontWeight: 200, display: 'inline' }}
          >
            {' '}
            {t('label.pts', 'pts')}
          </Typography>
        </div>
      </Box>
      <PlayerAvatar name={me.player.name} />
    </Box>
  );
};

export default PlayerNavRanking;
