import { useQuery } from '@apollo/client';
import { Box, Modal, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  GameRankingDocument,
  GameResultDocument,
} from '../../../graphql/types';
import { useGameId } from '../../../hooks/useGameId';
import { useSpaceId } from '../../../hooks/useSpaceId';
import FullscreenLoader from '../../FullscreenLoader';
import GenericButton from '../../GenericButton';
import AdminGameHeader from '../AdminGameHeader';
import GameSummary from './GameSummary';
import { ScrollableScoreBoard } from './styled';

const AdminGameResult: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const gameId = useGameId();
  const spaceId = useSpaceId();
  const { data } = useQuery(GameResultDocument, {
    variables: { id: gameId },
  });

  const gameRanking = useQuery(GameRankingDocument, {
    variables: { id: gameId },
    notifyOnNetworkStatusChange: true,
  });

  const game = data?.gameById;
  const ranking = gameRanking.data?.gameById.ranking;

  const [isGameSummaryOpen, setGameSummaryOpen] = useState(false);

  const leaveGame = () => {
    navigate(`/admin/${spaceId}/games`);
  };

  if (!game || !ranking) {
    return <FullscreenLoader />;
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          height: '100%',
          paddingTop: '15px',
        }}
      >
        <AdminGameHeader game={game} />
        <Box
          sx={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            overflow: 'auto',
            paddingBottom: '15px',
          }}
        >
          <Typography
            variant="h2"
            sx={{ textAlign: 'center', paddingY: '2rem' }}
          >
            {t('label.gameIsFinished', 'The game is finished')}
          </Typography>
          <ScrollableScoreBoard
            gameContext="gameEnd"
            ranking={ranking.edges}
            loadingMore={gameRanking.loading}
            loadMore={() => {
              if (ranking.pageInfo.hasNextPage && ranking.pageInfo.endCursor) {
                gameRanking.fetchMore({
                  variables: {
                    cursor: ranking.pageInfo.endCursor,
                  },
                });
              }
            }}
          />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <GenericButton
              variant="text"
              label={t('action.openGameSummary', 'Open game summary')}
              onClick={() => setGameSummaryOpen(true)}
            />
            <GenericButton
              onClick={leaveGame}
              label={t('action.goBackToHome', 'Go back to home')}
            />
          </Box>
        </Box>
      </Box>
      <Modal open={isGameSummaryOpen} onClose={() => setGameSummaryOpen(false)}>
        <GameSummary
          gameId={gameId}
          handleClose={() => setGameSummaryOpen(false)}
        />
      </Modal>
    </>
  );
};

export default AdminGameResult;
