import { useMutation, useQuery } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import { AppBar, Box, Modal, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  DeleteSpaceDocument,
  ReadSpacesDocument,
  ReadSpacesQuery,
} from '../../graphql/types';
import FullscreenLoader from '../FullscreenLoader';
import GenericButton from '../GenericButton';
import ModalConfirmation from '../ModalConfirmation';
import SpaceForm from './SpaceForm';
import SpaceListItem from './SpaceListItem';

type PartialSpace = ReadSpacesQuery['spaces']['edges'][number]['node'];

const SpaceList: React.FC = () => {
  const { t } = useTranslation();
  const { loading, data } = useQuery(ReadSpacesDocument);
  const [isFormModalOpen, setIsFormModalOpen] = useState(false);
  const [isDeletionModalOpen, setIsDeletionModalOpen] = useState(false);
  const [selectedSpace, setSelectedSpace] = useState<
    PartialSpace | undefined
  >();
  let navigate = useNavigate();

  const [deleteSpace] = useMutation(DeleteSpaceDocument, {
    variables: selectedSpace
      ? {
          id: selectedSpace.id,
        }
      : undefined,
    update(cache, { data }) {
      if (data) {
        cache.updateQuery({ query: ReadSpacesDocument }, (spacesData) =>
          spacesData
            ? {
                spaces: {
                  ...spacesData.spaces,
                  totalCount: spacesData.spaces.totalCount - 1,
                  edges: spacesData.spaces.edges.filter(
                    (space) => space.node.id !== selectedSpace?.id,
                  ),
                },
              }
            : undefined,
        );
      }
    },
  });

  const navigateToSpace = (space: PartialSpace) => {
    navigate(`/admin/${space.id}/games`);
  };

  const openEditModal = (space: PartialSpace) => {
    setSelectedSpace(space);
    setIsFormModalOpen(true);
  };

  const openDeleteModal = (space: PartialSpace) => {
    setSelectedSpace(space);
    setIsDeletionModalOpen(true);
  };

  const confirmationCallback = async () => {
    await deleteSpace();
    setIsDeletionModalOpen(false);
    setSelectedSpace(undefined);
  };

  const handleClose = () => {
    setSelectedSpace(undefined);
    setIsDeletionModalOpen(false);
    setIsFormModalOpen(false);
  };

  const spaces = data?.spaces;

  if (!spaces || loading) {
    return <FullscreenLoader />;
  }

  return (
    <Box
      sx={{ display: 'flex', flex: 1, flexDirection: 'column', height: '100%' }}
    >
      <AppBar
        position="sticky"
        color="primary"
        sx={{
          padding: '20px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          zIndex: 2,
          backgroundColor: 'transparent',
          backgroundImage: 'none',
          boxShadow: 'none',
        }}
      >
        <Typography variant="h2">{t('label.spaces', 'Game Spaces')}</Typography>
        <GenericButton
          onClick={() => setIsFormModalOpen(true)}
          label={t('action.createSpace', 'Create a game space')}
          startIcon={<AddIcon />}
        />
      </AppBar>
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          gap: '10px',
          padding: '15px 20px',
          overflow: 'auto',
        }}
      >
        {spaces.edges.map((space) => (
          <SpaceListItem
            key={space.node.id}
            space={space.node}
            onEdit={() => openEditModal(space.node)}
            onDelete={() => openDeleteModal(space.node)}
            onNavigate={() => navigateToSpace(space.node)}
          />
        ))}
      </Box>
      <Modal open={isFormModalOpen} onClose={handleClose}>
        <SpaceForm handleClose={handleClose} selectedSpace={selectedSpace} />
      </Modal>
      <Modal open={isDeletionModalOpen} onClose={handleClose}>
        <ModalConfirmation
          label={t('label.deleteSpace', {
            defaultValue: 'Delete space {{name}}?',
            name: selectedSpace?.name,
          })}
          warningMessage={t(
            'warning.deleteSpace',
            'You are about to delete a client game space. Every game, mcqs, player, administrators created in this space will be permanently deleted. As this is a very dangerous and consequential operation, please double check with the client owner of this space that they wish to have it deleted.',
          )}
          context={'danger'}
          confirmText={t('action.deleteGame', 'Delete client space')}
          onConfirm={confirmationCallback}
          handleClose={handleClose}
        />
      </Modal>
    </Box>
  );
};

export default SpaceList;
