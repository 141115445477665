import styled from '@emotion/styled';
import Timer from '@mui/icons-material/Timer';

export const RankedTimer = styled(Timer)<{ rank: number }>`
  font-size: ${({ rank }) => {
    switch (rank) {
      case 1:
        return '100px';
      case 2:
        return '80px';
      case 3:
      default:
        return '60px';
    }
  }};
  color: ${({ theme, rank }) => {
    switch (rank) {
      case 1:
        return theme.colors.gold;
      case 2:
        return theme.colors.silver;
      case 3:
      default:
        return theme.colors.bronze;
    }
  }};
`;
