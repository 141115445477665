import { Box, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

const getTotalPercentage = (answersCount: number, participantCount: number) => {
  if (!answersCount || !participantCount) {
    return 0;
  }

  return Math.round((answersCount / participantCount) * 100);
};

const getCorrectPercentage = (
  answersCount: number,
  correctAnswersCount: number,
) => {
  if (!answersCount || !correctAnswersCount) {
    return 0;
  }

  return Math.round((correctAnswersCount / answersCount) * 100);
};

const AnswerStats: React.FC<{
  participantCount: number;
  answersCount: number;
  correctAnswersCount: number;
}> = ({ participantCount, answersCount, correctAnswersCount }) => {
  const { t } = useTranslation();

  const totalAnswerPercentage = getTotalPercentage(
    answersCount,
    participantCount,
  );

  const correctAnswersPercentage = getCorrectPercentage(
    answersCount,
    correctAnswersCount,
  );

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
      {/* TODO: need plural */}
      <Typography variant="subtitle2">
        {t('label.plural.answers', {
          defaultValue: '{{answers}} answer(s)',
          answers: answersCount ?? 0,
        })}{' '}
        ({totalAnswerPercentage} %)
      </Typography>
      <Typography variant="h5">-</Typography>
      <Typography variant="subtitle2">
        {t('label.plural.participantsRightAnswer', {
          defaultValue: '{{participantsRightAnswer}} right answer(s)',
          participantsRightAnswer: correctAnswersCount,
        })}{' '}
        ({correctAnswersPercentage} %)
      </Typography>
    </Box>
  );
};

export default AnswerStats;
