import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import { AppBar, Box, IconButton, Typography } from '@mui/material';
import { useContext } from 'react';
import { AuthContext } from '../../../context/auth';
import { PlayerSpaceByIdQuery } from '../../../graphql/types';

const HubHeader: React.FC<{ space: PlayerSpaceByIdQuery['spaceById'] }> = ({
  space,
}) => {
  const { logout } = useContext(AuthContext);

  return (
    <AppBar
      position="sticky"
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '10px',
        backgroundColor: 'transparent',
        backgroundImage: 'none',
        boxShadow: 'none',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
        <Box
          sx={{
            display: 'flex',
            width: '32px',
            aspectRatio: '1',
            backgroundImage: `url(${space?.image})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            borderRadius: '4px',
          }}
        ></Box>
        <Typography variant="h5">{space.name}</Typography>
      </Box>
      <IconButton onClick={logout}>
        <PowerSettingsNewIcon />
      </IconButton>
    </AppBar>
  );
};

export default HubHeader;
