import { useMutation, useQuery } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import { AppBar, Box, Modal, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../context/auth';
import {
  DeleteAdminDocument,
  SpaceByIdDocument,
  SpaceByIdQuery,
} from '../../graphql/types';
import { useSpaceId } from '../../hooks/useSpaceId';
import FullscreenLoader from '../FullscreenLoader';
import GenericButton from '../GenericButton';
import ModalConfirmation from '../ModalConfirmation';
import AdminForm from './AdminForm';
import AdminListItem from './AdminListItem';

type PartialAdmin =
  SpaceByIdQuery['spaceById']['admins']['edges'][number]['node'];

const AdminList: React.FC = () => {
  const { t } = useTranslation();
  const spaceId = useSpaceId();
  const { user } = useContext(AuthContext);

  const { data } = useQuery(SpaceByIdDocument, {
    variables: { id: spaceId },
  });
  const [isFormModalOpen, setIsFormModalOpen] = useState(false);
  const [isDeletionModalOpen, setIsDeletionModalOpen] = useState(false);
  const [selectedAdmin, setSelectedAdmin] = useState<
    PartialAdmin | undefined
  >();

  const [deleteAdmin] = useMutation(DeleteAdminDocument, {
    variables: selectedAdmin
      ? {
          id: selectedAdmin.id,
        }
      : undefined,
    update(cache) {
      cache.updateQuery(
        { query: SpaceByIdDocument, variables: { id: spaceId } },
        (data) =>
          data
            ? {
                spaceById: {
                  ...data.spaceById,
                  admins: {
                    ...data.spaceById.admins,
                    totalCount: data.spaceById.admins.totalCount - 1,
                    edges: data.spaceById.admins.edges.filter(
                      (admin) => admin.node.id !== selectedAdmin?.id,
                    ),
                  },
                },
              }
            : undefined,
      );
    },
  });

  const openEditModal = (admin: PartialAdmin) => {
    setSelectedAdmin(admin);
    setIsFormModalOpen(true);
  };

  const openDeleteModal = (admin: PartialAdmin) => {
    setSelectedAdmin(admin);
    setIsDeletionModalOpen(true);
  };

  const handleClose = () => {
    setSelectedAdmin(undefined);
    setIsFormModalOpen(false);
    setIsDeletionModalOpen(false);
  };

  const onConfirmDelete = async () => {
    await deleteAdmin();
    handleClose();
  };

  const space = data?.spaceById;

  if (!space) {
    return <FullscreenLoader />;
  }

  return (
    <Box
      sx={{ display: 'flex', flex: 1, flexDirection: 'column', height: '100%' }}
    >
      <AppBar
        position="sticky"
        color="primary"
        sx={{
          padding: '20px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          zIndex: 2,
          backgroundColor: 'transparent',
          backgroundImage: 'none',
          boxShadow: 'none',
        }}
      >
        <Typography variant="h2">
          {t('label.admins', 'Administrators')}
        </Typography>
        <GenericButton
          onClick={() => setIsFormModalOpen(true)}
          label={t('action.createAdmin', 'Create a new admin')}
          startIcon={<AddIcon />}
        />
      </AppBar>
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          gap: '10px',
          padding: '15px 20px',
          overflow: 'auto',
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
          {space.admins.edges.map((admin) => (
            <AdminListItem
              key={admin.node.id}
              admin={admin.node}
              onEdit={() => openEditModal(admin.node)}
              onDelete={
                admin.node.id !== user?.userId
                  ? () => openDeleteModal(admin.node)
                  : undefined
              }
            />
          ))}
        </Box>
      </Box>
      <Modal open={isFormModalOpen} onClose={handleClose}>
        <AdminForm
          selectedAdmin={selectedAdmin}
          spaceId={spaceId}
          handleClose={handleClose}
        />
      </Modal>
      <Modal open={isDeletionModalOpen} onClose={handleClose}>
        <ModalConfirmation
          label={t('label.deleteAdmin', 'Delete admin')}
          warningMessage={t(
            'warning.deleteAdmin',
            'This administrator will be deleted, and their access will be lost. You may still grant them a new access.',
          )}
          confirmText={t('action.delete', 'Delete')}
          context={'danger'}
          onConfirm={onConfirmDelete}
          handleClose={handleClose}
        />
      </Modal>
    </Box>
  );
};

export default AdminList;
