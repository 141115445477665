import React, { useContext } from 'react';
import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom';
import AdminGame from '../components/AdminGame';
import AdminGameResult from '../components/AdminGame/AdminGameResult';
import AdminList from '../components/AdminList';
import AdminLogin from '../components/AdminLogin';
import AppDrawer from '../components/AppDrawer';
import GameList from '../components/GameList';
import McqList from '../components/McqList';
import NoMatch from '../components/NoMatch';
import QuestionManager from '../components/QuestionManager';
import RoutesLayout from '../components/RoutesLayout';
import { AuthContext } from '../context/auth';

const AdminRoutes = () => {
  return (
    <Routes>
      <Route index element={<AdminLogin />} />
      <Route
        path=":spaceId"
        element={
          <RequireAuth>
            <RoutesLayout>
              <AppDrawer />
              <Outlet />
            </RoutesLayout>
          </RequireAuth>
        }
      >
        <Route path="games" element={<GameList />} />
        <Route path="games/:gameId/play" element={<AdminGame />} />
        <Route path="games/:gameId/results" element={<AdminGameResult />} />
        <Route path="mcqs" element={<McqList />} />
        <Route path="mcqs/:mcqId" element={<QuestionManager />} />
        <Route path="admins" element={<AdminList />} />
      </Route>
      <Route path="*" element={<NoMatch />} />
    </Routes>
  );
};

const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const { user } = useContext(AuthContext);
  let location = useLocation();

  if (!user || (!user.isAdmin && !user.isSuperAdmin)) {
    return <Navigate to="/admin" state={{ from: location }} />;
  }

  return children;
};

export default AdminRoutes;
