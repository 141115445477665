import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { defaultNS, resources } from './locales';

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    defaultNS,
    resources,
    fallbackLng: 'en',

    keySeparator: false, // disable nesting
    returnNull: false,
    returnEmptyString: false,

    interpolation: {
      escapeValue: false, // react already safes from xss
    },

    detection: {
      // order and from where user language should be detected
      order: ['querystring', 'localStorage', 'navigator'],

      // keys or params to lookup language from
      lookupQuerystring: 'language',
      lookupLocalStorage: 'i18nextLng',

      // cache user language on
      caches: ['localStorage'],
      excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)
    },
  });

export default i18n;
