import { ApolloProvider } from '@apollo/client';
import CssBaseline from '@mui/material/CssBaseline';
import createApolloClient from '../../utils/createApolloClient';
import { SnackbarProvider } from 'notistack';

import '../../i18n';
import { AuthProvider } from '../../context/auth';
import AppRoutes from '../../routes/AppRoutes';

const client = createApolloClient();

function App() {
  return (
    <ApolloProvider client={client}>
      <AuthProvider>
        <CssBaseline />
        <SnackbarProvider maxSnack={3}>
          <AppRoutes />
        </SnackbarProvider>
      </AuthProvider>
    </ApolloProvider>
  );
}

export default App;
