import { Box } from '@mui/material';
import React, { useMemo } from 'react';
import { CentisecondsValue, SecondsValue } from './styled';

export const millisecondsToSeconds = (
  milliseconds: number,
): [string, string] => {
  const d = new Date(Date.UTC(0, 0, 0, 0, 0, 0, milliseconds));
  return [
    d.getUTCSeconds().toString(),
    Math.round(d.getUTCMilliseconds() / 10)
      .toString()
      .padStart(2, '0'),
  ];
};

const AnswerSpeed: React.FC<{
  speed: number;
  size?: 'small' | 'medium' | 'large';
}> = ({ speed, size = 'medium' }) => {
  const time = useMemo(() => millisecondsToSeconds(speed), [speed]);

  return (
    <Box sx={{ display: 'inline' }}>
      <SecondsValue variant="body1" sx={{ display: 'inline' }} size={size}>
        {time[0]}.
      </SecondsValue>
      <CentisecondsValue variant="body1" sx={{ display: 'inline' }} size={size}>
        {time[1]} s
      </CentisecondsValue>
    </Box>
  );
};

export default AnswerSpeed;
