import { useLazyQuery } from '@apollo/client';
import { Box, Typography } from '@mui/material';
import {
  AdminGameByIdQuery,
  GameRankingDocument,
} from '../../../graphql/types';
import React, { useEffect, useMemo } from 'react';
import ScoreBoard from '../../ScoreBoard';
import TurnPodium from './TurnPodium';
import QuestionChoiceItem from '../../QuestionChoiceItem';
import QuestionPictureContainer from '../../QuestionPictureContainer';

const LiveShowGamePlay: React.FC<{
  game: AdminGameByIdQuery['gameById'];
}> = ({ game }) => {
  const [getRanking, { data: rankingData, fetchMore, loading }] = useLazyQuery(
    GameRankingDocument,
    {
      variables: { id: game.id },
      notifyOnNetworkStatusChange: true,
    },
  );

  const ranking = rankingData?.gameById?.ranking;
  const currentTurn = game.currentTurn;
  const currentQuestion = currentTurn?.question;
  const currentChoices = currentQuestion?.choices;

  // Update ranking when a turn ends or starts
  useEffect(() => {
    if (game?.currentTurn?.endedAt || game?.currentTurn?.startedAt) {
      getRanking();
    }
  }, [game?.currentTurn?.endedAt, game?.currentTurn?.startedAt, getRanking]);

  // Update ranking when someone joins
  useEffect(() => {
    if (game?.ranking.totalCount) {
      getRanking();
    }
  }, [game?.ranking.totalCount, getRanking]);

  const rightAnswer = useMemo(() => {
    if (currentChoices) {
      const choice = currentChoices.find((choice) => choice.isCorrect);
      return choice?.value || '';
    } else {
      return '';
    }
  }, [currentChoices]);

  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        gap: '60px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          gap: '30px',
          alignContent: 'space-between',
          overflowX: 'scroll',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            flex: 1,
          }}
        >
          <Typography variant="h2" sx={{ fontSize: 70 }}>
            {game.currentTurn?.index}
          </Typography>
          <Typography variant="h2" sx={{ color: 'text.secondary' }}>
            /{game.turnCount}
          </Typography>
        </Box>
        <Box sx={{ flex: 1 }}>
          {!currentTurn?.endedAt ? (
            <Typography
              variant="h2"
              sx={{ fontSize: '64px', textAlign: 'center' }}
            >
              {currentTurn?.question.title}
            </Typography>
          ) : (
            <>
              <Typography
                variant="body1"
                sx={{ fontSize: '32px', textAlign: 'center' }}
              >
                {currentTurn?.question.title}
              </Typography>
              <Typography
                variant="h2"
                sx={{
                  color: 'success.main',
                  textAlign: 'center',
                  fontSize: '64px',
                }}
              >
                {rightAnswer}
              </Typography>
            </>
          )}
        </Box>
        {currentTurn?.question?.media &&
          /(.png)|(.jpg)|(.jpeg)/.test(currentTurn.question.media) && (
            <Box sx={{ display: 'flex', justifyContent: 'center', flex: 1 }}>
              <Box sx={{ display: 'flex', flex: 1, maxWidth: '90%' }}>
                <QuestionPictureContainer
                  picture={currentTurn.question.media}
                />
              </Box>
            </Box>
          )}
        {!game.currentTurn?.endedAt ? (
          <Box
            sx={{
              flex: 2,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: '50% auto',
                gap: '20px',
                width: '100%',
                flex: 1,
              }}
            >
              {currentTurn?.question.choices.map((choice) => (
                <QuestionChoiceItem key={choice.id} choice={choice} />
              ))}
            </Box>
          </Box>
        ) : (
          <TurnPodium game={game} />
        )}
      </Box>
      <ScoreBoard
        ranking={ranking?.edges ?? []}
        size="large"
        gameContext={!game.currentTurn?.endedAt ? 'turnPlay' : 'turnEnd'}
        loadingMore={loading}
        loadMore={() => {
          if (ranking?.pageInfo.hasNextPage && ranking.pageInfo.endCursor) {
            fetchMore({
              variables: {
                cursor: ranking.pageInfo.endCursor,
              },
            });
          }
        }}
      />
    </Box>
  );
};

export default LiveShowGamePlay;
