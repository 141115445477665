import styled from '@emotion/styled';

export const AdminLoginContainer = styled.div`
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${({ theme }) =>
    `background: linear-gradient(${theme.colors.darkBlue}, ${theme.colors.dark})`};
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid ${({ theme }) => theme.colors.softGrey};
  box-sizing: border-box;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
  border-radius: 1.25rem;
  background-color: #00000033;
  padding: 2rem;
`;

export const ImageContainer = styled.div`
  margin-bottom: 2rem;
`;
