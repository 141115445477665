import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import GamesIcon from '@mui/icons-material/Games';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PlayerSpaceByIdQuery } from '../../../graphql/types';
import CoverPlaceholder from '../../../images/no_game_cover.png';

const PlayerGameItem: React.FC<{
  game: PlayerSpaceByIdQuery['spaceById']['games']['edges'][number]['node'];
  onNavigate: () => void;
}> = ({ game, onNavigate }) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}
      onClick={onNavigate}
      key={game.id}
    >
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          aspectRatio: '2.2',
          backgroundImage: `url(${game.image ?? CoverPlaceholder})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          borderRadius: '10px',
        }}
      ></Box>
      <Typography sx={{ fontSize: '18px', fontWeight: '700' }}>
        {game.name}
      </Typography>
      <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
        <Box sx={{ display: 'flex', gap: '5px' }}>
          <EventAvailableIcon sx={{ color: '#EA227A' }} />
          <Typography variant="body2" color="#EA227A">
            {game.openedAt !== null
              ? t('label.ongoing', 'Ongoing')
              : t('label.gameWillStart', 'Game will start soon!')}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
          <GamesIcon sx={{ color: 'text.secondary' }} />
          <Typography variant="body2" color="text.secondary">
            {t('label.plural.questions', {
              questionCount: game.turnCount,
              defaultValue: `{{ questionCount }} questions`,
            })}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default PlayerGameItem;
