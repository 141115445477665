import { Box } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { GameRankingQuery } from '../../graphql/types';
import RankingLobby from '../../images/ranking_lobby.svg';
import { RankContext } from './ParticipantRankItem';
import Ranking from './Ranking';
import { CustomText, ImageContainer, ScoreBoardContainer } from './styled';

const ScoreBoard: React.FC<{
  ranking: GameRankingQuery['gameById']['ranking']['edges'];
  loadingMore: boolean;
  loadMore: () => void;
  className?: string | undefined;
  size?: 'default' | 'large';
  gameContext?: RankContext;
}> = ({ ranking, loadingMore, loadMore, className, size, gameContext }) => {
  const { t } = useTranslation();
  return (
    <ScoreBoardContainer className={className}>
      {ranking.length === 0 ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: '5rem',
          }}
        >
          <ImageContainer>
            <img alt="tracktl_logo" src={RankingLobby} />
          </ImageContainer>
          <CustomText>
            {t('label.rankingLobby', 'The ranking will be displayed here')}
          </CustomText>
        </Box>
      ) : (
        <Ranking
          ranking={ranking}
          loadingMore={loadingMore}
          loadMore={loadMore}
          size={size ?? 'default'}
          gameContext={gameContext ?? 'turnPlay'}
        />
      )}
    </ScoreBoardContainer>
  );
};

export default ScoreBoard;
