import { Box, Typography } from '@mui/material';
import { BasicChoiceFragment } from '../../graphql/types';

const QuestionChoiceItem: React.FC<{
  choice: BasicChoiceFragment;
}> = ({ choice }) => {
  return (
    <Box
      sx={{
        backgroundColor: '#9C9C9C33',
        padding: '15px',
        textAlign: 'center',
        borderRadius: '70px',
      }}
    >
      <Typography variant="h4">{choice.value}</Typography>
    </Box>
  );
};

export default QuestionChoiceItem;
