import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import NoMatchBackground from '../../images/backgrounds/no_match_background.svg';
import Astronaut from '../../images/astronaut.svg';
import GenericButton from '../GenericButton';

const NoMatch: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const goToHome = () => {
    navigate('/');
  };

  return (
    <>
      <Box
        sx={{
          height: '100vh',
          width: '100vw',
          backgroundImage: `url(${NoMatchBackground})`,
          backgroundSize: 'cover',
          backgroundPosition: 'right',
          filter: 'blur(4px)',
        }}
      ></Box>
      <Box
        sx={{
          padding: '20px',
          display: 'flex',
          flexDirection: 'column',
          gap: '100px',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
          width: '100vw',
          position: 'absolute',
          top: '0',
        }}
      >
        <Box
          sx={{
            minWidth: '200px',
            width: '20rem',
            maxWidth: '70%',
            aspectRatio: '0.76',
            backgroundImage: `url(${Astronaut})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            alignItems: 'center',
            textAlign: 'center',
          }}
        >
          <Typography variant="h5">{t('label.noMatch', 'Hmm...')}</Typography>
          <Typography variant="subtitle2">
            {t(
              'sublabel.noMatch',
              'We could not find the page you were looking for.',
            )}
          </Typography>
        </Box>
        <GenericButton onClick={goToHome} label={t('action.goBackToHome')} />
      </Box>
    </>
  );
};

export default NoMatch;
