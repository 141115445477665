import { Avatar } from '@mui/material';
import { useMemo } from 'react';

const stringToColor = (
  string: string,
): { backgroundColor: string; textColor: 'black' | 'white' } => {
  let hash = 0;
  let i;

  for (i = 0; i < string.length; i++) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let backgroundColor = '#';
  let thresholdColor = 0;

  for (i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    thresholdColor += value;
    backgroundColor += ('00' + value.toString(16)).slice(-2);
  }

  return {
    backgroundColor,
    textColor: thresholdColor < 326 ? 'white' : 'black',
  };
};

const PlayerAvatar: React.FC<{
  name: string;
  size?: 'small' | 'medium' | 'large';
}> = ({ name, size = 'small' }) => {
  const stringAvatar = useMemo(() => {
    const colors = stringToColor(name);
    const sizeMap = {
      small: '30px',
      medium: '50px',
      large: '70px',
    };
    const fontSizeMap = {
      small: '12px',
      medium: '24px',
      large: '32px',
    };
    const borderSizeMap = {
      small: '2px',
      medium: '3px',
      large: '4px',
    };
    return {
      sx: {
        height: sizeMap[size],
        width: sizeMap[size],
        backgroundColor: colors.backgroundColor,
        color: colors.textColor,
        fontSize: fontSizeMap[size],
        borderWidth: borderSizeMap[size],
        borderStyle: 'solid',
        borderColor: '#141A2B',
      },
      children: `${name.split(' ')[0][0]}${
        name.split(' ')[1] ? name.split(' ')[1][0] : ''
      }`,
    };
  }, [name, size]);

  return <Avatar {...stringAvatar} />;
};

export default PlayerAvatar;
