import { useTranslation } from 'react-i18next';
import { BasicParticipationFragment } from '../../../../graphql/types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Snackbar,
  TextField,
} from '@mui/material';
import PlayerAvatar from '../../../PlayerAvatar';
import EditIcon from '@mui/icons-material/Edit';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import GenericButton from '../../../GenericButton';
import CustomFormLabel from '../../../CustomFormLabel';
import { useMutation } from '@apollo/client';
import { ChangeNameDocument } from '../../../../graphql/types';

const PlayerProfile: React.FC<{
  me: BasicParticipationFragment;
  playerCount: number;
}> = ({ me, playerCount }) => {
  const { t } = useTranslation();
  const [isNameEditDialogOpened, setIsNameEditDialogOpened] = useState(false);
  const [isSuccessAlertOpened, setIsSuccessAlertOpened] = useState(false);

  const { register, handleSubmit } = useForm<{ name: string }>({
    mode: 'onTouched',
  });

  const [changeName] = useMutation(ChangeNameDocument);

  const onSubmit = handleSubmit(async (data: { name: string }) => {
    if (data.name) {
      await changeName({
        variables: {
          name: data.name,
        },
      });
      setIsNameEditDialogOpened(false);
      setIsSuccessAlertOpened(true);
    }
  });

  return (
    <Box sx={{ display: 'flex', gap: '15px', padding: '10px', width: '100%' }}>
      <PlayerAvatar name={me.player.name} size="large" />
      <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
        <Box sx={{ display: 'flex', flex: 1, alignItems: 'center' }}>
          <Typography variant="h5" sx={{ flex: 1 }}>
            {me.player.name}
          </Typography>
          <IconButton onClick={() => setIsNameEditDialogOpened(true)}>
            <EditIcon />
          </IconButton>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Box sx={{ display: 'flex' }}>
            <Typography variant="body1" sx={{ fontWeight: '700' }}>
              {me.score}
            </Typography>
            <Typography variant="body1" sx={{ fontWeight: '200' }}>
               {t('label.pts', 'pts')}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
            <Typography variant="h5" color="primary.main">
              {me.rank === 0 ? '-' : me.rank}
            </Typography>
            <Typography
              variant="h5"
              color="text.secondary"
              sx={{ fontSize: '14px' }}
            >
               {t('label.of', 'of')} 
            </Typography>
            <Typography variant="h5" color="text.secondary">
              {playerCount}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Dialog
        open={isNameEditDialogOpened}
        maxWidth={false}
        fullWidth
        onClose={() => setIsNameEditDialogOpened(false)}
      >
        <DialogTitle>{t('label.changeName', 'Change your name')}</DialogTitle>
        <form onSubmit={onSubmit}>
          <DialogContent>
            <CustomFormLabel
              label={t('input.label.newName', 'Enter your new name:')}
            />
            <TextField
              variant="outlined"
              required
              fullWidth
              id="name"
              placeholder={me.player.name ?? 'Marie Dupont'}
              defaultValue={me.player.name ?? ' '}
              {...register('name', { required: true, minLength: 3 })}
            />
          </DialogContent>
          <DialogActions
            disableSpacing
            sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}
          >
            <GenericButton
              type="reset"
              label={t('action.cancel')}
              context="secondary"
              variant="text"
              fullWidth
              onClick={() => setIsNameEditDialogOpened(false)}
            />
            <GenericButton type="submit" label={t('action.save')} fullWidth />
          </DialogActions>
        </form>
      </Dialog>
      <Snackbar
        open={isSuccessAlertOpened}
        autoHideDuration={6000}
        onClose={() => setIsSuccessAlertOpened(false)}
      >
        <Alert sx={{ width: '100%' }} severity="success">
          {t('success.changeName', 'Name changed successfully.')}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default PlayerProfile;
