import DeleteIcon from '@mui/icons-material/Delete';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import GenericButton from '../GenericButton';

const PictureHolder: React.FC<{ picture: string; onDelete: () => void }> = ({
  picture,
  onDelete,
}) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        gap: '10px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          backgroundImage: `url(${picture})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          width: '400px',
          aspectRatio: '2.2',
          borderRadius: '10px',
        }}
      />
      <GenericButton
        context="danger"
        onClick={onDelete}
        startIcon={<DeleteIcon />}
        type="button"
        label={t('action.deletePicture', 'Delete picture')}
      />
    </Box>
  );
};

export default PictureHolder;
