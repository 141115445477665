import { useMutation } from '@apollo/client';
import { ErrorMessage } from '@hookform/error-message';
import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, TextField, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  CreateAdminDocument,
  SpaceByIdDocument,
  SpaceByIdQuery,
} from '../../../graphql/types';
import { snackbarErrorConfig } from '../../../utils/Snackbars/snackbar.configs';
import CustomFormLabel from '../../CustomFormLabel';
import GenericButton from '../../GenericButton';
import { ModalBody, ModalFooter, ModalHeader, PageContainer } from './styled';

const AdminForm = React.forwardRef<
  HTMLDivElement,
  {
    handleClose: () => void;
    selectedAdmin?: SpaceByIdQuery['spaceById']['admins']['edges'][number]['node'];
    spaceId: number;
  }
>(({ handleClose, selectedAdmin, spaceId }, ref) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { register, handleSubmit, formState, reset } = useForm<{
    name: string;
    email: string;
    password: string;
  }>({
    defaultValues: {
      name: selectedAdmin?.name || '',
      email: selectedAdmin?.email || '',
      password: '',
    },
  });

  const [createAdminMutation] = useMutation(CreateAdminDocument, {
    update(cache, { data }) {
      if (data) {
        cache.updateQuery(
          { query: SpaceByIdDocument, variables: { id: spaceId } },
          (spaceData) =>
            spaceData
              ? {
                  spaceById: {
                    ...spaceData.spaceById,
                    admins: {
                      ...spaceData.spaceById.admins,
                      totalCount: spaceData.spaceById.admins.totalCount + 1,
                      edges: [
                        ...spaceData.spaceById.admins.edges,
                        { node: data.createAdmin },
                      ],
                    },
                  },
                }
              : undefined,
        );
      }
    },
  });

  const onSubmit = handleSubmit(async (data) => {
    try {
      await createAdminMutation({ variables: { ...data, spaceId } });
      reset();
      handleClose();
    } catch (error: any) {
      enqueueSnackbar(error.message, snackbarErrorConfig);
    }
  });

  const { ref: nameRef, ...nameRest } = register('name');
  const { ref: emailRef, ...emailRest } = register('email', {
    pattern: {
      value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/i,
      message: t('error.label.email', 'e.g. tracktl@tracktl.com'),
    },
  });
  const { ref: passwordRef, ...passwordRest } = register('password');

  return (
    <PageContainer ref={ref}>
      <form onSubmit={onSubmit}>
        <ModalHeader>
          <Typography variant="h4">
            {selectedAdmin
              ? t('action.editAdmin', 'Edit Administrator')
              : t('action.createAdmin', 'Create Administrator')}
          </Typography>
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </ModalHeader>
        <ModalBody>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
            <div>
              <CustomFormLabel label={t('input.label.name', 'Name')} />
              <TextField
                variant="outlined"
                required
                fullWidth
                id="name"
                placeholder={t('input.placeholder.name', 'e.g. Richard Gilson')}
                autoComplete="name"
                {...nameRest}
                inputRef={nameRef}
              />
            </div>
            <div>
              <CustomFormLabel label={t('input.label.email', 'Email')} />
              <TextField
                error={!!formState.errors.email}
                variant="outlined"
                required
                fullWidth
                id="email"
                placeholder={t(
                  'input.placeholder.email',
                  'e.g. tracktl@tracktl.com',
                )}
                autoComplete="email"
                {...emailRest}
                inputRef={emailRef}
              />
              <ErrorMessage errors={formState.errors} name="email" />
            </div>
            <div>
              <CustomFormLabel label={t('input.label.password', 'Password')} />
              <TextField
                variant="outlined"
                required
                fullWidth
                placeholder={t('input.placeholder.password', 'Password')}
                type="password"
                id="password"
                autoComplete="current-password"
                {...passwordRest}
                inputRef={passwordRef}
              />
            </div>
          </Box>
        </ModalBody>
        <ModalFooter>
          <GenericButton
            onClick={handleClose}
            variant="text"
            disabled={formState.isSubmitting}
            label={t('action.cancel', 'Cancel')}
          />
          <GenericButton
            type="submit"
            variant="contained"
            disabled={
              !!formState.errors.name ||
              !!formState.errors.email ||
              !!formState.errors.password ||
              formState.isSubmitting
            }
            label={
              selectedAdmin
                ? t('action.save', 'Save')
                : t('action.create', 'Create')
            }
          />
        </ModalFooter>
      </form>
    </PageContainer>
  );
});

export default AdminForm;
