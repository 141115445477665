import { useQuery } from '@apollo/client';
import { Box, Divider, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { AuthContext } from '../../context/auth';
import { ReadMcqDocument, ReadMcqQuery } from '../../graphql/types';
import FullscreenLoader from '../FullscreenLoader';
import QuestionForm from './QuestionForm';
import QuestionListItem from './QuestionListItem';
import QuestionVisualizer from './QuestionVisualizer';
import { ButtonContainer, QuestionListContainer } from './styled';

export type QuestionFormData = {
  mcqTitle: String;
  questionTitle: string;
  media?: string | null;
  firstAnswer: string;
  secondAnswer: string;
  thirdAnswer: string;
  fourthAnswer: string;
  firstCheckbox: boolean;
  secondCheckbox: boolean;
  thirdCheckbox: boolean;
  fourthCheckbox: boolean;
};

const useMcqId = () => {
  const { mcqId } = useParams() as { mcqId: string };
  return Number(mcqId);
};

type MCQQuestion =
  ReadMcqQuery['readMCQ']['questions']['edges'][number]['node'];

const QuestionManager: React.FC = () => {
  const { t } = useTranslation();

  const mcqId = useMcqId();
  const { user } = useContext(AuthContext);
  const readMcqReturn = useQuery(ReadMcqDocument, {
    variables: {
      id: mcqId,
    },
  });

  const [currentMcq, setCurrentMcq] = useState(readMcqReturn.data?.readMCQ);
  const [defaultQuestion, setDefaultQuestion] = useState<MCQQuestion>({
    id: 0,
    title: '',
    media: '',
    choices: [
      { id: 0, value: '', isCorrect: false },
      { id: 1, value: '', isCorrect: false },
      { id: 2, value: '', isCorrect: false },
      { id: 3, value: '', isCorrect: false },
    ],
  });

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(1);
  const [currentQuestion, setCurrentQuestion] = useState(
    currentMcq?.questions.edges[0]?.node ?? defaultQuestion,
  );

  useEffect(() => {
    setCurrentMcq(readMcqReturn.data?.readMCQ);
    setCurrentQuestion(
      readMcqReturn.data?.readMCQ.questions.edges[0]?.node ?? defaultQuestion,
    );
    setCurrentQuestionIndex(1);
    setDefaultQuestion({
      id: 0,
      title: '',
      media: '',
      choices: [
        { id: 0, value: '', isCorrect: false },
        { id: 1, value: '', isCorrect: false },
        { id: 2, value: '', isCorrect: false },
        { id: 3, value: '', isCorrect: false },
      ],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mcqId, readMcqReturn.data?.readMCQ]);

  //Form
  const {
    register,
    handleSubmit,
    reset,
    formState,
    control,
    setValue,
    getValues,
  } = useForm<QuestionFormData>({
    defaultValues: {
      mcqTitle: currentMcq?.name,
      questionTitle: defaultQuestion?.title,
      media: defaultQuestion?.media,
      firstAnswer: defaultQuestion?.choices[0]?.value,
      secondAnswer: defaultQuestion?.choices[1]?.value,
      thirdAnswer: defaultQuestion?.choices[2]?.value,
      fourthAnswer: defaultQuestion?.choices[3]?.value,
      firstCheckbox: defaultQuestion?.choices[0]?.isCorrect,
      secondCheckbox: defaultQuestion?.choices[1]?.isCorrect,
      thirdCheckbox: defaultQuestion?.choices[2]?.isCorrect,
      fourthCheckbox: defaultQuestion?.choices[3]?.isCorrect,
    },
  });

  //Preset inputs value
  const resetInputs = useCallback(
    (presetQuestion: MCQQuestion) => {
      reset({
        mcqTitle: currentMcq?.name,
        questionTitle: presetQuestion?.title || defaultQuestion?.title,
        media: presetQuestion?.media || defaultQuestion?.media,
        firstAnswer:
          presetQuestion?.choices[0]?.value ||
          defaultQuestion?.choices[0]?.value,
        secondAnswer:
          presetQuestion?.choices[1]?.value ||
          defaultQuestion?.choices[1]?.value,
        thirdAnswer:
          presetQuestion?.choices[2]?.value ||
          defaultQuestion?.choices[2]?.value,
        fourthAnswer:
          presetQuestion?.choices[3]?.value ||
          defaultQuestion?.choices[3]?.value,
        firstCheckbox: presetQuestion?.choices[0]?.isCorrect ?? false,
        secondCheckbox: presetQuestion?.choices[1]?.isCorrect ?? false,
        thirdCheckbox: presetQuestion?.choices[2]?.isCorrect ?? false,
        fourthCheckbox: presetQuestion?.choices[3]?.isCorrect ?? false,
      });
    },
    [
      currentMcq?.name,
      defaultQuestion?.choices,
      defaultQuestion?.media,
      defaultQuestion?.title,
      reset,
    ],
  );

  useEffect(() => {
    resetInputs(currentQuestion || defaultQuestion);
  }, [currentMcq, currentQuestion, defaultQuestion, resetInputs]);

  //switch question CB
  const switchQuestion = (index: number, question: MCQQuestion) => {
    setCurrentQuestion(question);
    setCurrentQuestionIndex(index);
    resetInputs(question);
  };

  if (!currentMcq) {
    return <FullscreenLoader />;
  }

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
      }}
    >
      <Box sx={{ padding: '20px' }}>
        <Typography variant="h2">{currentMcq.name}</Typography>
        {currentMcq.space === null && !user?.isSuperAdmin && (
          <Typography variant="subtitle2">
            {t(
              'info.globalMcq',
              'This MCQ is crafted by TrackTl and is only available for consultation.',
            )}
          </Typography>
        )}
      </Box>
      <Divider sx={{ marginX: '20px', color: '#EFEFEF' }} />
      {currentMcq.space === null && !user?.isSuperAdmin ? (
        <QuestionVisualizer question={currentQuestion} />
      ) : (
        <QuestionForm
          defaultQuestion={defaultQuestion}
          currentMcq={currentMcq}
          currentQuestion={currentQuestion}
          currentQuestionIndex={currentQuestionIndex}
          switchQuestion={switchQuestion}
          setValue={setValue}
          getValues={getValues}
          control={control}
          formState={formState}
          register={register}
          handleSubmit={handleSubmit}
          mcqId={mcqId}
        />
      )}
      <QuestionListContainer>
        <QuestionListItem
          questions={currentMcq.questions.edges}
          switchQuestion={switchQuestion}
          currentQuestion={currentQuestion}
        />
        {(currentMcq.space !== null || user?.isSuperAdmin) && (
          <ButtonContainer>
            <Button
              role="button"
              onClick={() => {
                switchQuestion(
                  currentMcq.questions.totalCount
                    ? currentMcq.questions.totalCount + 1
                    : 1,
                  defaultQuestion,
                );
              }}
            >
              {t('label.+NewQuestion', '+ New Question')}
            </Button>
          </ButtonContainer>
        )}
      </QuestionListContainer>
    </Box>
  );
};

export default QuestionManager;
