import { useMutation, useQuery } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import { AppBar, Box, Modal, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  DeleteGameDocument,
  SpaceByIdDocument,
  SpaceByIdQuery,
} from '../../graphql/types';
import { useSpaceId } from '../../hooks/useSpaceId';
import FullscreenLoader from '../FullscreenLoader';
import GenericButton from '../GenericButton';
import ModalConfirmation from '../ModalConfirmation';
import GameForm from './GameForm';
import GameListItem from './GameListItem';

type PartialGame =
  SpaceByIdQuery['spaceById']['games']['edges'][number]['node'];

const GameList: React.FC = () => {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const spaceId = useSpaceId();

  const { data } = useQuery(SpaceByIdDocument, {
    variables: { id: spaceId },
  });

  const [isFormModalOpen, setIsFormModalOpen] = useState(false);
  const [isDeletionModalOpen, setIsDeletionModalOpen] = useState(false);
  const [selectedGame, setSelectedGame] = useState<PartialGame | undefined>();
  const [gameContext, setGameContext] = useState<'create' | 'update' | 'clone'>(
    'create',
  );

  const [deleteGame] = useMutation(DeleteGameDocument, {
    variables: selectedGame
      ? {
          id: selectedGame.id,
        }
      : undefined,
    update(cache) {
      cache.updateQuery(
        { query: SpaceByIdDocument, variables: { id: spaceId } },
        (data) =>
          data
            ? {
                spaceById: {
                  ...data.spaceById,
                  games: {
                    ...data.spaceById.games,
                    totalCount: data.spaceById.games.totalCount - 1,
                    edges: data.spaceById.games.edges.filter(
                      (game) => game.node.id !== selectedGame?.id,
                    ),
                  },
                },
              }
            : undefined,
      );
    },
  });

  const openCloneModal = (game: PartialGame) => {
    setSelectedGame({ ...game, code: '' });
    setGameContext('clone');
    setIsFormModalOpen(true);
  };

  const openEditModal = (game: PartialGame) => {
    setSelectedGame(game);
    setGameContext('update');
    setIsFormModalOpen(true);
  };

  const openDeleteModal = (game: PartialGame) => {
    setSelectedGame(game);
    setIsDeletionModalOpen(true);
  };

  const openCreateModal = () => {
    setGameContext('create');
    setIsFormModalOpen(true);
  };

  const handleClose = () => {
    setIsFormModalOpen(false);
    setIsDeletionModalOpen(false);
    setSelectedGame(undefined);
  };

  const onConfirmDelete = async () => {
    await deleteGame();
    handleClose();
  };

  const navigateToGame = (game: PartialGame) => {
    if (game.closedAt) {
      navigate(`/admin/${spaceId}/games/${game.id}/results`);
    } else {
      navigate(`/admin/${spaceId}/games/${game.id}/play`);
    }
  };

  const space = data?.spaceById;

  if (!space) {
    return <FullscreenLoader />;
  }

  return (
    <Box
      sx={{ display: 'flex', flex: 1, flexDirection: 'column', height: '100%' }}
    >
      <AppBar
        position="sticky"
        color="primary"
        sx={{
          padding: '20px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          zIndex: 2,
          backgroundColor: 'transparent',
          backgroundImage: 'none',
          boxShadow: 'none',
        }}
      >
        <Typography variant="h2">{t('label.games', 'Games')}</Typography>
        <GenericButton
          onClick={() => openCreateModal()}
          label={t('action.createGame', 'Create a new game')}
          startIcon={<AddIcon />}
        />
      </AppBar>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
          padding: '15px 20px',
          overflow: 'auto',
        }}
      >
        <Typography variant="h5">
          {t('label.activeGames', 'Active and upcoming games')}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            marginTop: '10px',
            marginBottom: '20px',
          }}
        >
          {space.games.edges
            .filter((game) => game.node.closedAt === null)
            .map((game) => (
              <GameListItem
                key={game.node.id}
                game={game.node}
                onClone={() => openCloneModal(game.node)}
                onEdit={() => openEditModal(game.node)}
                onDelete={() => openDeleteModal(game.node)}
                onNavigate={() => navigateToGame(game.node)}
              />
            ))}
        </Box>
        <Typography variant="h5">
          {t('label.pastGames', 'Past games')}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            marginTop: '10px',
            marginBottom: '20px',
          }}
        >
          {space.games.edges
            .filter((game) => game.node.closedAt !== null)
            .map((game) => (
              <GameListItem
                key={game.node.id}
                game={game.node}
                onClone={() => openCloneModal(game.node)}
                onEdit={() => openEditModal(game.node)}
                onDelete={() => openDeleteModal(game.node)}
                onNavigate={() => navigateToGame(game.node)}
              />
            ))}
        </Box>
      </Box>
      <Modal open={isFormModalOpen} onClose={handleClose}>
        <GameForm
          selectedGame={selectedGame}
          spaceId={spaceId}
          gameContext={gameContext}
          mcqs={data?.spaceById?.mcqs.edges}
          handleClose={handleClose}
        />
      </Modal>
      <Modal
        open={isDeletionModalOpen}
        onClose={() => setIsDeletionModalOpen(false)}
      >
        <ModalConfirmation
          label={t('label.warning.deleteGame', {
            defaultValue: 'Delete game {{name}}?',
            name: selectedGame?.name,
          })}
          warningMessage={t(
            'warning.deleteGame',
            "This game will be deleted, but the quiz will be kept. You won't be able to undo this action.",
          )}
          context={'danger'}
          confirmText={t('confirm.deleteGame', 'Delete game')}
          handleClose={handleClose}
          onConfirm={onConfirmDelete}
        />
      </Modal>
    </Box>
  );
};

export default GameList;
