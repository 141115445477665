import { useLazyQuery } from '@apollo/client';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AdminGameByIdDocument,
  AdminGameByIdQuery,
} from '../../../../graphql/types';
import {
  CountBar,
  CountBarStates,
  CountBarText,
  CountGauge,
  CountNumber,
} from './styled';

const countBarState = (
  answerCount: number,
  playerCount: number,
): CountBarStates => {
  if (answerCount === 0) {
    return 'empty';
  }
  if (answerCount > 0 && answerCount < playerCount) {
    return 'filling';
  }
  if (answerCount >= playerCount) {
    return 'full';
  }
  return 'empty';
};

const AnswerCountGauge: React.FC<{ game: AdminGameByIdQuery['gameById'] }> = ({
  game,
}) => {
  const { t } = useTranslation();

  // useQuery with pollingInterval doesn't mesh well with re-renders.
  const [answerCount] = useLazyQuery(AdminGameByIdDocument, {
    variables: { id: game.id },
    fetchPolicy: 'cache-and-network',
    refetchWritePolicy: 'merge',
  });

  useEffect(() => {
    const polling = setInterval(async () => {
      answerCount();
    }, 1000);
    return () => clearInterval(polling);
  }, [answerCount]);

  const answersCount = game.currentTurn?.answers?.totalCount ?? 0;

  const variant = countBarState(answersCount, game.ranking.totalCount);

  return (
    <CountBar variant={variant}>
      {variant === 'filling' && (
        <CountGauge
          filling={(answersCount / game.ranking.totalCount) * 100}
        ></CountGauge>
      )}
      <CountBarText>
        {variant === 'empty' &&
          t(
            'label.noParticipantAnswer',
            'No participant has answered this question.',
          )}
        {variant === 'filling' && (
          <>
            <CountNumber>
              {answersCount} / {game.ranking.totalCount}
            </CountNumber>{' '}
            {t(
              'label.someParticipantsAnswered',
              'Participants have answered this question',
            )}
          </>
        )}
        {variant === 'full' &&
          t(
            'label.allParticipantsAnswered',
            'All participants have answered this question.',
          )}
      </CountBarText>
    </CountBar>
  );
};

export default AnswerCountGauge;
