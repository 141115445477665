/* eslint-disable */

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "User": [
      "Admin",
      "Player"
    ],
    "meResult": [
      "Admin",
      "Player"
    ]
  }
};
      export default result;
    