import styled from '@emotion/styled';
import { Box } from '@mui/material';

export const PageContainer = styled(Box)`
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  width: 700px;
  background-color: #141a2b;
  border-radius: 1rem;
`;

export const ModalHeader = styled(Box)`
  display: flex;
  padding: 30px 30px 20px 30px;
  justify-content: space-between;
`;

export const ModalBody = styled(Box)`
  padding: 15px 30px;
`;

export const ModalFooter = styled(Box)`
  padding: 20px 30px 30px 30px;
  display: flex;
  justify-content: flex-end;
`;
