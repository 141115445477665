import styled from '@emotion/styled';

export const ScoreBoardContainer = styled.div`
  display: flex;
  flex: 0.4;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

export const CustomText = styled.p`
  text-align: center;
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  font-size: ${({ theme }) => theme.fontSize.normal};
  color: ${({ theme }) => theme.colors.deepGrey};
  margin-top: 0.5rem;
`;

export const ImageContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
