import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';

const blink = keyframes`
    0%,
      100% {
        opacity: 0;
      }
      50% {
        opacity: 1;
      }
    }
  `;

export interface StatusProps {
  isStarted: boolean;
  isFinished: boolean;
}

export const Status = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isStarted' && prop !== 'isFinished',
})<StatusProps>`
  width: 1rem;
  height: 1rem;
  margin: 4px;
  background-color: ${({ isStarted, isFinished, theme }) => {
    if (isStarted) {
      return theme.colors.lightBlue;
    }
    if (isFinished) {
      return theme.colors.mediumGrey;
    }
    return theme.colors.grey;
  }};
  border-radius: 2rem;
  animation: ${({ isStarted, isFinished }) =>
      isStarted && !isFinished ? blink : null}
    2s infinite;
`;
