import CheckIcon from '@mui/icons-material/Check';
import { CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { CustomButton, CustomLabel } from './styled';
import { OperationState } from './types';

export type ButtonProps = {
  label?: string;
  operationState?: OperationState;
  activationDelay?: number;
} & React.ComponentProps<typeof CustomButton>;

const GenericButton: React.FC<ButtonProps> = ({
  label,
  operationState = 'ready',
  variant = 'contained',
  role = 'button',
  activationDelay = 0,
  ...props
}) => {
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if (activationDelay > 0) {
      setDisabled(true);
      const timer = setTimeout(() => {
        setDisabled(false);
      }, activationDelay);
      return () => clearTimeout(timer);
    }
  }, [activationDelay]);

  return (
    <CustomButton variant={variant} role={role} {...props} disabled={disabled}>
      {operationState === 'ready' && <CustomLabel>{label}</CustomLabel>}
      {operationState === 'saving' && (
        <CircularProgress size={20} color="inherit" />
      )}
      {operationState === 'saved' && <CheckIcon />}
    </CustomButton>
  );
};

export default GenericButton;
