import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const CustomUploadImg = styled.img`
  @media (min-width: 1200px) {
    height: 3.125rem;
    width: 3.125rem;
  }
  height: 2.5rem;
  width: 2.5rem;
`;

type DropZoneContainerProps = {
  isDragActive?: boolean;
};

export const DropZoneContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isDragActive',
})<DropZoneContainerProps>`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 3.75rem;
  border-radius: 1.5rem;
  border: 1px dashed
    ${({ theme, isDragActive }) =>
      isDragActive ? 'transparent' : theme.colors.deepGrey};
  background-color: ${({ theme, isDragActive }) =>
    isDragActive ? theme.colors.grey : 'transparent'};
  padding: 0 1rem;
  :hover {
    background-color: ${({ theme }) => `${theme.colors.lightGrey}1A`};
  }
  cursor: pointer;
`;

export const UploadIconContainer = styled.div`
  display: flex;
  flex: 0.15;
  @media (min-width: 1200px) {
    flex: 0.2;
  }
  justify-content: center;
`;

export const DropZoneLabel = styled.p`
  font-size: ${({ theme }) => theme.fontSize.small};
  color: ${({ theme }) => theme.colors.deepGrey};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  @media (min-width: 1200px) {
    font-size: ${({ theme }) => theme.fontSize.normal};
  }
`;

export const DropZoneInfoLabel = styled.p`
  font-size: ${({ theme }) => theme.fontSize.xxsmall};
  color: ${({ theme }) => theme.colors.deepGrey};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  @media (min-width: 1200px) {
    font-size: ${({ theme }) => theme.fontSize.xxsmall};
  }
  text-align: center;
`;

type UploadStatusProps = {
  status: 'success' | 'error' | 'ready' | 'uploading';
};

export const LabelStatus = styled('p', {
  shouldForwardProp: (prop) => prop !== 'status',
})<UploadStatusProps>`
  color: ${({ theme, status }) => {
    switch (status) {
      case 'success':
        return theme.colors.successGreen;
      case 'error':
        return theme.colors.dangerRed;
      case 'ready':
      case 'uploading':
      default:
        return theme.colors.deepGrey;
    }
  }};
  font-size: ${({ theme }) => theme.fontSize.xxsmall};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  @media (min-height: 850px) {
    height: 2.125rem;
    width: 2.125rem;
  }
  height: 1.5rem;
  width: 1.5rem;
`;

export const FileName = styled.p`
  font-size: ${({ theme }) => theme.fontSize.normal};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

export const UploadStatusContainer = styled(DropZoneContainer, {
  shouldForwardProp: (prop) => prop !== 'status',
})<UploadStatusProps>`
  justify-content: space-between;
  border: 1px solid
    ${({ theme, status }) => {
      switch (status) {
        case 'success':
          return theme.colors.successGreen;
        case 'error':
          return theme.colors.dangerRed;
        case 'uploading':
          return theme.colors.lightBlue;
        case 'ready':
        default:
          return theme.colors.deepGrey;
      }
    }};
  :hover {
    background-color: 'transparent';
  }
  animation: ${fadeIn} 1.5s linear;
`;
