import { useQuery } from '@apollo/client';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  IconButton,
  Typography,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Waypoint } from 'react-waypoint';
import { GameTurnsDocument } from '../../../../graphql/types';
import background from '../../../../images/backgrounds/stars.png';
import FullscreenLoader from '../../../FullscreenLoader';
import TurnSummary from '../../AdminGamePlay/TurnSummary';
import { QuestionContainer } from '../styled';

const GameSummary = React.forwardRef<
  HTMLDivElement,
  {
    gameId: number;
    handleClose: () => void;
  }
>(({ gameId, handleClose }, ref) => {
  const { t } = useTranslation();

  const { data, fetchMore, loading } = useQuery(GameTurnsDocument, {
    variables: { id: gameId },
    notifyOnNetworkStatusChange: true,
  });

  const turns = data?.gameById.turns;

  if (!turns) {
    return <FullscreenLoader ref={ref} />;
  }

  const loadingMore = loading;
  const loadMore = () => {
    if (turns.pageInfo.hasNextPage && turns.pageInfo.endCursor) {
      fetchMore({
        variables: {
          cursor: turns.pageInfo.endCursor,
        },
      });
    }
  };

  return (
    <Box
      ref={ref}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        alignItems: 'center',
        backgroundImage: `url(${background})`,
        gap: '15px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          alignItems: 'center',
          padding: '15px',
        }}
      >
        <Typography variant="h4" sx={{ color: 'white' }}>
          {t('label.gameSummary', 'Game Summary')}
        </Typography>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box sx={{ overflow: 'auto', width: '100%', padding: '0 20%' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
          }}
        >
          {turns.edges.map((turn, index) => {
            return (
              <QuestionContainer key={index}>
                <Accordion
                  TransitionProps={{ unmountOnExit: true }}
                  sx={{ backgroundColor: '#00000033' }}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Typography variant="h5" sx={{ marginRight: '1rem' }}>
                        {index + 1}
                      </Typography>
                      <Typography>{turn.node.question.title}</Typography>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box sx={{ maxHeight: '20rem', overflowY: 'auto' }}>
                      <TurnSummary turnId={turn.node.id} />
                    </Box>
                  </AccordionDetails>
                </Accordion>
              </QuestionContainer>
            );
          })}
          {!loadingMore && <Waypoint onEnter={loadMore} bottomOffset={-100} />}
        </Box>
      </Box>
    </Box>
  );
});

export default GameSummary;
