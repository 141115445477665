const deleteMedia = (url: string): Promise<any> => {
  return fetch('https://quiz-upload.tracktl.in/media', {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json; charset=utf-8 ',
      Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
    },
    body: JSON.stringify({
      filepath: url.split('/')[4] + '/' + url.split('/')[5],
    }),
  }).then((response) => {
    const statusCode = response.status;
    const responseJSON = response.json();
    return Promise.all([statusCode, responseJSON]);
  });
};

export default deleteMedia;
