import { useMutation, useQuery } from '@apollo/client';
import { Box, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  JoinGameDocument,
  MeDocument,
  Player,
  PlayerSpaceByIdDocument,
  PlayerSpaceByIdQuery,
} from '../../graphql/types';
import { useSpaceId } from '../../hooks/useSpaceId';
import { snackbarErrorConfig } from '../../utils/Snackbars/snackbar.configs';
import FullscreenLoader from '../FullscreenLoader';
import NoSpace from '../NoSpace';
import PlayerAvatar from '../PlayerAvatar';
import HubHeader from './HubHeader';
import PlayerGameItem from './PlayerGameItem';

const ClientHub: React.FC = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const spaceId = useSpaceId();
  const navigate = useNavigate();

  const { data: userData } = useQuery(MeDocument);
  const player = userData?.me as Player | undefined;

  const { loading, data: spaceData } = useQuery(PlayerSpaceByIdDocument, {
    variables: { id: spaceId },
  });

  const ongoingGames = useMemo(() => {
    return !spaceData
      ? []
      : spaceData.spaceById.games.edges.filter(
          (game) => game.node.openedAt !== null && game.node.closedAt === null,
        );
  }, [spaceData]);

  const availableGames = useMemo(() => {
    return !spaceData
      ? []
      : spaceData.spaceById.games.edges.filter(
          (game) => game.node.openedAt === null && game.node.closedAt === null,
        );
  }, [spaceData]);

  const [joinGame] = useMutation(JoinGameDocument);

  const goToGame = async (
    game: PlayerSpaceByIdQuery['spaceById']['games']['edges'][number]['node'],
  ) => {
    if (!game?.me) {
      try {
        await joinGame({
          variables: {
            id: game.id,
          },
        });
      } catch (error: any) {
        enqueueSnackbar(error.message, snackbarErrorConfig);
        return;
      }
    }
    navigate(`/games/${game.id}/play`);
  };

  if (loading) {
    return <FullscreenLoader />;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        height: '100%',
      }}
    >
      {!spaceData ? (
        <NoSpace />
      ) : (
        <>
          {spaceData && <HubHeader space={spaceData.spaceById} />}
          <Box
            sx={{
              padding: '15px 10px 30px 10px',
              display: 'flex',
              flexDirection: 'column',
              gap: '25px',
              overflow: 'auto',
            }}
          >
            {player && (
              <Box
                sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}
              >
                <Typography variant="h5">
                  {t('label.myRanking', 'My Ranking')}
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    padding: '10px',
                    alignItems: 'center',
                    border: '#67686A',
                    borderWidth: '1px',
                    borderRadius: '10px',
                    borderStyle: 'solid',
                    backgroundColor: '#FFFFFF0C',
                    justifyContent: 'space-between',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      gap: '10px',
                      alignItems: 'center',
                    }}
                  >
                    <PlayerAvatar name={player?.name} />
                    <Typography variant="body1">{player?.name}</Typography>
                  </Box>
                  <Typography variant="body1">
                    {player?.score} points
                  </Typography>
                </Box>
              </Box>
            )}
            {ongoingGames.length > 0 && (
              <Box
                sx={{ display: 'flex', flexDirection: 'column', gap: '15px' }}
              >
                <Typography variant="h5">
                  {t('label.ongoingGames', 'Ongoing Games')}
                </Typography>
                {ongoingGames.map((game) => (
                  <PlayerGameItem
                    key={game.node.id}
                    game={game.node}
                    onNavigate={() => goToGame(game.node)}
                  />
                ))}
              </Box>
            )}
            {availableGames.length > 0 && (
              <Box
                sx={{ display: 'flex', flexDirection: 'column', gap: '15px' }}
              >
                <Box>
                  <Typography variant="h6" fontWeight="700">
                    {t('label.joinTheGame', 'Join the other players!')}
                  </Typography>
                  <Typography variant="body2">
                    {t('sublabel.joinTheGame', 'A game is about to begin!')}
                  </Typography>
                </Box>
                {availableGames.map((game) => (
                  <PlayerGameItem
                    key={game.node.id}
                    game={game.node}
                    onNavigate={() => goToGame(game.node)}
                  />
                ))}
              </Box>
            )}
          </Box>
        </>
      )}
    </Box>
  );
};

export default ClientHub;
