import { ThemeProvider as EmotionThemeProvider } from '@emotion/react';
import { ThemeProvider } from '@mui/material/styles';
import { EventSourcePolyfill } from 'event-source-polyfill';
import React from 'react';
import App from './components/App';
import './style.css';
import { emotionTheme, theme } from './theme';
import reportWebVitals from './utils/reportWebVitals';
import { createRoot } from 'react-dom/client';

global.EventSource = EventSourcePolyfill;

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <EmotionThemeProvider theme={emotionTheme}>
        <App />
      </EmotionThemeProvider>
    </ThemeProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
