import { Autocomplete, Box, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SpaceByIdQuery } from '../../../../graphql/types';
import McqItem from '../McqItem';
import { McqItemContainer } from './styled';

type SpaceMCQs = SpaceByIdQuery['spaceById']['mcqs']['edges'];

const McqManager: React.FC<{
  mcqs: SpaceMCQs;
  selectedMcqs: SpaceMCQs;
  onChange: (mcqs: SpaceMCQs) => void;
}> = ({ mcqs, selectedMcqs, onChange }) => {
  const { t } = useTranslation();

  const addMcq = (mcq?: SpaceMCQs[number]) => {
    if (mcq) {
      onChange([...selectedMcqs, mcq]);
    }
  };

  const removeMcq = (mcq: SpaceMCQs[number]) => {
    onChange(
      selectedMcqs.filter(
        ({ node: selectedMcq }) => selectedMcq.id !== mcq.node.id,
      ),
    );
  };

  const [inputValue, setInputValue] = useState('');
  const [value, setValue] = useState<SpaceMCQs[number] | null>(null);

  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', gap: '20px', flex: 1 }}
    >
      <Autocomplete
        inputValue={inputValue}
        value={value}
        options={mcqs.filter(
          (mcq) =>
            !selectedMcqs.some(
              (selectedMcq) => selectedMcq.node.id === mcq.node.id,
            ),
        )}
        getOptionLabel={(option) => option.node.name}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t('select.label.default', 'Type to search available MCQs')}
          />
        )}
        renderOption={(props, option) => (
          <Box component="li" {...props}>
            <Box sx={{ marginLeft: '15px' }}>
              <Typography sx={{ fontWeight: 700 }}>
                {option.node.name}
              </Typography>
              <Typography variant="body2">
                {t('label.plural.questions', {
                  defaultValue: ' {{ questionCount }} question(s)',
                  questionCount: option.node.questions.totalCount,
                })}
              </Typography>
            </Box>
          </Box>
        )}
        onChange={(_, mcq) => {
          addMcq(mcq!);
          setInputValue('');
          setValue(null);
        }}
        onInputChange={(_, inputValue) => {
          setInputValue(inputValue);
        }}
      />
      <McqItemContainer>
        {selectedMcqs.map((selectedMcq) => (
          <McqItem
            key={selectedMcq.node.id}
            mcq={selectedMcq}
            removeMcq={removeMcq}
          />
        ))}
      </McqItemContainer>
    </Box>
  );
};

export default McqManager;
