import { useQuery } from '@apollo/client';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Divider, IconButton, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  BasicParticipationFragment,
  GameRankingDocument,
} from '../../../graphql/types';
import background from '../../../images/backgrounds/stars.png';
import FullscreenLoader from '../../FullscreenLoader';
import { RankContext } from '../../ScoreBoard/ParticipantRankItem';
import Ranking from '../../ScoreBoard/Ranking';
import PlayerProfile from './PlayerProfile';

const RankingModal = React.forwardRef<
  HTMLDivElement,
  {
    gameId: number;
    handleClose: () => void;
    gameContext: RankContext;
    me: BasicParticipationFragment;
  }
>(({ gameId, handleClose, gameContext, me }, ref) => {
  const { t } = useTranslation();

  const { data, fetchMore, loading } = useQuery(GameRankingDocument, {
    variables: { id: gameId },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });

  const ranking = data?.gameById.ranking;

  if (!ranking) {
    return <FullscreenLoader ref={ref} />;
  }

  return (
    <Box
      ref={ref}
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        alignItems: 'center',
        marginBottom: '15px',
        backgroundImage: `url(${background})`,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          alignItems: 'center',
          padding: '15px',
        }}
      >
        <Typography variant="h5" sx={{ color: 'white' }}>
          {t('label.scoreboard', 'Leaderboard')}
        </Typography>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <PlayerProfile me={me} playerCount={ranking.totalCount} />
      <Divider sx={{ color: '#7F8FA4', width: '100%' }} />
      <Ranking
        ranking={ranking.edges}
        size="default"
        gameContext={gameContext}
        loadingMore={loading}
        loadMore={() => {
          if (ranking.pageInfo.hasNextPage && ranking.pageInfo.endCursor) {
            fetchMore({
              variables: {
                cursor: ranking.pageInfo.endCursor,
              },
            });
          }
        }}
      />
    </Box>
  );
});

export default RankingModal;
