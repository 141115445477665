import { useContext } from 'react';
import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom';
import ClientGame from '../components/ClientGame';
import ClientResult from '../components/ClientGame/ClientResult';
import ClientHub from '../components/ClientHub';
import ClientLogin from '../components/ClientLogin';
import RoutesLayout from '../components/RoutesLayout';
import { AuthContext } from '../context/auth';

const ClientRoutes = () => {
  return (
    <Routes>
      <Route index element={<ClientLogin />} />
      <Route path="/:code" element={<ClientLogin />} />
      <Route
        element={
          <RequireAuth>
            <RoutesLayout>
              <Outlet />
            </RoutesLayout>
          </RequireAuth>
        }
      >
        <Route path="/spaces/:spaceId" element={<ClientHub />} />
        <Route path="/games/:gameId/play" element={<ClientGame />} />
        <Route path="/games/:gameId/results" element={<ClientResult />} />
      </Route>
    </Routes>
  );
};

const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const { user } = useContext(AuthContext);
  let location = useLocation();

  if (!user) {
    return <Navigate to="/" state={{ from: location }} />;
  }

  return children;
};

export default ClientRoutes;
