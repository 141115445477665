import styled from '@emotion/styled';
import { Box, LinearProgress } from '@mui/material';

export const FormItem = styled(Box)`
  display: flex;
  flex-direction: column;
`;

export const CustomForm = styled.form`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 5px;
`;

export const StepTitleContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 5px;
  flex: 1;
  text-align: center;
`;

export const StepProgress = styled(LinearProgress)`
  background: rgba(255, 255, 255, 0.15);
  & .MuiLinearProgress-barColorPrimary {
    background: linear-gradient(270deg, #00aaff 0%, #0088cc 100%);
  }
`;
