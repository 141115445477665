import { useMutation } from '@apollo/client';
import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import deleteMedia from '../../../API/deleteMedia';
import postMedia from '../../../API/postMedia';
import {
  CreateSpaceDocument,
  ReadSpacesDocument,
  ReadSpacesQuery,
  UpdateSpaceDocument,
} from '../../../graphql/types';
import CustomFormLabel from '../../CustomFormLabel';
import DropZone from '../../DropZone';
import GenericButton from '../../GenericButton';
import PictureHolder from '../../PictureHolder';
import { ModalBody, ModalFooter, ModalHeader, PageContainer } from './styled';

type PartialSpace = ReadSpacesQuery['spaces']['edges'][number]['node'];

const SpaceForm = React.forwardRef<
  HTMLDivElement,
  {
    handleClose: () => void;
    selectedSpace?: PartialSpace;
  }
>(({ handleClose, selectedSpace }, ref) => {
  const { t } = useTranslation();
  const [fileToUpload, setFileToUpload] = useState<File | null>(null);

  const {
    register,
    handleSubmit,
    formState,
    reset,
    getValues,
    setValue,
    trigger,
  } = useForm<{
    name: string;
    url: string;
    image: string;
  }>({
    defaultValues: {
      name: selectedSpace?.name || '',
      url: selectedSpace?.url || '',
      image: selectedSpace?.image || '',
    },
  });

  const [createSpace] = useMutation(CreateSpaceDocument, {
    update(cache, { data }) {
      if (data) {
        cache.updateQuery({ query: ReadSpacesDocument }, (spacesData) =>
          spacesData
            ? {
                spaces: {
                  totalCount: spacesData.spaces.totalCount + 1,
                  edges: [
                    ...spacesData.spaces.edges,
                    { node: data.createSpace },
                  ],
                },
              }
            : undefined,
        );
      }
    },
  });

  const [updateSpaceMutation] = useMutation(UpdateSpaceDocument);

  const onSubmit = handleSubmit(async (data) => {
    if (fileToUpload) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_code, { url }] = await postMedia(fileToUpload);
      data.image = url;
    }

    if (selectedSpace) {
      await updateSpaceMutation({
        variables: {
          id: selectedSpace.id,
          name: data.name,
          url: data.url,
          image: data?.image,
        },
      });
    } else {
      await createSpace({
        variables: {
          name: data.name,
          url: data.url,
          image: data?.image || null,
        },
      });
    }
    reset();
    handleClose();
  });

  const deleteImage = async () => {
    const data = getValues();
    if (selectedSpace) {
      await updateSpaceMutation({
        variables: {
          id: selectedSpace.id!,
          name: data.name,
          url: data.url,
          image: null,
        },
      });
    }
    deleteMedia(selectedSpace?.image!);
    setValue('image', '');
    trigger();
  };

  const { ref: nameRef, ...nameRest } = register('name', {
    required: true,
  });
  const { ref: urlRef, ...urlRest } = register('url', {
    required: true,
  });

  return (
    <PageContainer ref={ref}>
      <form onSubmit={onSubmit}>
        <ModalHeader>
          <Typography variant="h4">
            {selectedSpace
              ? t('action.updateSpace', 'Update space')
              : t('action.createSpace', 'Create space')}
          </Typography>
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </ModalHeader>
        <ModalBody>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
            <div>
              <CustomFormLabel label={t('label.spaceName', 'Name of space')} />
              <TextField
                required
                id="name"
                fullWidth
                error={!!formState.errors.name}
                {...nameRest}
                inputRef={nameRef}
              />
            </div>
            <div>
              <CustomFormLabel
                label={t('label.spaceURL', 'URL of the space')}
              />
              <TextField
                required
                id="url"
                error={!!formState.errors.url}
                fullWidth
                {...urlRest}
                inputRef={urlRef}
              />
            </div>
            <div>
              <CustomFormLabel label={t('textfield.label.cover', 'Cover')} />
              {!!getValues('image') ? (
                <PictureHolder
                  picture={getValues('image')}
                  onDelete={deleteImage}
                />
              ) : (
                <DropZone
                  onFileDropped={setFileToUpload}
                  acceptedTypes={[{ type: 'image', width: 400, height: 400 }]}
                />
              )}
            </div>
          </Box>
        </ModalBody>
        <ModalFooter>
          <GenericButton
            onClick={handleClose}
            variant="text"
            disabled={formState.isSubmitting}
            label={t('action.cancel', 'Cancel')}
          />
          <GenericButton
            type="submit"
            variant="contained"
            disabled={
              !!formState.errors.name ||
              !!formState.errors.url ||
              formState.isSubmitting
            }
            label={selectedSpace ? 'Enregistrer' : 'Créer'}
          />
        </ModalFooter>
      </form>
    </PageContainer>
  );
});

export default SpaceForm;
