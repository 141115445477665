import styled from '@emotion/styled';
import Button from '@mui/material/Button';

interface ChoiceBackgroundProps {
  sent: boolean;
  selected: boolean;
  correct: boolean;
  wrong: boolean;
}

export const PlayerChoiceButton = styled(Button, {
  shouldForwardProp: (prop) =>
    prop !== 'sent' &&
    prop !== 'selected' &&
    prop !== 'correct' &&
    prop !== 'wrong',
})<ChoiceBackgroundProps>`
  min-height: 3.4rem;
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 30px;
  padding: 10px 15px;
  background-color: ${({ theme, selected, correct, wrong }) => {
    if (selected) {
      return theme.colors.lightBlue;
    }
    if (correct) {
      return theme.colors.successGreen;
    }
    if (wrong) {
      return theme.colors.dangerRed;
    }
    return theme.colors.white;
  }};
  color: ${({ theme, selected, correct, wrong }) => {
    if (selected || correct || wrong) {
      return theme.colors.white;
    }
    return theme.colors.midnightBlue;
  }};
  :hover {
    background-color: ${({ theme, selected, correct, wrong }) => {
      if (selected) {
        return theme.colors.lightBlue;
      }
      if (correct) {
        return theme.colors.successGreen;
      }
      if (wrong) {
        return theme.colors.dangerRed;
      }
      return theme.colors.white;
    }};
  }
  &.Mui-disabled {
    color: ${({ theme, selected, correct, wrong }) => {
      if (selected || correct || wrong) {
        return theme.colors.white;
      }
      return theme.colors.midnightBlue;
    }};
  }
`;
