import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Box, IconButton, Typography } from '@mui/material';
import React from 'react';
import { GlobalMcqsQuery, SpaceByIdQuery } from '../../../graphql/types';
import { ListItemContainer } from './styled';

const McqListItem: React.FC<{
  mcq:
    | SpaceByIdQuery['spaceById']['mcqs']['edges'][number]['node']
    | GlobalMcqsQuery['globalMcqs']['edges'][number]['node'];
  onEdit: () => void;
  onDelete: () => void;
  onNavigate: () => void;
  isSuperAdmin: boolean;
}> = ({ mcq, onEdit, onDelete, onNavigate, isSuperAdmin }) => (
  <ListItemContainer>
    <Box
      sx={{
        display: 'flex',
        flex: 5,
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
      onClick={onNavigate}
    >
      <Box>
        <Typography variant="h5">{mcq.name}</Typography>
        <Box>
          <Typography variant="body2" color="text.secondary">
            {mcq.questions.totalCount} questions
          </Typography>
        </Box>
      </Box>
    </Box>
    {(mcq.space !== null || isSuperAdmin) && (
      <Box
        sx={{
          display: 'flex',
          flex: 0.5,
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <IconButton aria-label="edit" name="edit" onClick={onEdit}>
          <EditIcon />
        </IconButton>
        <IconButton aria-label="delete" name="delete" onClick={onDelete}>
          <DeleteIcon />
        </IconButton>
      </Box>
    )}
  </ListItemContainer>
);

export default McqListItem;
