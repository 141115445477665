import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import GroupIcon from '@mui/icons-material/Group';
import QuizIcon from '@mui/icons-material/Quiz';
import { Box, IconButton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { SpaceByIdQuery } from '../../../graphql/types';
import CoverPlaceholder from '../../../images/no_game_cover.png';
import RoundedStatus from '../../RoundedStatus';
import {
  CustomCode,
  CustomDivider,
  InfoContainer,
  ListItemContainer,
} from './styled';

const GameListItem: React.FC<{
  game: SpaceByIdQuery['spaceById']['games']['edges'][number]['node'];
  onEdit: () => void;
  onClone: () => void;
  onDelete: () => void;
  onNavigate: () => void;
}> = ({ game, onEdit, onClone, onDelete, onNavigate }) => {
  const { t } = useTranslation();

  return (
    <ListItemContainer>
      <InfoContainer onClick={onNavigate}>
        <Box
          sx={{
            display: 'flex',
            width: '20%',
            minWidth: '10%',
            aspectRatio: '2.2',
            backgroundImage: `url(${game.image ?? CoverPlaceholder})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            borderRadius: '10px',
          }}
        />
        <Box
          sx={{
            display: 'flex',
            flex: 1,
            justifyContent: 'center',
            alignItems: 'flex-start',
            flexDirection: 'column',
          }}
        >
          <Typography variant="h5" sx={{ color: 'white' }}>
            {game.name}
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
            <QuizIcon sx={{ color: 'text.secondary' }} />
            <Typography variant="body1" sx={{ color: 'text.secondary' }}>
              {t('label.plural.questions', {
                questionCount: game.turnCount,
                defaultValue: `{{ questionCount }} questions`,
              })}
            </Typography>
          </Box>
          <CustomCode>{game.code}</CustomCode>
        </Box>
        <Box sx={{ display: 'flex', gap: '15px', paddingX: '10px' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '5px',
              minWidth: '80px',
            }}
          >
            <GroupIcon sx={{ color: 'text.secondary' }} />
            <Typography variant="body1" sx={{ color: 'text.secondary' }}>
              {game.ranking.totalCount} {t('label.players', 'players')}
            </Typography>
          </Box>
          <Box sx={{ minWidth: '80px' }}>
            <RoundedStatus
              isFinished={game.closedAt}
              isStarted={game.openedAt && !game.closedAt}
            />
          </Box>
        </Box>
      </InfoContainer>
      <CustomDivider orientation="vertical" flexItem />
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <IconButton aria-label="clone" name="clone" onClick={onClone}>
          <ContentCopyIcon />
        </IconButton>
        <IconButton
          aria-label="edit"
          name="edit"
          onClick={onEdit}
          disabled={game.closedAt || game.openedAt}
        >
          <EditIcon />
        </IconButton>
        <IconButton
          aria-label="delete"
          name="delete"
          onClick={onDelete}
          disabled={game.openedAt && !game.closedAt}
        >
          <DeleteIcon />
        </IconButton>
      </Box>
    </ListItemContainer>
  );
};

export default GameListItem;
