import styled from '@emotion/styled';

export const ChoiceContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  min-height: 5rem;
  border: 1px solid ${({ theme }) => theme.colors.grey};
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 0.625rem;
  padding: 1rem;
`;
