import styled from '@emotion/styled';
import { Box, TextField } from '@mui/material';
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';
import InputBase from '@mui/material/InputBase';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';

export const CustomForm = styled.form`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 1rem;
`;

export const PaperForm = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
`;

interface InputContainerProps {
  isSelected: boolean;
}

export const InputContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isSelected',
})<InputContainerProps>`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  border-radius: 0.625rem;
  padding: 0.5rem 1rem 0.5rem 0.5rem;
  position: relative;
  border-radius: 0.625rem;
  border-width: ${({ isSelected }) => (isSelected ? '3px' : '1px')};
  border-style: solid;
  border-color: ${({ theme, isSelected }) =>
    isSelected ? theme.colors.lightBlue : theme.colors.grey};
`;

export const ErrorLabel = styled.p`
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  color: ${({ theme }) => theme.colors.dangerRed};
  font-size: ${({ theme }) => theme.fontSize.small};
  text-transform: uppercase;
  margin-left: 1rem;
`;

export const BootstrapInput = withStyles(() =>
  createStyles({
    root: {
      width: '100%',
    },
    input: {
      width: '100%',
      borderRadius: 4,
      position: 'relative',
      border: '1px solid #ced4da',
      fontSize: 15,
      fontWeight: 500,
      padding: '10px 26px 10px 12px',
      //   transition: theme.transitions.create(['border-color', 'box-shadow']),
      '&:focus': {
        borderRadius: 4,
        borderColor: '#80bdff',
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      },
    },
  }),
)(InputBase);

export const AudioContainer = styled.div`
  width: 90%;
`;

export const OrangeCheckbox = withStyles({
  root: {
    '&$checked': {
      color: '#00AEFF',
    },
  },
  checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

export const FormChangedAlert = styled(Box)({
  background: 'rgba(0, 0, 0, 0.8)',
  borderRadius: '10px',
  padding: '15px 30px',
  color: 'white',
  textAlign: 'center',
  fontWeight: 600,
  fontSize: '14px',
});

export const TitleTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    fontSize: '48px',
    fontWeight: 'bold',
  },
  '& .MuiOutlinedInput-input': {
    textAlign: 'center',
  },
});
