import { Box } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { OldScore, ScoreVariation, ScoreIcon, NewScore } from './styled';

const ScoreEvolution: React.FC<{ oldScore: number; newScore: number }> = ({
  oldScore,
  newScore,
}) => {
  const { t } = useTranslation();
  const scoreVariation = newScore - oldScore;

  return (
    <Box
      display="flex"
      flex="0.5"
      flexDirection="row"
      justifyContent="flex-end"
      alignItems="center"
    >
      {scoreVariation !== 0 && (
        <>
          <OldScore>
            {t('label.plural.pts', {
              defaultValue: '{{score}} pts',
              score: oldScore,
            })}
          </OldScore>
          <ScoreVariation variant={scoreVariation}>
            {scoreVariation > 0 && '+'}
            {scoreVariation}
          </ScoreVariation>
          <ScoreIcon />
        </>
      )}
      <NewScore variant={scoreVariation}>
        {t('label.plural.pts', {
          defaultValue: '{{score}} pts',
          score: newScore,
        })}
      </NewScore>
    </Box>
  );
};

export default ScoreEvolution;
