import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { GameRankingQuery } from '../../graphql/types';
import SpeedIcon from '@mui/icons-material/Speed';
import AnswerSpeed from '../AnswerSpeed';
import { MedalContainer, PlayerName } from './styled';

const GamePodiumItem: React.FC<{
  participation: GameRankingQuery['gameById']['ranking']['edges'][number]['node'];
  rank: number;
}> = ({ participation, rank }) => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <MedalContainer rank={rank} />
      <PlayerName rank={rank}>{participation.player.name}</PlayerName>
      <Typography variant="h2">
        {t('label.plural.pts', {
          defaultValue: '{{score}} pts',
          score: participation.score,
        })}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: '5px',
        }}
      >
        <SpeedIcon sx={{ fontSize: '24px' }} />
        <AnswerSpeed speed={participation.averageSpeed!} size={'large'} />
      </Box>
    </Box>
  );
};

export default GamePodiumItem;
