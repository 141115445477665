import styled from '@emotion/styled';
import Divider from '@mui/material/Divider';

export const ListItemContainer = styled.div`
  display: flex;
  flex: 1;
  padding: 15px;
  border: 1px solid #efefef21;
  border-radius: 5px;
  :hover {
    background-color: #efefef1a;
  }
  :active {
    background-color: #efefef21;
  }
`;

export const AvatarContainer = styled.div`
  width: 2.7rem;
  height: 2.7rem;
  background-color: ${({ theme }) => theme.colors.grey};
  border-radius: 0.5rem;
  margin-right: 1rem;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex: 3;
  align-items: center;
  justify-content: space-between;
`;

export const CustomCode = styled.p`
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  color: ${({ theme }) => theme.colors.lightBlue};
  text-decoration-line: underline;
`;

export const CustomDivider = styled(Divider)`
  margin: 1rem;
`;

export const ImageContainer = styled.div`
  display: flex;
  width: 1.375rem;
`;
