import { useContext } from 'react';
import { Routes, Route, Outlet, useLocation, Navigate } from 'react-router-dom';
import RoutesLayout from '../components/RoutesLayout';
import LiveShowGame from '../components/LiveShowGame';
import { AuthContext } from '../context/auth';
import NoMatch from '../components/NoMatch';

const LiveShowRoutes = () => {
  return (
    <Routes>
      <Route
        path=":spaceId"
        element={
          <RequireAuth>
            <RoutesLayout>
              <Outlet />
            </RoutesLayout>
          </RequireAuth>
        }
      >
        <Route path="games/:gameId" element={<LiveShowGame />} />
      </Route>
      <Route path="*" element={<NoMatch />} />
    </Routes>
  );
};
const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const { user } = useContext(AuthContext);
  let location = useLocation();

  if (!user || (!user.isAdmin && !user.isSuperAdmin)) {
    return <Navigate to="/admin" state={{ from: location }} />;
  }

  return children;
};

export default LiveShowRoutes;
