import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Box, Divider, Modal } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  AdminGameByIdDocument,
  AdminGameUpdatedDocument,
  CloseGameDocument,
  GameRankingDocument,
} from '../../graphql/types';
import { useGameId } from '../../hooks/useGameId';
import { useSpaceId } from '../../hooks/useSpaceId';
import FullscreenLoader from '../FullscreenLoader';
import ModalConfirmation from '../ModalConfirmation';
import ScoreBoard from '../ScoreBoard/index';
import AdminGameHeader from './AdminGameHeader';
import AdminGameLobby from './AdminGameLobby';
import AdminGamePlay from './AdminGamePlay';
import { ContentRowContainer } from './styled';

const AdminGame: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const gameId = useGameId();
  const spaceId = useSpaceId();

  const { subscribeToMore, data } = useQuery(AdminGameByIdDocument, {
    variables: { id: gameId },
  });

  const [getRanking, { data: rankingData, fetchMore, loading }] = useLazyQuery(
    GameRankingDocument,
    {
      variables: { id: gameId },
      notifyOnNetworkStatusChange: true,
    },
  );

  useEffect(() => {
    const unsubscribe = subscribeToMore({
      document: AdminGameUpdatedDocument,
      variables: { gameId },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;

        return Object.assign({}, prev, {
          gameById: subscriptionData.data.adminGameUpdated,
        });
      },
    });

    return unsubscribe;
  }, [gameId, subscribeToMore]);

  const game = data?.gameById;
  const ranking = rankingData?.gameById?.ranking;
  const isGameFinished = game?.openedAt && game?.closedAt;

  useEffect(() => {
    if (game?.currentTurn?.endedAt || game?.currentTurn?.startedAt) {
      getRanking();
    }
  }, [game?.currentTurn?.endedAt, game?.currentTurn?.startedAt, getRanking]);

  // Updates the ranking when someone joins
  useEffect(() => {
    if (game?.ranking.totalCount) {
      getRanking();
    }
  }, [game?.ranking.totalCount, getRanking]);

  useEffect(() => {
    if (isGameFinished) {
      navigate(`/admin/${spaceId}/games/${gameId}/results`);
    }
  }, [isGameFinished, gameId, navigate, spaceId]);

  // End session
  const [isEndGameConfirmationOpen, setEndGameConfirmationOpen] =
    useState(false);
  const [closeGame] = useMutation(CloseGameDocument);

  const manualEndSession = async () => {
    setEndGameConfirmationOpen(false);
    await closeGame({
      variables: {
        id: gameId,
      },
    });
  };

  if (!game) {
    return <FullscreenLoader />;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        height: '100%',
        paddingTop: '15px',
      }}
    >
      <AdminGameHeader
        game={game}
        sendStopSignal={() => setEndGameConfirmationOpen(true)}
      />
      <ContentRowContainer>
        {game.openedAt === null &&
          game.closedAt === null &&
          game.currentTurn === null && <AdminGameLobby game={game} />}
        {game.closedAt === null && game.currentTurn && (
          <AdminGamePlay game={game} />
        )}
        <Divider sx={{ borderLeft: '1px solid #efefef1a', width: '1px' }} />
        <ScoreBoard
          gameContext={!game.currentTurn?.endedAt ? 'turnPlay' : 'turnEnd'}
          ranking={ranking?.edges ?? []}
          loadingMore={loading}
          loadMore={() => {
            if (ranking?.pageInfo.hasNextPage && ranking.pageInfo.endCursor) {
              fetchMore({
                variables: {
                  cursor: ranking.pageInfo.endCursor,
                },
              });
            }
          }}
        />
      </ContentRowContainer>
      <Modal
        open={isEndGameConfirmationOpen}
        onClose={() => setEndGameConfirmationOpen(false)}
      >
        <ModalConfirmation
          context={'danger'}
          cancelText={t('action.continuePlaying', 'Continue playing')}
          label={t('label.warning.finishGame', 'Finish the game?')}
          warningMessage={t(
            'warning.finishGame',
            'The game will finish and all participants will be lead to the final results screen.',
          )}
          confirmText={t('confirm.finishGame', 'Finish game and go to results')}
          onConfirm={manualEndSession}
          handleClose={() => setEndGameConfirmationOpen(false)}
        />
      </Modal>
    </Box>
  );
};

export default AdminGame;
