import { useQuery } from '@apollo/client';
import { Box } from '@mui/material';
import { useEffect } from 'react';
import {
  AdminGameByIdDocument,
  AdminGameUpdatedDocument,
} from '../../graphql/types';
import { useGameId } from '../../hooks/useGameId';
import FullscreenLoader from '../FullscreenLoader';
import LiveShowGameHeader from './LiveShowGameHeader';
import LiveShowGameLobby from './LiveShowGameLobby';
import LiveShowGamePlay from './LiveShowGamePlay';
import LiveShowGameResult from './LiveShowGameResult';

const LiveShowGame: React.FC = () => {
  const gameId = useGameId();

  const { subscribeToMore, data } = useQuery(AdminGameByIdDocument, {
    variables: { id: gameId },
  });

  useEffect(() => {
    const unsubscribe = subscribeToMore({
      document: AdminGameUpdatedDocument,
      variables: { gameId },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;

        return Object.assign({}, prev, {
          gameById: subscriptionData.data.adminGameUpdated,
        });
      },
    });

    return unsubscribe;
  }, [gameId, subscribeToMore]);

  const game = data?.gameById;

  if (!game) {
    return <FullscreenLoader />;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        height: '100%',
        padding: '20px 40px',
      }}
    >
      <LiveShowGameHeader game={game} />
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          flexDirection: 'row',
          borderRadius: '0.6rem',
          overflow: 'hidden',
          padding: '20px 0',
        }}
      >
        {game.openedAt === null &&
          game.closedAt === null &&
          game.currentTurn === null && (
            <LiveShowGameLobby gameCode={game.code} />
          )}
        {game.closedAt === null && game.currentTurn && (
          <LiveShowGamePlay game={game} />
        )}
        {game.openedAt && game.closedAt && (
          <LiveShowGameResult gameId={game.id} />
        )}
      </Box>
    </Box>
  );
};

export default LiveShowGame;
