import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AdminGameByIdQuery, GameResultQuery } from '../../../graphql/types';
import CoverPlaceholder from '../../../images/no_game_cover.png';

const LiveShowGameHeader: React.FC<{
  game: AdminGameByIdQuery['gameById'] | GameResultQuery['gameById'];
}> = ({ game }) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '10px 0',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: '15px',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            height: '120px',
            aspectRatio: '2.2',
            backgroundImage: `url(${game.image ?? CoverPlaceholder})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            borderRadius: '4px',
          }}
        ></Box>
        <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
          <Typography variant="h2">{game.name}</Typography>
          {game.openedAt && (
            <Box sx={{ display: 'inline' }}>
              <Typography variant="body1" sx={{ display: 'inline' }}>
                {t('label.howToJoin.firstPart', 'To play, go to ')}
              </Typography>
              <Typography
                variant="h5"
                sx={{ display: 'inline', color: 'primary.main' }}
              >
                quiz-app.tracktl.in
              </Typography>
              <Typography variant="body1" sx={{ display: 'inline' }}>
                {t('label.howToJoin.secondPart', ' and enter the code: ')}
              </Typography>
              <Typography
                variant="h4"
                sx={{ display: 'inline', color: 'primary.main' }}
              >
                {game.code}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default LiveShowGameHeader;
