import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/material';
import React, { useMemo } from 'react';
import { BasicAnswerFragment } from '../../../../../graphql/types';
import ScoreEvolution from './../ScoreEvolution';
import {
  AnswerContainer,
  AnswerSpeedMilliseconds,
  AnswerSpeedSeconds,
  CustomTimerIcon,
  ParticipantChoice,
  ParticipantName,
} from './styled';

const getTime = (speed: number) => {
  const d = new Date(Date.UTC(0, 0, 0, 0, 0, 0, speed));
  return [
    d.getUTCSeconds().toString(),
    Math.round(d.getUTCMilliseconds() / 10)
      .toString()
      .padStart(2, '0'),
  ];
};

const ParticipantAnswer: React.FC<{
  answer: BasicAnswerFragment;
  rank: number;
  withScore?: boolean;
}> = ({ answer, rank, withScore }) => {
  const time = useMemo(() => getTime(answer.speed), [answer.speed]);

  return (
    <AnswerContainer>
      <Box
        display="flex"
        flex="0.75"
        flexDirection="row"
        alignItems="center"
        justifyContent="flex-start"
      >
        <Box display="flex" flexDirection="column" paddingRight="15px">
          {answer.choice.isCorrect ? (
            <CheckIcon color="success" />
          ) : (
            <CloseIcon color="error" />
          )}
        </Box>
        <Box display="flex" flexDirection="column">
          <ParticipantName isCorrect={answer.choice.isCorrect}>
            {answer.participation.player.name}
          </ParticipantName>
          <ParticipantChoice isCorrect={answer.choice.isCorrect}>
            {answer.choice.value}
          </ParticipantChoice>
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        minWidth="4rem"
        flex="0.25"
        justifyContent="flex-end"
        alignItems="center"
      >
        <CustomTimerIcon position={rank} isCorrect={answer.choice.isCorrect} />
        <Box
          minWidth="4.5rem"
          display="flex"
          justifyContent="flex-end"
          alignItems="baseline"
        >
          <AnswerSpeedSeconds
            position={rank}
            isCorrect={answer.choice.isCorrect}
          >
            {time[0]}.
          </AnswerSpeedSeconds>
          <AnswerSpeedMilliseconds
            position={rank}
            isCorrect={answer.choice.isCorrect}
          >
            {time[1]} s
          </AnswerSpeedMilliseconds>
        </Box>
      </Box>
      {withScore && (
        <ScoreEvolution
          oldScore={answer.participation.previousScore}
          newScore={answer.participation.score}
        />
      )}
    </AnswerContainer>
  );
};

export default ParticipantAnswer;
