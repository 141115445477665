import { ErrorMessage } from '@hookform/error-message';
import { Box, TextField, Typography } from '@mui/material';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import CustomFormLabel from '../../CustomFormLabel';
import GenericButton from '../../GenericButton';
import { CustomForm } from './styled';

type FormData = {
  code: string;
};

const SessionCodeInput: React.FC<{
  setInputCode: (code: string) => void;
}> = ({ setInputCode }) => {
  const { t } = useTranslation();
  const { register, handleSubmit, reset, formState } = useForm<FormData>({
    criteriaMode: 'all',
    defaultValues: {
      code: '',
    },
    mode: 'onTouched',
  });
  const onSubmit = handleSubmit(async (data: FormData) => {
    if (data.code) {
      setInputCode(data.code);
      reset();
    }
  });

  const { ref: codeRef, ...codeRest } = register('code', {
    required: t(
      'error.codeRequired',
      'You cannot join a game without its access code.',
    ),
    minLength: {
      value: 5,
      message: t(
        'error.codeLength',
        'Your code should be at least 5 characters',
      ),
    },
  });

  return (
    <CustomForm onSubmit={onSubmit}>
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          width: '18.75rem',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            gap: '15px',
          }}
        >
          <Typography
            variant="body1"
            sx={{
              color: 'text.secondary',
              textAlign: 'center',
              fontWeight: '700',
            }}
          >
            {t(
              'label.welcomeStepCode',
              'Welcome! You have a game code? Input it below:',
            )}
          </Typography>
          <Box sx={{ marginBottom: '5px' }}>
            <CustomFormLabel
              label={t('input.label.gameCode', 'Enter a game code')}
            ></CustomFormLabel>
            <TextField
              error={!!formState.errors.code}
              required
              fullWidth
              id="code"
              placeholder={t('input.placeholder.gameCode', 'e.g. test12345')}
              autoFocus
              {...codeRest}
              inputRef={codeRef}
            />
            <ErrorMessage
              errors={formState.errors}
              name="code"
              render={({ messages }) =>
                messages &&
                Object.entries(messages).map(([type, message]) => (
                  <Typography
                    variant="body2"
                    sx={{ color: 'error.main' }}
                    key={type}
                  >
                    {message}
                  </Typography>
                ))
              }
            />
          </Box>
        </Box>
        <GenericButton
          type="submit"
          disabled={formState.isSubmitting || !formState.isValid}
          label={t('action.confirm', 'Confirm')}
        />
      </Box>
    </CustomForm>
  );
};

export default SessionCodeInput;
