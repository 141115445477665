import styled from '@emotion/styled';

export const QuestionTitle = styled.p`
  text-align: center;
  @media (min-height: 850px) {
    font-size: ${({ theme }) => theme.fontSize['48']};
  }
  font-size: ${({ theme }) => theme.fontSize.xxlarge};
  font-weight: bold;
`;

export const ParticipantsAnswerContainer = styled.div`
  @media (min-height: 850px) {
    max-height: 20rem;
  }
  max-height: 12.5rem;
  width: 90%;
  border: 1px solid ${({ theme }) => theme.colors.grey};
  border-radius: 0.625rem;
  overflow-y: auto;
  padding: 1rem;
  margin: 0 1rem;
  display: flex;
  flex: 2;
`;
