import { Box, Typography } from '@mui/material';

const CustomFormLabel: React.FC<{ label?: string; helperText?: string }> = ({
  label,
  helperText,
}) => {
  return (
    <Box sx={{ marginBottom: '5px' }}>
      {label && (
        <Typography variant="body2" sx={{ fontWeight: 700 }}>
          {label}
        </Typography>
      )}
      {helperText && (
        <Typography sx={{ fontSize: '0.75rem', color: '#EFEFEF' }}>
          {helperText}
        </Typography>
      )}
    </Box>
  );
};

export default CustomFormLabel;
