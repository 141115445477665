import { Box, Typography } from '@mui/material';
import { useMemo } from 'react';
import { PlayerChoiceButton } from './styled';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';

const PlayerChoiceOption: React.FC<{
  selected: boolean;
  sent: boolean;
  correct: boolean;
  wrong: boolean;
  choiceText: string;
  disabled: boolean;
  onClick: () => void;
}> = ({ selected, sent, correct, wrong, disabled, choiceText, onClick }) => {
  const endIcon = useMemo(() => {
    if (sent) {
      return <SendIcon />;
    }

    if (wrong) {
      return <CloseIcon />;
    }

    if (sent || correct) {
      return <CheckIcon />;
    }

    return null;
  }, [sent, wrong, correct]);

  return (
    <PlayerChoiceButton
      selected={selected}
      sent={sent}
      correct={correct}
      wrong={wrong}
      onClick={onClick}
      endIcon={endIcon}
      disabled={disabled}
    >
      {(sent || correct || wrong) && <Box sx={{ width: '24px' }}></Box>}
      <Typography sx={{ fontWeight: 700, flex: 1 }}>{choiceText}</Typography>
    </PlayerChoiceButton>
  );
};

export default PlayerChoiceOption;
