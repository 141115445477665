import { useQuery } from '@apollo/client';
import { Box, Typography } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AdminGameByIdQuery,
  TurnAnswersDocument,
} from '../../../../graphql/types';
import FullscreenLoader from '../../../FullscreenLoader';
import TurnPodiumItem from '../../../TurnPodiumItem';

const TurnPodium: React.FC<{
  game: AdminGameByIdQuery['gameById'];
}> = ({ game }) => {
  const { t } = useTranslation();
  const { data, loading } = useQuery(TurnAnswersDocument, {
    variables: { id: game!.currentTurn!.id },
    notifyOnNetworkStatusChange: true,
  });

  const answers = data?.turnById.answers;

  const fastestThree = useMemo(() => {
    if (!answers) {
      return [];
    }

    return answers.edges
      .filter((answer) => {
        return answer.node.choice.isCorrect;
      })
      .slice(0, 3);
  }, [answers]);

  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1,
        width: '100%',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        paddingY: '20px',
        alignItems: 'center',
      }}
    >
      <Box sx={{ width: '100%' }}>
        {loading ? (
          <FullscreenLoader />
        ) : (
          <>
            {fastestThree.length ? (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'flex-end',
                }}
              >
                {fastestThree[1] && fastestThree[1].node && (
                  <TurnPodiumItem answer={fastestThree[1].node} rank={2} />
                )}
                {fastestThree[0] && fastestThree[0].node && (
                  <TurnPodiumItem answer={fastestThree[0].node} rank={1} />
                )}
                {fastestThree[2] && fastestThree[2].node && (
                  <TurnPodiumItem answer={fastestThree[2].node} rank={3} />
                )}
              </Box>
            ) : (
              <Typography variant="h2" sx={{ textAlign: 'center' }}>
                {t(
                  'label.noCorrectAnswer',
                  'Noone found the correct answer :(',
                )}
              </Typography>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default TurnPodium;
