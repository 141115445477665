import GroupIcon from '@mui/icons-material/Group';
import StopIcon from '@mui/icons-material/Stop';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Box, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { AdminGameByIdQuery, GameResultQuery } from '../../../graphql/types';
import CoverPlaceholder from '../../../images/no_game_cover.png';
import GenericButton from '../../GenericButton';
import { SessionCode } from './styled';
import { useSpaceId } from '../../../hooks/useSpaceId';
import { Link } from 'react-router-dom';

const AdminGameHeader: React.FC<{
  game: AdminGameByIdQuery['gameById'] | GameResultQuery['gameById'];
  sendStopSignal?: () => void;
}> = ({ game, sendStopSignal }) => {
  const { t } = useTranslation();
  const spaceId = useSpaceId();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '10px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: '15px',
          alignItems: 'center',
          flex: 1,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            height: '100px',
            aspectRatio: '2.2',
            backgroundImage: `url(${game.image ?? CoverPlaceholder})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            borderRadius: '4px',
          }}
        ></Box>
        <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
          <Typography variant="h2">{game.name}</Typography>
          <SessionCode>{game.code}</SessionCode>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-end',
          gap: '10px',
        }}
      >
        {game.openedAt && !game.closedAt && spaceId && (
          <>
            <Link
              to={`/live/${spaceId}/games/${game.id}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <GenericButton
                label="Ouvrir l'affichage présentateur"
                startIcon={<OpenInNewIcon />}
                context="primary"
              />
            </Link>
            <GenericButton
              label={t('action.finishGame', 'Stop playing')}
              startIcon={<StopIcon />}
              onClick={sendStopSignal}
              context="secondary"
            />
          </>
        )}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography sx={{ fontSize: 30, fontWeight: 700, marginRight: 1 }}>
            {game.ranking.totalCount}
          </Typography>
          <GroupIcon sx={{ fontSize: 40 }} />
        </Box>
      </Box>
    </Box>
  );
};

export default AdminGameHeader;
