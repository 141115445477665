import { Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import TracktlLogo from '../../images/tracktl_logo.svg';
import Login from './Login';
import { AdminLoginContainer, FormContainer, ImageContainer } from './styled';

const AdminLogin: React.FC = () => {
  const { t } = useTranslation();
  return (
    <AdminLoginContainer>
      <ImageContainer>
        <img alt="tracktl_logo" src={TracktlLogo} />
      </ImageContainer>
      <FormContainer>
        <Typography
          variant="h4"
          sx={{ textAlign: 'center', marginBottom: '20px' }}
        >
          {t('label.welcome', 'Welcome!')}
        </Typography>
        <Login />
      </FormContainer>
    </AdminLoginContainer>
  );
};

export default AdminLogin;
