import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/auth';

export const useAuthRedirect = () => {
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (authContext.user) {
      if (authContext.user.isSuperAdmin) {
        navigate('/dashboard/spaces');
      } else if (authContext.user.isAdmin) {
        navigate(`/admin/${authContext.user.spaceId}/games`);
      } else {
        navigate(`/spaces/${authContext.user?.spaceId}`);
      }
    }
  }, [authContext.user, navigate]);
};
