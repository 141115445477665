import { ErrorMessage } from '@hookform/error-message';
import { Box, TextField, Typography } from '@mui/material';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import CustomFormLabel from '../../CustomFormLabel';
import GenericButton from '../../GenericButton';
import { CustomForm } from './styled';

type FormData = {
  name: string;
};

const SessionNameInput: React.FC<{ enterSession: (name: string) => void }> = ({
  enterSession,
}) => {
  const { t } = useTranslation();
  const { register, handleSubmit, formState } = useForm<FormData>({
    criteriaMode: 'all',
    defaultValues: {
      name: '',
    },
    mode: 'onTouched',
  });

  const onSubmit = handleSubmit(async (data: FormData) => {
    if (data.name) {
      enterSession(data.name);
    }
  });

  const { ref: nameRef, ...nameRest } = register('name', {
    required: t('error.nameRequired', 'You must set a name.'),
    minLength: {
      value: 3,
      message: 'Your name should be at least 3 characters',
    },
  });

  return (
    <CustomForm onSubmit={onSubmit}>
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          width: '18.75rem',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '15px',
        }}
      >
        <Box sx={{ flex: 1, marginBottom: '5px' }}>
          <CustomFormLabel
            label={t(
              'input.label.blindtestUsername',
              "You're almost there!\nEnter your full name to join the game:",
            )}
          ></CustomFormLabel>
          <TextField
            error={!!formState.errors.name}
            required
            fullWidth
            id="name"
            placeholder={t(
              'input.placeholder.blindtestUsername',
              'e.g. Richard Gilson',
            )}
            autoFocus
            {...nameRest}
            inputRef={nameRef}
          />
          <ErrorMessage
            errors={formState.errors}
            name="name"
            render={({ messages }) =>
              messages &&
              Object.entries(messages).map(([type, message]) => (
                <Typography
                  variant="body2"
                  sx={{ color: 'error.main' }}
                  key={type}
                >
                  {message}
                </Typography>
              ))
            }
          />
        </Box>
        <GenericButton
          type="submit"
          disabled={formState.isSubmitting || !formState.isValid}
          label={t('button.label.join', 'Join')}
        />
      </Box>
    </CustomForm>
  );
};

export default SessionNameInput;
