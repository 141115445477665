import { useQuery } from '@apollo/client';
import { Box } from '@mui/material';
import Modal from '@mui/material/Modal';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  ClientGameByIdDocument,
  ClientGameUpdatedDocument,
} from '../../graphql/types';
import usePrompt from '../../hooks/useBlocker';
import { useGameId } from '../../hooks/useGameId';
import FullscreenLoader from '../FullscreenLoader';
import NoSpace from '../NoSpace';
import ClientGameHeader from './ClientGameHeader';
import ClientGameQuestion from './ClientGameQuestion';
import ClientLobby from './ClientLobby';
import RankingModal from './RankingModal';

const ClientGame: React.FC = () => {
  const gameId = useGameId();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const toggleLeaderboard = useCallback(() => {
    setOpen((open) => !open);
  }, []);

  const { subscribeToMore, data, loading, error } = useQuery(
    ClientGameByIdDocument,
    {
      variables: { id: gameId },
    },
  );

  const game = data?.gameById;
  const isSessionFinished = game?.openedAt && game?.closedAt;

  useEffect(() => {
    if (!gameId) {
      return;
    }
    const unsubscribe = subscribeToMore({
      document: ClientGameUpdatedDocument,
      variables: { gameId },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;

        return Object.assign({}, prev, {
          gameById: subscriptionData.data.gameUpdated,
        });
      },
    });
    return unsubscribe;
  }, [gameId, subscribeToMore]);

  useEffect(() => {
    if (isSessionFinished) {
      navigate(`/games/${gameId}/results`);
    }
  }, [isSessionFinished, gameId, navigate]);

  usePrompt(
    t(
      'warning.leaveGame',
      "Are you sure you wish to quit the game? You won't be able to rejoin.",
    ),
    !isSessionFinished,
  );

  if (loading) {
    return <FullscreenLoader />;
  }

  if (error || !game) {
    return <NoSpace />;
  }

  const isSessionRunning = game.openedAt && !game.closedAt;
  const currentTurn = game.currentTurn;
  const me = game.me;

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
      }}
    >
      <ClientGameHeader game={game} onClick={toggleLeaderboard} />
      {!game.openedAt && <ClientLobby game={game} />}
      {isSessionRunning && currentTurn && (
        <ClientGameQuestion
          currentTurn={currentTurn}
          gameOptions={game.options}
          turnCount={game.turnCount}
        />
      )}
      <Modal
        open={open}
        onClose={toggleLeaderboard}
        hideBackdrop
        aria-labelledby="leaderboard"
      >
        <RankingModal
          gameId={gameId}
          me={me!}
          handleClose={toggleLeaderboard}
          gameContext={currentTurn?.endedAt ? 'turnEnd' : 'turnPlay'}
        />
      </Modal>
    </Box>
  );
};

export default ClientGame;
