import styled from '@emotion/styled';

const omitRank = (prop: string) => prop !== 'rank';

export const ParticipantRank = styled('p', {
  shouldForwardProp: omitRank,
})<{ rank: number }>`
  font-weight: bold;
  font-size: 12px;
  line-height: 10px;
  color: ${({ theme, rank }) => {
    switch (rank) {
      case 2:
        return theme.colors.silver;
      case 3:
        return theme.colors.bronze;
      default:
        return theme.colors.mediumGrey;
    }
  }};
`;

export const RankImage = styled('img', {
  shouldForwardProp: omitRank,
})<{ rank: number }>`
  width: 15px;
  height: 15px;
  color: ${({ theme }) => theme.colors.activeColor};
`;
