import styled from '@emotion/styled';
import Button from '@mui/material/Button';

interface CustomButtonProps {
  context?: 'primary' | 'secondary' | 'success' | 'danger';
}

export const CustomButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'context',
})<CustomButtonProps>`
  @media (min-height: 850px) {
    padding: 1rem 1.75rem;
  }
  border-radius: 5rem;
  padding: ${({ size }) => {
    if (size === 'small') {
      return '0.5rem 1rem';
    }
    return '1.1rem 1rem';
  }};
  background-color: ${({ theme, variant, context, disabled }) => {
    if (disabled) return theme.colors.disabledGrey;
    if (variant === 'contained') {
      switch (context) {
        case 'success':
          return theme.colors.successGreen;

        case 'danger':
          return theme.colors.dangerRed;

        case 'secondary':
          return theme.colors.white;

        case 'primary':
        default:
          return theme.colors.lightBlue;
      }
    }
  }};
  :hover {
    background-color: ${({ theme, variant, context, disabled }) => {
      if (!disabled && variant === 'contained') {
        switch (context) {
          case 'success':
            return theme.colors.successGreen;
          case 'danger':
            return theme.colors.dangerRedHover;
          case 'secondary':
            return theme.colors.secondaryButtonHover;
          case 'primary':
          default:
            return theme.colors.lightBlueHover;
        }
      }
    }};
  }
  :active {
    background-color: ${({ theme, variant, context, disabled }) => {
      if (!disabled && variant === 'contained') {
        switch (context) {
          case 'success':
            return theme.colors.successGreen;
          case 'danger':
            return theme.colors.dangerRedPressed;
          case 'secondary':
            return theme.colors.secondaryButtonPressed;
          case 'primary':
          default:
            return theme.colors.lightBluePressed;
        }
      }
    }};
  }
  color: ${({ theme, variant, context, disabled }) => {
    if (disabled) return theme.colors.disabledGrey;
    if (variant === 'text') {
      return '#8F8F8F';
    }
    if (context === 'secondary') {
      return theme.colors.darkBlue;
    }
    return theme.colors.white;
  }};
`;

export const CustomLabel = styled.p`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  letter-spacing: 0.1rem;
`;
