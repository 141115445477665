import { Theme } from '@emotion/react';
import styled from '@emotion/styled';
import TimerIcon from '@mui/icons-material/Timer';

type IsCorrectProps = {
  isCorrect: boolean;
};

type PositionProps = {
  position: number;
} & IsCorrectProps;

const positionShouldForwardProp = (prop: string) =>
  prop !== 'position' && prop !== 'isCorrect';

const positionColor = ({
  theme,
  position,
  isCorrect,
}: { theme: Theme } & PositionProps) => {
  if (!isCorrect) {
    return theme.colors.white;
  }
  switch (position) {
    case 1:
      return theme.colors.gold;
    case 2:
      return theme.colors.silver;
    case 3:
      return theme.colors.bronze;
    default:
      return theme.colors.white;
  }
};

export const AnswerContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  &:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

export const CustomTimerIcon = styled(TimerIcon, {
  shouldForwardProp: positionShouldForwardProp,
})<PositionProps>`
  color: ${positionColor};
`;

export const ParticipantName = styled.p<IsCorrectProps>`
  font-size: ${({ theme }) => theme.fontSize[18]};
  font-weight: ${({ theme, isCorrect }) =>
    isCorrect ? theme.fontWeight.semiBold : theme.fontWeight.medium};
  min-width: 8rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const ParticipantChoice = styled.p<IsCorrectProps>`
  color: ${({ theme, isCorrect }) =>
    isCorrect ? theme.colors.successGreen : theme.colors.dangerRed};
  font-size: ${({ theme }) => theme.fontSize.small};
  min-width: 11rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const AnswerSpeedSeconds = styled('p', {
  shouldForwardProp: positionShouldForwardProp,
})<PositionProps>`
  color: ${positionColor};
  font-size: ${({ theme }) => theme.fontSize[20]};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

export const AnswerSpeedMilliseconds = styled('p', {
  shouldForwardProp: positionShouldForwardProp,
})<PositionProps>`
  color: ${positionColor};
  font-size: ${({ theme }) => theme.fontSize.normal};
`;
