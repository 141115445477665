import { Box, Typography } from '@mui/material';
import { BasicAnswerFragment } from '../../graphql/types';
import AnswerSpeed from '../AnswerSpeed';
import { RankedTimer } from './styled';

const TurnPodiumItem: React.FC<{
  answer: BasicAnswerFragment;
  rank: number;
}> = ({ answer, rank }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          height: rank === 1 ? '100px' : rank === 2 ? '80px' : '60px',
        }}
      >
        <RankedTimer rank={rank} />
      </Box>
      <Typography variant="h2" sx={{ textAlign: 'center' }}>
        {answer.participation.player.name}
      </Typography>
      <AnswerSpeed speed={answer.speed} />
    </Box>
  );
};

export default TurnPodiumItem;
