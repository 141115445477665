import { useMutation } from '@apollo/client';
import DeleteIcon from '@mui/icons-material/Delete';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import {
  Box,
  IconButton,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useSnackbar } from 'notistack';
import React, { useEffect, useMemo, useState } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { QuestionFormData } from '..';
import deleteMedia from '../../../API/deleteMedia';
import postMedia from '../../../API/postMedia';
import {
  CreateQuestionDocument,
  DeleteMediaDocument,
  DeleteQuestionDocument,
  ReadMcqDocument,
  ReadMcqQuery,
  UpdateQuestionDocument,
} from '../../../graphql/types';
import {
  snackbarErrorConfig,
  snackbarSuccessConfig,
} from '../../../utils/Snackbars/snackbar.configs';
import AudioPlayer from '../../AudioPlayer';
import DropZone from '../../DropZone';
import GenericButton from '../../GenericButton';
import { OperationState } from '../../GenericButton/types';
import ModalConfirmation from '../../ModalConfirmation';
import QuestionPictureContainer from '../../QuestionPictureContainer';
import {
  BootstrapInput,
  CustomForm,
  ErrorLabel,
  FormChangedAlert,
  InputContainer,
  OrangeCheckbox,
  PaperForm,
  TitleTextField,
} from './styled';

type InputProps = {
  input: typeof inputsToFill[number];
  currentValueIndex: number;
  checkboxArray: Array<boolean>;
  setCheckboxArray: React.Dispatch<React.SetStateAction<boolean[]>>;
  smallScreen: boolean;
} & Pick<UseFormReturn<QuestionFormData>, 'register' | 'control' | 'setValue'>;

const Input: React.FC<InputProps> = ({
  register,
  input,
  control,
  currentValueIndex,
  checkboxArray,
  setValue,
  setCheckboxArray,
}) => {
  const { ref, ...rest } = register(input.textfieldName);

  return (
    <InputContainer
      key={currentValueIndex}
      isSelected={checkboxArray[currentValueIndex]}
    >
      <Controller
        name={input.checkboxName}
        control={control}
        render={({ field }) => (
          <OrangeCheckbox
            checkedIcon={<RadioButtonCheckedIcon />}
            icon={<RadioButtonUncheckedIcon />}
            checked={field.value as boolean}
            onChange={async (e) => {
              currentValueIndex === 0 &&
                (await setValue('firstCheckbox', !checkboxArray[0]));
              currentValueIndex === 1 &&
                (await setValue('secondCheckbox', !checkboxArray[1]));
              currentValueIndex === 2 &&
                (await setValue('thirdCheckbox', !checkboxArray[2]));
              currentValueIndex === 3 &&
                (await setValue('fourthCheckbox', !checkboxArray[3]));
              setCheckboxArray(
                checkboxArray.map((checkboxValue, index) =>
                  currentValueIndex === index ? !checkboxValue : checkboxValue,
                ),
              );
              return field.onChange(e.target.checked);
            }}
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
        )}
      />
      <TextField
        placeholder={input.label}
        required
        id={input.id}
        fullWidth
        {...rest}
        inputRef={ref}
      />
    </InputContainer>
  );
};

//Inputs to Fill
const inputsToFill = [
  {
    id: 'firstAnswer',
    label: 'First Answer',
    textfieldName: 'firstAnswer',
    checkboxName: 'firstCheckbox',
  },
  {
    id: 'secondAnswer',
    label: 'Second Answer',
    textfieldName: 'secondAnswer',
    checkboxName: 'secondCheckbox',
  },
  {
    id: 'thirdAnswer',
    label: 'Third Answer',
    textfieldName: 'thirdAnswer',
    checkboxName: 'thirdCheckbox',
  },
  {
    id: 'fourthAnswer',
    label: 'Fourth Answer',
    textfieldName: 'fourthAnswer',
    checkboxName: 'fourthCheckbox',
  },
] as const; // as const is important here https://steveholgado.com/typescript-types-from-arrays/

type CurrentMCQ = ReadMcqQuery['readMCQ'];
type MCQQuestion = CurrentMCQ['questions']['edges'][number]['node'];

type QuestionFormProps = {
  currentQuestionIndex: number;
  defaultQuestion: MCQQuestion;
  currentMcq: CurrentMCQ;
  currentQuestion: MCQQuestion;
  switchQuestion: (index: number, question: MCQQuestion) => void;
  mcqId: number;
} & Pick<
  UseFormReturn<QuestionFormData>,
  | 'register'
  | 'control'
  | 'setValue'
  | 'getValues'
  | 'formState'
  | 'handleSubmit'
>;

const QuestionForm: React.FC<QuestionFormProps> = ({
  defaultQuestion,
  currentMcq,
  currentQuestion,
  currentQuestionIndex,
  switchQuestion,
  getValues,
  setValue,
  control,
  formState,
  register,
  handleSubmit,
  mcqId,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const smallScreen = useMediaQuery('(min-height:950px)');
  const [questionSavingState, setQuestionSavingState] =
    useState<OperationState>('ready');

  const questionTitleOptions: Array<string> = useMemo(() => {
    return [
      t('label.questionTitle1'),
      t('label.questionTitle2'),
      t('label.questionTitle3'),
      t('label.questionTitle4'),
    ];
  }, [t]);

  const [createQuestion] = useMutation(CreateQuestionDocument, {
    update(cache, { data }) {
      if (data) {
        cache.updateQuery(
          { query: ReadMcqDocument, variables: { id: mcqId } },
          (mcqData) =>
            mcqData
              ? {
                  readMCQ: {
                    ...mcqData.readMCQ,
                    questions: {
                      ...mcqData.readMCQ.questions,
                      totalCount: mcqData.readMCQ.questions.totalCount + 1,
                      edges: [
                        ...mcqData.readMCQ.questions.edges,
                        { node: data.createQuestion },
                      ],
                    },
                  },
                }
              : undefined,
        );
      }
    },
  });
  const [updateQuestionMutation] = useMutation(UpdateQuestionDocument);
  const [deleteQuestion] = useMutation(DeleteQuestionDocument, {
    variables: {
      id: currentQuestion.id,
    },
    update(cache, { data }) {
      if (data) {
        cache.updateQuery(
          { query: ReadMcqDocument, variables: { id: mcqId } },
          (mcqData) =>
            mcqData
              ? {
                  readMCQ: {
                    ...mcqData.readMCQ,
                    questions: {
                      ...mcqData.readMCQ.questions,
                      totalCount: mcqData.readMCQ.questions.totalCount - 1,
                      edges: mcqData.readMCQ.questions.edges.filter(
                        (question) => question.node.id !== currentQuestion.id,
                      ),
                    },
                  },
                }
              : undefined,
        );
      }
    },
  });

  const [deleteMediaMutation] = useMutation(DeleteMediaDocument, {
    variables: {
      id: currentQuestion.id,
    },
  });

  const deleteAudioSample = async () => {
    if (currentQuestion.media) {
      await Promise.all([
        deleteMedia(currentQuestion.media),
        deleteMediaMutation(),
      ]);
    }
    setFileToUpload(null);
  };

  const onSubmitQuestion = handleSubmit(async (data) => {
    if (
      checkboxArray.filter(Boolean).length !== 1 ||
      !data.firstAnswer ||
      !data.secondAnswer ||
      !data.thirdAnswer ||
      !data.fourthAnswer
    )
      return;

    const choices = [
      { value: data.firstAnswer, isCorrect: data.firstCheckbox },
      { value: data.secondAnswer, isCorrect: data.secondCheckbox },
      { value: data.thirdAnswer, isCorrect: data.thirdCheckbox },
      { value: data.fourthAnswer, isCorrect: data.fourthCheckbox },
    ];

    //TODO could be better
    const resetInputs = (newQuestionIndex: number) => {
      setQuestionSavingState('saved');
      enqueueSnackbar(
        t('feedback.questionSaved', 'Your question has been saved.'),
        snackbarSuccessConfig,
      );
      setFileToUpload(null);
      setValue('firstCheckbox', false);
      setValue('secondCheckbox', false);
      setValue('thirdCheckbox', false);
      setValue('fourthCheckbox', false);
      setCheckboxArray([false, false, false, false]);
      switchQuestion(newQuestionIndex, defaultQuestion);
      setQuestionSavingState('ready');
    };

    try {
      setQuestionSavingState('saving');
      if (fileToUpload) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_code, { url }] = await postMedia(fileToUpload);
        data.media = url;
      }

      if (currentQuestion && currentQuestion.id) {
        await updateQuestionMutation({
          variables: {
            id: currentQuestion.id,
            title: data.questionTitle,
            media: data.media || currentQuestion.media,
            mcqId: currentMcq.id,
            choices: choices,
          },
        });
        resetInputs(currentMcq.questions.totalCount + 1);
      } else {
        await createQuestion({
          variables: {
            title: data.questionTitle,
            media: data.media,
            mcqId: currentMcq.id,
            choices: choices,
          },
        });
        resetInputs(currentMcq.questions.totalCount + 2);
      }
    } catch (error: any) {
      setQuestionSavingState('ready');
      enqueueSnackbar(error.message, snackbarErrorConfig);
    }
  });

  const handleQuestionChange = async (event: any) => {
    if (event.target.value) {
      await setValue('questionTitle', event.target.value);
    }
  };

  //TODO could be better
  const [checkboxArray, setCheckboxArray] = useState([
    false,
    false,
    false,
    false,
  ]);

  useEffect(() => {
    setCheckboxArray([
      getValues('firstCheckbox') ||
        currentQuestion?.choices[0].isCorrect ||
        defaultQuestion?.choices[0].isCorrect,
      getValues('secondCheckbox') ||
        currentQuestion?.choices[1].isCorrect ||
        defaultQuestion?.choices[1].isCorrect,
      getValues('thirdCheckbox') ||
        currentQuestion?.choices[2].isCorrect ||
        defaultQuestion?.choices[2].isCorrect,
      getValues('fourthCheckbox') ||
        currentQuestion?.choices[3].isCorrect ||
        defaultQuestion?.choices[3].isCorrect,
    ]);
  }, [currentQuestion, defaultQuestion?.choices, getValues]);

  const [fileToUpload, setFileToUpload] = useState<File | null>(null);

  const [isDeleteConfirmationModal, setIsDeleteConfirmationModal] =
    useState(false);
  const [isQuestionDeletionModalShown, showQuestionDeletionModal] =
    useState(false);

  const { ref: questionTitleRef, ...questionTitleRest } =
    register('questionTitle');
  const { isValid, isSubmitting, dirtyFields } = formState;
  return (
    <CustomForm onSubmit={onSubmitQuestion}>
      <PaperForm>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="h5">Question {currentQuestionIndex}</Typography>
          {!!currentQuestion.id && (
            <IconButton
              aria-label="delete"
              onClick={() => showQuestionDeletionModal(true)}
            >
              <DeleteIcon />
            </IconButton>
          )}
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            width: '100%',
            borderRadius: '10px',
            padding: '15px',
            border: '1px solid #7F8FA4',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flex: 0.5,
              paddingRight: '10px',
            }}
          >
            <Box sx={{ marginBottom: '15px' }}>
              <Typography
                variant="body1"
                sx={{ fontWeight: 700, marginBottom: '0.25rem' }}
              >
                {t('label.typeQuestion', 'Type your question...')}
              </Typography>
              <TitleTextField
                variant="outlined"
                multiline
                rows={2}
                id="questionTitle"
                fullWidth
                {...questionTitleRest}
                inputRef={questionTitleRef}
              />
            </Box>

            <Typography
              variant="body1"
              sx={{ fontWeight: 700, marginBottom: '0.25rem' }}
            >
              {t('label.selectQuestion', 'Or choose from a selection:')}
            </Typography>
            <Select
              labelId="preselectedQuestion"
              id="select"
              value={getValues('questionTitle') || currentQuestion?.title || ''}
              onChange={handleQuestionChange}
              input={<BootstrapInput />}
            >
              <MenuItem value="">Intitulé personnalisé</MenuItem>
              {questionTitleOptions.map((questionTitleOption, index) => (
                <MenuItem key={index} value={questionTitleOption}>
                  {questionTitleOption}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flex: 0.5,
              paddingLeft: '10px',
            }}
          >
            <Typography
              variant="body1"
              sx={{ fontWeight: 700, marginBottom: '0.25rem' }}
            >
              {t(
                'label.addMedia',
                '...If you need, add some music or a picture...',
              )}
            </Typography>
            {currentQuestion?.media ? (
              <Box
                sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}
              >
                {/(.mp3)|(.mp4)|(.m4a)/.test(currentQuestion.media) ? (
                  <AudioPlayer src={currentQuestion.media} />
                ) : (
                  <QuestionPictureContainer
                    picture={currentQuestion.media}
                    maxHeight={300}
                  />
                )}
                <GenericButton
                  type="button"
                  startIcon={<DeleteIcon />}
                  label={t('button.label.delete', 'Delete')}
                  context="secondary"
                  onClick={() => setIsDeleteConfirmationModal(true)}
                />
              </Box>
            ) : (
              <DropZone
                onFileDropped={setFileToUpload}
                acceptedTypes={[{ type: 'image' }, { type: 'audio' }]}
              />
            )}
          </Box>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
            borderRadius: '10px',
            padding: '15px',
            border: '1px solid #7F8FA4',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flex: 1,
              flexDirection: 'row',
              width: '100%',
            }}
          >
            <Typography
              variant="body1"
              sx={{ fontWeight: 700, marginBottom: '0.25rem' }}
            >
              {t(
                'label.fillSelectAnswer',
                '...Then fill the available choices:',
              )}
            </Typography>
            {checkboxArray.filter(Boolean).length !== 1 && (
              <ErrorLabel>{t('error.label.oneCorrectChoice')}</ErrorLabel>
            )}
          </Box>
          <Box
            sx={{
              display: 'grid',
              gap: '20px',
              gridTemplateColumns: '50% auto',
              width: '100%',
              paddingX: '10px',
            }}
          >
            {inputsToFill.map((input, index) => {
              return (
                <Input
                  key={input.id}
                  register={register}
                  input={input}
                  control={control}
                  currentValueIndex={index}
                  checkboxArray={checkboxArray}
                  setValue={setValue}
                  setCheckboxArray={setCheckboxArray}
                  smallScreen={smallScreen}
                />
              );
            })}
          </Box>
        </Box>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          {!!currentQuestion.id && Object.keys(dirtyFields).length > 0 && (
            <FormChangedAlert>
              {t(
                'warning.label.unsavedChanges',
                'Watch out! You have unsaved modifications on this question.',
              )}
            </FormChangedAlert>
          )}
          <GenericButton
            onClick={() => {
              switchQuestion(currentQuestionIndex, currentQuestion);
              //TODO could be better
              setCheckboxArray([
                getValues('firstCheckbox') ||
                  currentQuestion.choices[0].isCorrect ||
                  defaultQuestion.choices[0].isCorrect,
                getValues('secondCheckbox') ||
                  currentQuestion.choices[1].isCorrect ||
                  defaultQuestion.choices[1].isCorrect,
                getValues('thirdCheckbox') ||
                  currentQuestion.choices[2].isCorrect ||
                  defaultQuestion.choices[2].isCorrect,
                getValues('fourthCheckbox') ||
                  currentQuestion.choices[3].isCorrect ||
                  defaultQuestion.choices[3].isCorrect,
              ]);
            }}
            disabled={isSubmitting || !isValid}
            label={t('button.label.cancel', 'Cancel')}
            type="button"
            variant="text"
          />
          <GenericButton
            type="submit"
            operationState={questionSavingState}
            disabled={isSubmitting || !isValid}
            label={t('button.label.save', 'Save')}
          />
        </Box>
      </PaperForm>
      <Modal
        open={isQuestionDeletionModalShown}
        onClose={() => showQuestionDeletionModal(false)}
      >
        <ModalConfirmation
          context={'danger'}
          label={t('label.warning.deleteQuestion', 'Delete question?')}
          warningMessage={t(
            'warning.deleteQuestion',
            "The question and its media will be deleted. You won't be able to undo this action.",
          )}
          confirmText={t('button.label.deleteQuestion', 'Delete question')}
          onConfirm={async () => {
            await deleteQuestion();
            showQuestionDeletionModal(false);
          }}
          handleClose={() => showQuestionDeletionModal(false)}
        />
      </Modal>
      <Modal
        open={isDeleteConfirmationModal}
        onClose={() => setIsDeleteConfirmationModal(false)}
      >
        <ModalConfirmation
          context={'danger'}
          label={t('label.warning.deleteFile', 'Delete file?')}
          warningMessage={t(
            'warning.deleteFile',
            "The file will be deleted. You won't be able to undo this action.",
          )}
          confirmText={t('button.label.deleteFile', 'Delete file')}
          onConfirm={() => {
            setIsDeleteConfirmationModal(false);
            deleteAudioSample();
          }}
          handleClose={() => setIsDeleteConfirmationModal(false)}
        />
      </Modal>
    </CustomForm>
  );
};

export default QuestionForm;
