import { Box, Typography } from '@mui/material';
import React from 'react';
import { ClientGameByIdQuery } from '../../../graphql/types';
import CoverPlaceholder from '../../../images/no_game_cover.png';
import PlayerNavRanking from './PlayerNavRanking';

const ClientGameHeader: React.FC<{
  game: ClientGameByIdQuery['gameById'] | null;
  onClick?: () => void;
}> = ({ game, onClick }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        boxShadow: '0px 4px 4px rgba(0,0,0,0.15)',
        padding: '10px',
      }}
      onClick={onClick}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: '10px',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            height: '45px',
            aspectRatio: '2.2',
            backgroundImage: `url(${game?.image ?? CoverPlaceholder})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            borderRadius: '4px',
          }}
        ></Box>
        <Typography variant="body1">{game?.name}</Typography>
      </Box>
      {game && game.me && <PlayerNavRanking me={game.me} />}
    </Box>
  );
};

export default ClientGameHeader;
