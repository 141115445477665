import { Box, Typography } from '@mui/material';
import React from 'react';
import { ReadMcqQuery } from '../../../graphql/types';
import { ChoiceRightValue, QuestionListItemContainer } from './styled';

type MCQQuestions = ReadMcqQuery['readMCQ']['questions']['edges'];
type MCQQuestion = MCQQuestions[number]['node'];

const QuestionListItem: React.FC<{
  questions: MCQQuestions;
  switchQuestion: (index: number, question: MCQQuestion) => void;
  currentQuestion: MCQQuestion;
}> = ({ questions, switchQuestion, currentQuestion }) => {
  return (
    <>
      {questions.map((question, index) => (
        <QuestionListItemContainer
          key={index}
          isSelected={currentQuestion === question.node}
          onClick={() => {
            switchQuestion(index + 1, question.node);
          }}
        >
          <Typography variant="h3" sx={{ color: '#141A2B', fontWeight: 700 }}>
            {index + 1}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Typography
              variant="body1"
              sx={{ color: '#141A2B', marginBottom: '0.5rem' }}
            >
              {question.node.title}
            </Typography>
            {question.node.choices.map((choice, index) => {
              if (choice.isCorrect)
                return (
                  <ChoiceRightValue key={index}>
                    {choice.value}
                  </ChoiceRightValue>
                );
              return null;
            })}
          </Box>
        </QuestionListItemContainer>
      ))}
    </>
  );
};

export default QuestionListItem;
