import { Box, SvgIconProps, Typography } from '@mui/material';
import SpeedIcon from '@mui/icons-material/Speed';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

type Tip = {
  icon: React.ReactElement<SvgIconProps>;
  text: string;
};

const TipsMap: React.FC<{ withSpeedBonus: boolean }> = ({ withSpeedBonus }) => {
  const { t } = useTranslation();

  const generalList: Array<Tip> = useMemo(
    () => [
      {
        icon: <SpeedIcon />,
        text: t(
          'tip.tieBreak',
          'If you are tied with another player, the fastest player will win. Be swift!',
        ) as string,
      },
      {
        icon: <LockOpenIcon />,
        text: t(
          'tip.answerChange',
          'As long as the turn is ongoing, you can change your answer as often as you like!',
        ) as string,
      },
      {
        icon: <LeaderboardIcon />,
        text: t(
          'tip.leaderboard',
          'You can see the leaderboard during play by tapping on your score in the upper right corner.',
        ) as string,
      },
    ],
    [t],
  );

  const speedBonusList: Array<Tip> = useMemo(
    () => [
      {
        icon: <SpeedIcon />,
        text: t(
          'tip.speedBonus',
          'Be fast! You might get a speed bonus if you answer faster than the other players.',
        ) as string,
      },
    ],
    [t],
  );

  const list: Array<Tip> = useMemo(
    () => [...generalList, ...(withSpeedBonus ? speedBonusList : [])],
    [generalList, speedBonusList, withSpeedBonus],
  );

  const [selectedTip, selectTip] = useState(0);
  const selectRandomTip = useCallback(() => {
    selectTip(Math.floor(Math.random() * list.length));
  }, [list, selectTip]);

  useEffect(() => selectRandomTip(), [selectRandomTip]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
      <Typography
        variant="body1"
        sx={{ textAlign: 'center', fontWeight: '700', fontSize: '14px' }}
      >
        {t('label.tips', 'Tips and tricks')}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          gap: '10px',
          flexDirection: 'column',
          borderWidth: '1px',
          borderStyle: 'solid',
          borderColor: 'primary.main',
          borderRadius: '10px',
          padding: '10px 1rem',
        }}
        onClick={selectRandomTip}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: '10px',
            alignItems: 'center',
          }}
        >
          {list[selectedTip].icon}
          <Typography variant="body1" sx={{ fontSize: '0.875rem' }}>
            {list[selectedTip].text}
          </Typography>
        </Box>
        <Typography
          variant="subtitle2"
          sx={{ textAlign: 'center', fontSize: '0.75rem' }}
        >
          {t('action.seeAnotherTip', 'Tap for another tip')}
        </Typography>
      </Box>
    </Box>
  );
};

export default TipsMap;
