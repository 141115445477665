import styled from '@emotion/styled';
import { Typography } from '@mui/material';

export const SecondsValue = styled(Typography)<{ size: string }>`
  font-size: ${({ size }) => {
    switch (size) {
      case 'small':
        return '14px';
      case 'medium':
        return '18px';
      case 'large':
        return '20px';
    }
  }};
  font-weight: ${({ size }) => {
    switch (size) {
      case 'small':
        return '300';
      case 'medium':
      case 'large':
        return '800';
    }
  }};
`;

export const CentisecondsValue = styled(Typography)<{ size: string }>`
  font-size: ${({ size }) => {
    switch (size) {
      case 'small':
        return '12px';
      case 'medium':
        return '14px';
      case 'large':
        return '16px';
    }
  }};
  font-weight: ${({ size }) => {
    switch (size) {
      case 'small':
        return '300';
      case 'medium':
      case 'large':
        return '400';
    }
  }};
`;
