import { useMutation, useQuery } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import { AppBar, Box, Modal, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/auth';
import {
  DeleteMcqDocument,
  GlobalMcqsDocument,
  GlobalMcqsQuery,
} from '../../graphql/types';
import FullscreenLoader from '../FullscreenLoader';
import GenericButton from '../GenericButton';
import MCQForm from '../McqList/MCQForm';
import McqListItem from '../McqList/McqListItem';
import ModalConfirmation from '../ModalConfirmation';

type PartialMcq = GlobalMcqsQuery['globalMcqs']['edges'][number]['node'];

const GlobalMcqList: React.FC = () => {
  const { t } = useTranslation();
  let navigate = useNavigate();

  const { user } = useContext(AuthContext);
  const { data } = useQuery(GlobalMcqsDocument);

  const [isFormModalOpen, setIsFormModalOpen] = useState(false);
  const [isDeletionModalOpen, setIsDeletionModalOpen] = useState(false);
  const [selectedMcq, setSelectedMcq] = useState<PartialMcq | undefined>();

  const [deleteMcq] = useMutation(DeleteMcqDocument, {
    variables: selectedMcq
      ? {
          id: selectedMcq.id,
        }
      : undefined,
    update(cache) {
      cache.updateQuery({ query: GlobalMcqsDocument }, (data) =>
        data
          ? {
              globalMcqs: {
                ...data.globalMcqs,
                totalCount: data.globalMcqs.totalCount - 1,
                edges: data.globalMcqs.edges.filter(
                  (mcq) => mcq.node.id !== selectedMcq?.id,
                ),
              },
            }
          : undefined,
      );
    },
  });

  const handleOpen = () => {
    setIsFormModalOpen(true);
  };

  const handleClose = () => {
    setIsFormModalOpen(false);
    setIsDeletionModalOpen(false);
    setSelectedMcq(undefined);
  };

  const onConfirmDelete = async () => {
    await deleteMcq();
    setIsDeletionModalOpen(false);
    setSelectedMcq(undefined);
  };

  const openEditModal = (mcq: PartialMcq) => {
    setSelectedMcq(mcq);
    setIsFormModalOpen(true);
  };

  const openDeleteModal = (mcq: PartialMcq) => {
    setSelectedMcq(mcq);
    setIsDeletionModalOpen(true);
  };

  const navigateToMcq = (mcqId: number) => {
    navigate(`/dashboard/mcqs/${mcqId}`);
  };

  const mcqs = data?.globalMcqs;

  if (!mcqs) {
    return <FullscreenLoader />;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <AppBar
        position="sticky"
        color="primary"
        sx={{
          padding: '20px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: 'transparent',
          backgroundImage: 'none',
          boxShadow: 'none',
        }}
      >
        <Typography variant="h2">{t('label.mcqs', 'MCQs')}</Typography>
        <GenericButton
          onClick={handleOpen}
          label={t('action.createQuiz', 'Create a new quiz')}
          startIcon={<AddIcon />}
        />
      </AppBar>
      <Box
        sx={{
          display: 'grid',
          gap: '10px',
          gridTemplateColumns: '50% auto',
          padding: '15px 20px',
          overflow: 'auto',
        }}
      >
        {mcqs.edges.map((mcq) => (
          <McqListItem
            key={mcq.node.id}
            mcq={mcq.node}
            onEdit={() => openEditModal(mcq.node)}
            onDelete={() => openDeleteModal(mcq.node)}
            onNavigate={() => navigateToMcq(mcq.node.id)}
            isSuperAdmin={!!user?.isSuperAdmin}
          />
        ))}
      </Box>
      <Modal
        open={isFormModalOpen}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <MCQForm
          handleClose={handleClose}
          selectedMcq={selectedMcq}
          spaceId={null}
        />
      </Modal>
      <Modal open={isDeletionModalOpen} onClose={handleClose}>
        <ModalConfirmation
          label={t('label.warning.deleteQuiz', {
            defaultValue: 'Delete quiz {{name}}?',
            name: selectedMcq?.name,
          })}
          warningMessage={t(
            'warning.deleteQuiz',
            "This quiz and all of its resources (images and audio files) will be deleted. You won't be able to undo this action.",
          )}
          confirmText={t('button.label.deleteQuiz', 'Delete quiz')}
          context={'danger'}
          onConfirm={onConfirmDelete}
          handleClose={handleClose}
        />
      </Modal>
    </Box>
  );
};

export default GlobalMcqList;
