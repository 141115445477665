import RemoveIcon from '@mui/icons-material/Remove';
import { Box, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SpaceByIdQuery } from '../../../../graphql/types';
import { McqItemName } from './styled';

type SpaceMCQ = SpaceByIdQuery['spaceById']['mcqs']['edges'][number];

const McqItem: React.FC<{
  mcq: SpaceMCQ;
  removeMcq: (mcq: SpaceMCQ) => void;
}> = ({ mcq, removeMcq }) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Box sx={{ marginLeft: '15px' }}>
        <McqItemName>{mcq.node.name}</McqItemName>
        <Typography variant="body2">
          {t('label.plural.questions', {
            defaultValue: ' {{ questionCount }} question(s)',
            questionCount: mcq.node.questions.totalCount,
          })}
        </Typography>
      </Box>
      <IconButton
        name="delete"
        aria-label="delete"
        onClick={() => removeMcq(mcq)}
        size="large"
      >
        <RemoveIcon />
      </IconButton>
    </Box>
  );
};

export default McqItem;
