import CheckIcon from '@mui/icons-material/Check';
import { Box, Typography } from '@mui/material';
import React from 'react';
import { ReadMcqQuery } from '../../../graphql/types';
import AudioPlayer from '../../AudioPlayer';
import { ChoiceContainer } from './styled';

const QuestionVisualizer: React.FC<{
  question: ReadMcqQuery['readMCQ']['questions']['edges'][number]['node'];
}> = ({ question }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        marginX: '10%',
        gap: '50px',
        justifyContent: 'center',
      }}
    >
      <Typography
        sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '48px' }}
      >
        {question.title}
      </Typography>
      {question.media && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            marginY: '40px',
          }}
        >
          <AudioPlayer src={question.media} />
        </Box>
      )}
      <Box
        sx={{
          display: 'grid',
          gap: '20px',
          gridTemplateColumns: '50% auto',
          width: '100%',
          paddingX: '10px',
        }}
      >
        {question.choices.map((choice) => (
          <React.Fragment key={choice.id}>
            <ChoiceContainer>
              <Box sx={{ display: 'flex', flex: 1 }}>
                {choice.isCorrect && <CheckIcon color="success" />}
              </Box>
              <Box sx={{ display: 'flex', flex: 9, justifyContent: 'center' }}>
                <Typography variant="h5">{choice.value}</Typography>
              </Box>
              <Box sx={{ display: 'flex', flex: 1 }}></Box>
            </ChoiceContainer>
          </React.Fragment>
        ))}
      </Box>
    </Box>
  );
};

export default QuestionVisualizer;
