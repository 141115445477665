import { BrowserRouter, Route, Routes } from 'react-router-dom';
import NoMatch from '../components/NoMatch';
import AdminRoutes from './AdminRoutes';
import ClientRoutes from './ClientRoutes';
import DashboardRoutes from './DashboardRoutes';
import LiveShowRoutes from './LiveShowRoutes';

const AppRoutes = () => (
  <BrowserRouter>
    <Routes>
      <Route path="dashboard/*" element={<DashboardRoutes />} />
      <Route path="/*" element={<ClientRoutes />} />
      <Route path="admin/*" element={<AdminRoutes />} />
      <Route path="live/*" element={<LiveShowRoutes />} />
      <Route path="*" element={<NoMatch />} />
    </Routes>
  </BrowserRouter>
);

export default AppRoutes;
