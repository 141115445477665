import styled from '@emotion/styled';

export const CustomForm = styled.form`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 100%;
`;
