import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import MenuIcon from '@mui/icons-material/Menu';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import QuizIcon from '@mui/icons-material/Quiz';
import StarIcon from '@mui/icons-material/Star';
import {
  Box,
  IconButton,
  List,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../context/auth';
import TracktlLogo from '../../images/logo_c_white.svg';
import { CollapseContainer, CustomDrawer } from '../CustomDrawer';
import CustomLink from '../CustomLink';

const DashboardDrawer: React.FC = () => {
  const { t } = useTranslation();
  const { user, logout } = useContext(AuthContext);

  const [open, setOpen] = useState(true);
  const toggleOpen = () => setOpen((value) => !value);

  return (
    <CustomDrawer variant="permanent" anchor="left" open={open}>
      <CollapseContainer open={open}>
        <IconButton onClick={toggleOpen}>
          {open ? <ChevronLeftIcon /> : <MenuIcon />}
        </IconButton>
      </CollapseContainer>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-evenly',
          padding: '1rem 0',
          borderBottom: '1px solid rgba(0, 0, 0, 0.5)',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            backgroundImage: `url(${TracktlLogo})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            height: open ? '160px' : '60px',
            aspectRatio: '1',
            borderRadius: '10px',
          }}
        />
        <Typography variant="h6">Dashboard Global</Typography>
      </Box>
      <List
        sx={{
          paddingY: '10px',
          display: 'flex',
          flexDirection: 'column',
          gap: '15px',
          flexGrow: 1,
        }}
      >
        <CustomLink to="/dashboard/spaces">
          <ListItemIcon sx={{ color: 'inherit', justifyContent: 'center' }}>
            <StarIcon />
          </ListItemIcon>
          <ListItemText primary={t('label.spaces', 'Spaces')} />
        </CustomLink>
        <CustomLink to="/dashboard/mcqs">
          <ListItemIcon sx={{ color: 'inherit', justifyContent: 'center' }}>
            <QuizIcon />
          </ListItemIcon>
          <ListItemText primary={t('label.mcqs', 'MCQs')} />
        </CustomLink>
      </List>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: open ? 'space-between' : 'center',
          padding: '20px',
          backgroundColor: 'background.default',
        }}
      >
        <Typography variant="h5">{user?.name}</Typography>
        <IconButton onClick={logout}>
          <PowerSettingsNewIcon />
        </IconButton>
      </Box>
    </CustomDrawer>
  );
};

export default DashboardDrawer;
