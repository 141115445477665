import styled from '@emotion/styled';
import { Button } from '@mui/material';

export const QuestionListContainer = styled.div`
  display: flex;
  align-items: center;
  @media (min-height: 850px) {
    height: 13.75rem;
  }
  height: 10.75rem;
  overflow-x: scroll;
  overflow-y: hidden;
  padding: 2rem 1rem;
  background-color: #0000004d;
`;

export const ButtonContainer = styled.div`
  @media (min-height: 850px) {
    min-height: 11.2rem;
  }
  min-height: 8.2rem;
  min-width: 17rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  border: 1px dashed ${({ theme }) => theme.colors.mediumGrey};
  border-radius: 0.625rem;
  background-color: ${({ theme }) => theme.colors.mediumSoftGrey};
`;

export const CustomButton = styled(Button)`
  color: ${({ theme }) => theme.colors.lightBlue};
`;
