import { Box, Typography } from '@mui/material';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../context/auth';
import PartyOver from '../../images/party_over.svg';
import GenericButton from '../GenericButton';

const NoSpace: React.FC = () => {
  const { logout } = useContext(AuthContext);
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        padding: '20px',
        display: 'flex',
        flexDirection: 'column',
        gap: '25px',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
      }}
    >
      <Box
        sx={{
          minHeight: '20vh',
          height: '300px',
          maxHeight: '60vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            minWidth: '200px',
            width: '20rem',
            maxWidth: '70%',
            aspectRatio: '1.54',
            backgroundImage: `url(${PartyOver})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
          alignItems: 'center',
          textAlign: 'center',
        }}
      >
        <Typography variant="h5">
          {t('label.spaceDeleted', 'Looks like the party is over :(')}
        </Typography>
        <Typography variant="body1">
          {t(
            'sublabel.spaceDeleted',
            'This game space has closed and cannot be accessed.',
          )}
        </Typography>
      </Box>
      <GenericButton onClick={logout} label={t('action.goBackToHome')} />
    </Box>
  );
};

export default NoSpace;
