import { Box, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Status, StatusProps } from './styled';

const RoundedStatus: React.FC<StatusProps> = (props) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '5px',
      }}
    >
      <Status {...props} />
      <Typography
        variant="body1"
        color={
          props.isStarted
            ? 'primary.main'
            : props.isFinished
            ? 'text.disabled'
            : 'text.primary'
        }
      >
        {props.isStarted
          ? t('label.ongoing', 'Ongoing')
          : props.isFinished
          ? t('label.finished', 'Finished')
          : t('label.ready', 'Ready')}
      </Typography>
    </Box>
  );
};

export default RoundedStatus;
