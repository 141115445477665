import { useTheme } from '@emotion/react';
import { ListItem } from '@mui/material';
import { LinkProps, useResolvedPath, useMatch, Link } from 'react-router-dom';
import { CustomListItemStyle } from './styled';

const CustomLink = ({ children, to, ...props }: LinkProps) => {
  let resolved = useResolvedPath(to);
  let match = useMatch({ path: resolved.pathname, end: false });
  const theme = useTheme();

  return (
    <ListItem
      className={CustomListItemStyle(theme, !!match)}
      component={Link}
      to={to}
      {...props}
    >
      {children}
    </ListItem>
  );
};

export default CustomLink;
