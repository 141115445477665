import { useMutation } from '@apollo/client';
import { Alert, Box, Divider, Typography } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  AdminGameByIdQuery,
  OpenGameDocument,
  StartTurnDocument,
} from '../../../graphql/types';
import GenericButton from '../../GenericButton';
import { useSpaceId } from '../../../hooks/useSpaceId';

const AdminGameLobby: React.FC<{
  game: AdminGameByIdQuery['gameById'];
}> = ({ game }) => {
  const { t } = useTranslation();
  const spaceId = useSpaceId();
  const [startGame] = useMutation(OpenGameDocument);
  const [startTurn] = useMutation(StartTurnDocument);
  const [isStudioAlertShown, setIsStudioAlertShown] = useState(true);

  const openAndStartGame = async () => {
    if (!game.openedAt) {
      await startGame({
        variables: {
          id: game.id,
        },
      });
      await startTurn({
        variables: {
          gameId: game.id,
        },
      });
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        paddingX: '10%',
        gap: '20px',
      }}
    >
      {isStudioAlertShown && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '20px',
          }}
        >
          <Alert
            severity="info"
            variant="filled"
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
            onClose={() => {
              setIsStudioAlertShown(false);
            }}
          >
            <Typography variant="body1">
              <Typography sx={{ display: 'inline', fontWeight: 600 }}>
                {t(
                  'info.studioDisplay.firstPart',
                  'You are currently viewing the game in the Studio display',
                )}
              </Typography>
              {t(
                'info.studioDisplay.secondPart',
                ', where you will be able to monitor and adjust the flow of the game to deliver your players the perfect experience. ',
              )}
              <Typography sx={{ display: 'inline', fontWeight: 600 }}>
                {t(
                  'info.studioDisplay.thirdPart',
                  'To display a more appealing view to your players, we recommend you open the Live Show display',
                )}
              </Typography>
              {t(
                'info.studioDisplay.fourthPart',
                ' by clicking on the button below:',
              )}
            </Typography>
          </Alert>
          <Link
            to={`/live/${spaceId}/games/${game.id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <GenericButton
              label={t(
                'action.openLiveShow',
                'Open the Live Show Display in another tab',
              )}
              startIcon={<OpenInNewIcon />}
              context="primary"
            />
          </Link>
          <Divider sx={{ marginX: '20px', color: '#C7C7C7', width: '100%' }} />
        </Box>
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '20px',
          alignItems: 'center',
        }}
      >
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="h6">
            {t('label.gameContains', 'This game contains')}
          </Typography>
          <Typography variant="h4">
            {t('label.plural.questions', {
              defaultValue: '{{questionCount}} questions',
              questionCount: game.turnCount,
            })}
          </Typography>
        </Box>
        <Box>
          <GenericButton
            name="startSession"
            onClick={openAndStartGame}
            label={
              game.openedAt
                ? t('action.join', 'Join')
                : t('action.startGame', 'Start playing')
            }
          />
        </Box>
      </Box>
    </Box>
  );
};

export default AdminGameLobby;
