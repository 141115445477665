import DeleteIcon from '@mui/icons-material/Delete';
import { Box, IconButton, Typography } from '@mui/material';
import { SpaceByIdQuery } from '../../../graphql/types';
import { InfoContainer, ListItemContainer } from './styled';

const AdminListItem: React.FC<{
  admin: SpaceByIdQuery['spaceById']['admins']['edges'][number]['node'];
  onEdit: () => void;
  onDelete?: () => void;
}> = ({ admin, onEdit, onDelete }) => {
  return (
    <ListItemContainer>
      <InfoContainer>
        <Box
          sx={{
            display: 'flex',
            flex: 1,
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              justifyContent: 'center',
            }}
          >
            <Typography variant="h5">{admin.name}</Typography>
            <Typography variant="body1">{admin.email}</Typography>
          </Box>
        </Box>
      </InfoContainer>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {/* <IconButton aria-label="edit" name="edit" onClick={onEdit}>
          <EditIcon />
        </IconButton> */}
        {onDelete && (
          <IconButton aria-label="delete" name="delete" onClick={onDelete}>
            <DeleteIcon />
          </IconButton>
        )}
      </Box>
    </ListItemContainer>
  );
};

export default AdminListItem;
