import styled from '@emotion/styled';
import ScoreBoard from '../../ScoreBoard';

export const QuestionContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.colors.grey};
  border-radius: 0.625rem;
  overflow: hidden;
`;

export const ScrollableScoreBoard = styled(ScoreBoard)`
  padding: 1.5rem;
  min-height: 250px;
  overflow-y: auto;
  flex: 1;
  width: 50%;
  display: flex;
  align-self: center;
`;
