import styled from '@emotion/styled';

export const McqItemContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 200px;
  height: 100%;
  border: 1px solid ${({ theme }) => theme.colors.grey};
  border-radius: 10px;
  overflow-y: auto;
  gap: 15px;
  padding: 10px 0;
`;
