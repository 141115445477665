import { useQuery } from '@apollo/client';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { GameRankingDocument } from '../../../graphql/types';
import { ScrollableScoreBoard } from '../../AdminGame/AdminGameResult/styled';
import FullscreenLoader from '../../FullscreenLoader';
import GamePodiumItem from '../../GamePodiumItem';

const LiveShowGameResult: React.FC<{ gameId: number }> = ({ gameId }) => {
  const { t } = useTranslation();

  const gameRanking = useQuery(GameRankingDocument, {
    variables: { id: gameId },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
  });

  const ranking = gameRanking.data?.gameById.ranking;

  if (!ranking) {
    return <FullscreenLoader />;
  }

  const scrollableRanking =
    ranking.totalCount > 3 ? ranking?.edges.slice(3) : [];

  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        overflow: 'auto',
        paddingBottom: '15px',
      }}
    >
      <Typography variant="h2" sx={{ textAlign: 'center', paddingY: '2rem' }}>
        {t('label.gameIsFinished', 'The game is finished')}
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        {ranking.edges && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'flex-end',
            }}
          >
            {ranking.edges[1] && ranking.edges[1].node && (
              <GamePodiumItem rank={2} participation={ranking.edges[1].node} />
            )}
            {ranking.edges[0] && ranking.edges[0].node && (
              <GamePodiumItem rank={1} participation={ranking.edges[0].node} />
            )}
            {ranking.edges[2] && ranking.edges[2].node && (
              <GamePodiumItem rank={3} participation={ranking.edges[2].node} />
            )}
          </Box>
        )}
        {scrollableRanking.length > 0 && (
          <ScrollableScoreBoard
            size="large"
            gameContext="gameEnd"
            ranking={scrollableRanking}
            loadingMore={gameRanking.loading}
            loadMore={() => {}}
          />
        )}
      </Box>
    </Box>
  );
};

export default LiveShowGameResult;
