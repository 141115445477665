import styled from '@emotion/styled';

export const CustomForm = styled.form`
  width: 24.25rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
`;

export const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.2rem;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  :not(:first-of-type) {
    margin-top: 1rem;
  }
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 2.5rem;
`;
