import styled from '@emotion/styled';

interface PropsWithRank {
  rank: number;
  size: string;
}

const omitRank = (prop: string) => prop !== 'rank';

export const ParticipantName = styled('p', {
  shouldForwardProp: omitRank,
})<PropsWithRank>`
  margin-left: 1rem;
  flex: 1;
  font-weight: ${({ theme, rank }) => {
    switch (rank) {
      case 1:
        return theme.fontWeight.semiBold;
      case 2:
      case 3:
        return theme.fontWeight.medium;
      default:
        return theme.fontWeight.regular;
    }
  }};
  font-size: ${({ size }) => (size === 'large' ? '32px' : '18px')};
`;

export const ParticipantContainer = styled('div', {
  shouldForwardProp: omitRank,
})<PropsWithRank>`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 0.625rem;
  padding: ${({ size }) => (size === 'large' ? '15px' : '5px 10px')};
  gap: ${({ size }) => (size === 'large' ? '20px' : '10px')};
  border: ${({ rank, size }) => {
      if (size === 'default') {
        return '1px';
      }
      switch (rank) {
        case 1:
          return '4px';
        case 2:
          return '3px';
        case 3:
          return '2px';
        default:
          return '0px';
      }
    }}
    solid
    ${({ theme, rank }) => {
      switch (rank) {
        case 1:
          return theme.colors.gold;
        case 2:
          return theme.colors.silver;
        case 3:
          return theme.colors.bronze;
        default:
          return 'transparent';
      }
    }};
`;

export const ParticipantRank = styled('p', {
  shouldForwardProp: omitRank,
})<PropsWithRank>`
  font-weight: bold;
  font-size: ${({ size }) => (size === 'large' ? '32px' : '18px')};
  color: ${({ theme, rank }) => {
    switch (rank) {
      case 2:
        return theme.colors.silver;
      case 3:
        return theme.colors.bronze;
      default:
        return theme.colors.mediumGrey;
    }
  }};
`;

export const ParticipantScore = styled('p', {
  shouldForwardProp: omitRank,
})<PropsWithRank>`
  font-size: ${({ size }) => (size === 'large' ? '32px' : '18px')};
  font-weight: ${({ theme, rank }) => {
    switch (rank) {
      case 1:
        return 'bold';
      case 2:
        return theme.fontWeight.semiBold;
      case 3:
        return theme.fontWeight.medium;
      default:
        return theme.fontWeight.regular;
    }
  }};
`;

export const RankImage = styled('img', {
  shouldForwardProp: omitRank,
})<PropsWithRank>`
  width: 50px;
  height: ${({ rank, size }) => {
    if (size === 'large') {
      return rank === 3 ? '40px' : '50px';
    }
    return rank === 3 ? '24px' : '30px';
  }};
  color: ${({ theme }) => theme.colors.activeColor};
`;

export const RankChangeImage = styled('img', {
  shouldForwardProp: omitRank,
})<{ size: string }>`
  width: ${({ size }) => (size === 'large' ? '45px' : '20px')};
`;
