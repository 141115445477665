import { Box, Divider, IconButton, Typography } from '@mui/material';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import ProgressBar from './ProgressBar';
import { VolumeSlider } from './styled';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import ReplayIcon from '@mui/icons-material/Replay';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import { AuthContext, PlayerVolumeSettings } from '../../context/auth';
import { useDebouncedCallback } from 'use-debounce';

const getMinutes = (time: number) => {
  return Math.floor((time ?? 1 % 3600) / 60);
};

const getSeconds = (time: number) => {
  return Math.floor((time ?? 1 % 3600) % 60);
};

const formatTimerLabel = (time: number) => {
  const minutes = getMinutes(time);
  const seconds = getSeconds(time);
  return (
    (minutes < 10 ? '0' + minutes : minutes) +
    ':' +
    (seconds < 10 ? '0' + seconds : seconds)
  );
};

type AudioPlayerProps = {
  src: string;
  autoplay?: boolean;
  volume?: number;
  onVolumeChanged?: (volume: number) => void;
};

const AudioPlayer: React.FC<AudioPlayerProps> = ({
  src,
  autoplay = false,
  volume,
  onVolumeChanged,
}) => {
  const { playerSettings, setPlayerSettings } = useContext(AuthContext);
  const audioRef = useRef<HTMLAudioElement>();
  const [progressTime, setProgressTime] = useState(0);
  const [progressTimeLabel, setProgressTimeLabel] = useState('00:00');
  const [playState, setPlayState] = useState<'playing' | 'paused' | 'ended'>(
    'paused',
  );
  const [songDuration, setSongDuration] = useState('00:00');
  const [innerVolume] = useState(volume ?? playerSettings.volume);
  const [muted] = useState(playerSettings.muted);

  // Methods
  const togglePlay = useCallback(async () => {
    if (audioRef.current) {
      if (audioRef.current.paused) {
        try {
          await audioRef.current.play();
        } catch (err) {
          console.error('Audio play error: ', err);
        }
      } else {
        audioRef.current.pause();
      }
    }
  }, []);

  const replay = useCallback(() => {
    if (audioRef.current) {
      audioRef.current.currentTime = 0;
      togglePlay();
    }
  }, [togglePlay]);

  const debounced = useDebouncedCallback((settings: PlayerVolumeSettings) => {
    setPlayerSettings({
      volume: settings.volume,
      muted: settings.muted,
    });
    if (onVolumeChanged) {
      onVolumeChanged(settings.volume);
    }
  }, 500);

  const updateVolume = useCallback(
    (event: any, newValue: number | number[]) => {
      if (audioRef.current) {
        audioRef.current.volume = (newValue as number) / 100;
        debounced({
          volume: newValue as number,
          muted: audioRef.current.muted,
        });
      }
    },
    [debounced],
  );

  const muteVolume = useCallback(() => {
    if (audioRef.current) {
      audioRef.current.muted = !audioRef.current.muted;
      debounced({
        volume: audioRef.current.volume * 100,
        muted: audioRef.current.muted,
      });
    }
  }, [debounced]);

  const playerSeek = useCallback((newValue: number) => {
    if (audioRef.current) {
      audioRef.current.currentTime =
        audioRef.current.duration * (newValue / 100);
    }
  }, []);

  // Events
  const onPlay = useCallback(() => {
    setPlayState('playing');
  }, []);

  const onPause = useCallback(() => {
    setPlayState('paused');
  }, []);

  const onEnded = useCallback(() => {
    setPlayState('ended');
  }, []);

  const onLoadedMetadata = useCallback(() => {
    if (audioRef.current) {
      if (audioRef.current.duration) {
        setProgressTime(
          (audioRef.current.currentTime / audioRef.current.duration) * 100,
        );
        setProgressTimeLabel(formatTimerLabel(audioRef.current.currentTime));
        setSongDuration(formatTimerLabel(audioRef.current.duration));
      }
    }
  }, []);

  const onTimeUpdate = useCallback(() => {
    if (audioRef.current) {
      if (audioRef.current.duration) {
        setProgressTime(
          (audioRef.current.currentTime / audioRef.current.duration) * 100,
        );
        setProgressTimeLabel(formatTimerLabel(audioRef.current.currentTime));
      }
    }
  }, []);

  useEffect(() => {
    setPlayState('paused');

    if (src) {
      if (!audioRef.current) {
        audioRef.current = new Audio();
      }

      audioRef.current.crossOrigin = 'anonymous'; // enable CORS to allow SW to respond with range
      audioRef.current.loop = false;
      audioRef.current.autoplay = autoplay;
      audioRef.current.controls = false;
      audioRef.current.preload = 'auto';
      audioRef.current.src = src;
      audioRef.current.currentTime = 0;
      audioRef.current.volume = innerVolume / 100;
      audioRef.current.muted = muted;
      audioRef.current.load();

      audioRef.current.addEventListener('loadedmetadata', onLoadedMetadata);
      audioRef.current.addEventListener('timeupdate', onTimeUpdate);
      audioRef.current.addEventListener('play', onPlay);
      audioRef.current.addEventListener('pause', onPause);
      audioRef.current.addEventListener('ended', onEnded);
      // audioRef.current.addEventListener('volumechange', onVolumeChanged);

      return () => {
        if (audioRef.current) {
          audioRef.current.removeEventListener(
            'loadedmetadata',
            onLoadedMetadata,
          );
          audioRef.current.removeEventListener('timeupdate', onTimeUpdate);
          audioRef.current.removeEventListener('play', onPlay);
          audioRef.current.removeEventListener('pause', onPause);
          audioRef.current.removeEventListener('ended', onEnded);
          // audioRef.current.removeEventListener('volumechange', onVolumeChanged);
          audioRef.current.removeAttribute('src');
          audioRef.current.load();
        }
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onEnded, onLoadedMetadata, onPause, onPlay, onTimeUpdate, src, autoplay]);

  // Manipulate volume from the outside
  useEffect(() => {
    if (audioRef.current && volume) {
      audioRef.current.volume = volume / 100;
    }
  }, [volume]);

  const audioIcon =
    playState === 'ended' ? (
      <ReplayIcon />
    ) : playState === 'paused' ? (
      <PlayArrowIcon />
    ) : (
      <PauseIcon />
    );

  const volumeIcon = audioRef.current?.muted ? (
    <VolumeOffIcon />
  ) : (
    <VolumeUpIcon />
  );

  if (!audioRef.current) {
    return null;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        maxHeight: '70px',
        flexDirection: 'row',
        alignItems: 'center',
        borderRadius: '4px',
        border: '1px solid #7F8FA4',
        padding: '10px 5px',
        gap: '15px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flex: 5,
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <IconButton
          aria-label="play/pause/replay"
          onClick={playState !== 'ended' ? togglePlay : replay}
        >
          {audioIcon}
        </IconButton>
        <Typography variant="body1" sx={{ fontVariantNumeric: 'tabular-nums' }}>
          {progressTimeLabel}
        </Typography>
        <ProgressBar value={progressTime} onSeek={playerSeek} />
        <Typography variant="body1" sx={{ fontVariantNumeric: 'tabular-nums' }}>
          {songDuration}
        </Typography>
      </Box>
      <Divider orientation="vertical" flexItem />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: '10px',
          paddingRight: '15px',
        }}
      >
        <IconButton aria-label="mute" onClick={muteVolume}>
          {volumeIcon}
        </IconButton>
        <VolumeSlider
          value={audioRef.current.volume * 100}
          onChange={updateVolume}
          disabled={audioRef.current.muted}
        />
      </Box>
    </Box>
  );
};

export default AudioPlayer;
