import styled from '@emotion/styled';
import { Box, Typography } from '@mui/material';
import GoldCrown from '../../images/gold_crown.svg';
import SilverMedal from '../../images/silver_medal.svg';
import BronzeMedal from '../../images/bronze_medal.svg';

export const MedalContainer = styled(Box)<{ rank: number }>`
  height: ${({ rank }) => {
    switch (rank) {
      case 1:
        return '200px';
      case 2:
        return '100px';
      case 3:
        return '80px';
      default:
        return '80px';
    }
  }};
  aspect-ratio: ${({ rank }) => (rank === 1 ? '1.35' : '1')};
  background-image: ${({ rank }) => {
    switch (rank) {
      case 1:
        return `url(${GoldCrown})`;
      case 2:
        return `url(${SilverMedal})`;
      case 3:
        return `url(${BronzeMedal})`;
      default:
        return `url(${BronzeMedal})`;
    }
  }};
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`;

export const PlayerName = styled(Typography)<{ rank: number }>`
  font-weight: 700;
  font-size: ${({ rank }) => {
    switch (rank) {
      case 1:
        return '48px';
      case 2:
        return '40px';
      case 3:
        return '36px';
      default:
        return '36px';
    }
  }};
`;
