import { useQuery } from '@apollo/client';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import EngineeringIcon from '@mui/icons-material/Engineering';
import GamesIcon from '@mui/icons-material/Games';
import MenuIcon from '@mui/icons-material/Menu';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import QuizIcon from '@mui/icons-material/Quiz';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import {
  Box,
  IconButton,
  ListItemText,
  Tooltip,
  Typography,
} from '@mui/material';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../context/auth';
import { SpaceByIdDocument } from '../../graphql/types';
import { useSpaceId } from '../../hooks/useSpaceId';
import TracktlLogo from '../../images/logo_c_white.svg';
import { CollapseContainer, CustomDrawer } from '../CustomDrawer';
import CustomLink from '../CustomLink';
import FullscreenLoader from '../FullscreenLoader';

const AppDrawer: React.FC = () => {
  const { t } = useTranslation();
  const { user, logout } = useContext(AuthContext);
  const spaceId = useSpaceId();

  const { data } = useQuery(SpaceByIdDocument, { variables: { id: spaceId } });
  const space = data?.spaceById;

  const [open, setOpen] = useState(true);
  const toggleOpen = () => setOpen((value) => !value);

  return (
    <CustomDrawer variant="permanent" anchor="left" open={open}>
      {!space ? (
        <FullscreenLoader />
      ) : (
        <>
          <CollapseContainer open={open}>
            <IconButton onClick={toggleOpen}>
              {open ? <ChevronLeftIcon /> : <MenuIcon />}
            </IconButton>
          </CollapseContainer>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '20px',
              borderBottom: '1px solid rgba(0, 0, 0, 0.5)',
              gap: '20px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                backgroundImage: `url(${space.image ?? TracktlLogo})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                height: open ? '160px' : '60px',
                aspectRatio: '1',
                borderRadius: '10px',
              }}
            />
            <Typography variant="h6">{space.name}</Typography>
          </Box>
          <List
            sx={{
              paddingY: '10px',
              display: 'flex',
              flexDirection: 'column',
              gap: '20px',
              flexGrow: 1,
            }}
          >
            <CustomLink to="games">
              <ListItemIcon
                sx={{
                  color: 'inherit',
                  justifyContent: 'center',
                }}
              >
                <GamesIcon />
              </ListItemIcon>
              <ListItemText primary={t('label.games', 'Games')} />
            </CustomLink>
            <CustomLink to="mcqs">
              <ListItemIcon
                sx={{
                  color: 'inherit',
                  justifyContent: 'center',
                }}
              >
                <QuizIcon />
              </ListItemIcon>
              <ListItemText primary={t('label.mcqs', 'MCQs')} />
            </CustomLink>
            <CustomLink to="admins">
              <ListItemIcon
                sx={{
                  color: 'inherit',
                  justifyContent: 'center',
                }}
              >
                <SupervisorAccountIcon />
              </ListItemIcon>
              <ListItemText primary={t('label.admins', 'Admins')} />
            </CustomLink>
          </List>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: open ? 'space-between' : 'center',
              padding: '20px 10px',
              backgroundColor: 'background.default',
            }}
          >
            <Typography variant="h5" sx={{ flex: 4 }}>
              {user?.name}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                flex: open ? 2 : 0,
                justifyContent: 'flex-end',
                flexDirection: open ? 'row' : 'column',
                gap: open ? '0px' : '10px',
              }}
            >
              {user?.isSuperAdmin && (
                <Tooltip
                  title={t('action.backToDashboard', 'Back to Dashboard')}
                >
                  <IconButton component={Link} to="/dashboard/spaces">
                    <EngineeringIcon />
                  </IconButton>
                </Tooltip>
              )}
              <Tooltip title={t('action.logout', 'Logout')}>
                <IconButton onClick={logout}>
                  <PowerSettingsNewIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        </>
      )}
    </CustomDrawer>
  );
};

export default AppDrawer;
