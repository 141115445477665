import { useMutation } from '@apollo/client';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton, TextField, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  CreateMcqDocument,
  GlobalMcqsDocument,
  GlobalMcqsQuery,
  Maybe,
  SpaceByIdDocument,
  SpaceByIdQuery,
  UpdateMcqDocument,
} from '../../../graphql/types';
import { snackbarErrorConfig } from '../../../utils/Snackbars/snackbar.configs';
import CustomFormLabel from '../../CustomFormLabel';
import GenericButton from '../../GenericButton';
import { ModalFooter } from '../../SpaceList/SpaceForm/styled';
import { ModalBody, ModalHeader, PageContainer } from './styled';

type FormData = {
  name: string;
};

const MCQForm = React.forwardRef<
  HTMLDivElement,
  {
    handleClose: () => void;
    selectedMcq?:
      | SpaceByIdQuery['spaceById']['mcqs']['edges'][number]['node']
      | GlobalMcqsQuery['globalMcqs']['edges'][number]['node'];
    spaceId: Maybe<number>;
  }
>(({ handleClose, selectedMcq, spaceId }, ref) => {
  const { enqueueSnackbar } = useSnackbar();

  const [createMCQ] = useMutation(CreateMcqDocument, {
    update(cache, { data }) {
      if (data) {
        if (spaceId) {
          cache.updateQuery(
            { query: SpaceByIdDocument, variables: { id: spaceId } },
            (spaceData) =>
              spaceData
                ? {
                    spaceById: {
                      ...spaceData.spaceById,
                      mcqs: {
                        ...spaceData.spaceById.mcqs,
                        totalCount: spaceData.spaceById.mcqs.totalCount + 1,
                        edges: [
                          ...spaceData.spaceById.mcqs.edges,
                          { node: data.createMCQ },
                        ],
                      },
                    },
                  }
                : undefined,
          );
        } else {
          cache.updateQuery({ query: GlobalMcqsDocument }, (mcqsData) => ({
            globalMcqs: mcqsData
              ? {
                  ...mcqsData.globalMcqs,
                  totalCount: mcqsData.globalMcqs.totalCount + 1,
                  edges: [
                    ...mcqsData.globalMcqs.edges,
                    { node: data.createMCQ },
                  ],
                }
              : {
                  pageInfo: {
                    endCursor: null,
                    hasNextPage: false,
                  },
                  totalCount: 1,
                  edges: [{ node: data.createMCQ }],
                },
          }));
        }
      }
    },
  });
  const [updateMcqMutation] = useMutation(UpdateMcqDocument);

  const { t } = useTranslation();
  let navigate = useNavigate();

  const { register, handleSubmit, reset, formState } = useForm<FormData>({
    defaultValues: {
      name: selectedMcq?.name || '',
    },
  });

  const onSubmit = handleSubmit(async (data) => {
    try {
      if (data.name) {
        if (selectedMcq) {
          await updateMcqMutation({
            variables: {
              id: selectedMcq.id,
              name: data.name,
            },
          });
          reset();
          handleClose();
        } else {
          const createdMcq = await createMCQ({
            variables: { name: data.name, spaceId: spaceId },
          });
          reset();
          handleClose();
          navigate(
            spaceId
              ? `/admin/${spaceId}/mcqs/${createdMcq.data?.createMCQ.id}`
              : `/dashboard/mcqs/${createdMcq.data?.createMCQ.id}`,
          );
        }
      }
    } catch (error: any) {
      enqueueSnackbar(error.message, snackbarErrorConfig);
    }
  });

  const { ref: nameRef, ...nameRest } = register('name', {
    required: 'You must set a name',
  });

  return (
    <PageContainer ref={ref}>
      <form onSubmit={onSubmit}>
        <ModalHeader>
          <Typography variant="h4">
            {selectedMcq
              ? t('label.updateMcq', 'Update MCQ')
              : t('label.createMcq', 'Create MCQ')}
          </Typography>
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </ModalHeader>
        <ModalBody>
          <CustomFormLabel label={t('label.name', 'Name')} />
          <TextField
            required
            id="name"
            placeholder={t(
              'textfield.placeholder.name',
              'e.g. Christmas songs',
            )}
            error={!!formState.errors.name}
            fullWidth
            {...nameRest}
            inputRef={nameRef}
          />
        </ModalBody>
        <ModalFooter>
          <GenericButton
            onClick={handleClose}
            disabled={formState.isSubmitting}
            label={t('button.label.cancel', 'Cancel')}
            variant="text"
          />
          <GenericButton
            type="submit"
            disabled={!!formState.errors.name || formState.isSubmitting}
            label={
              selectedMcq
                ? t('button.label.save', 'Save')
                : t('button.label.create', 'Create')
            }
          />
        </ModalFooter>
      </form>
    </PageContainer>
  );
});

export default MCQForm;
