import { useMutation } from '@apollo/client';
import { TextField } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../../context/auth';
import { LoginDocument } from '../../../graphql/types';
import { useAuthRedirect } from '../../../hooks/useAuthRedirect';
import { snackbarErrorConfig } from '../../../utils/Snackbars/snackbar.configs';
import CustomFormLabel from '../../CustomFormLabel';
import GenericButton from '../../GenericButton';
import {
  ButtonContainer,
  CustomForm,
  InputContainer,
  InputsContainer,
} from './styled';

type FormData = {
  email: string;
  password: string;
};

const AdminLogin: React.FC = () => {
  const authContext = useContext(AuthContext);
  const { enqueueSnackbar } = useSnackbar();
  const [login] = useMutation(LoginDocument);
  const { t } = useTranslation();
  const { register, handleSubmit, formState } = useForm<FormData>();

  useAuthRedirect();

  const onSubmit = handleSubmit(async (data) => {
    if (data.email && data.password) {
      login({ variables: data })
        .then((response) => {
          if (response.data) {
            authContext.login(response.data.login.accessToken);
          }
        })
        .catch((err) => {
          enqueueSnackbar(err.message, snackbarErrorConfig);
        });
    }
  });

  const { ref: emailRef, ...emailRest } = register('email');
  const { ref: passwordRef, ...passwordRest } = register('password');

  return (
    <CustomForm onSubmit={onSubmit}>
      <InputsContainer>
        <InputContainer>
          <CustomFormLabel label={t('input.label.email', 'Email')} />
          <TextField
            variant="outlined"
            required
            fullWidth
            id="email"
            placeholder={t(
              'input.placeholder.email',
              'e.g: tracktl@tracktl.com',
            )}
            autoComplete="email"
            {...emailRest}
            inputRef={emailRef}
          />
        </InputContainer>
        <InputContainer>
          <CustomFormLabel label={t('input.label.password', 'Password')} />
          <TextField
            variant="outlined"
            required
            fullWidth
            placeholder={t('input.placeholder.password', 'Password')}
            type="password"
            id="password"
            autoComplete="current-password"
            {...passwordRest}
            inputRef={passwordRef}
          />
        </InputContainer>
      </InputsContainer>
      <ButtonContainer>
        <GenericButton
          type="submit"
          disabled={formState.isSubmitting}
          label={t('button.label.login', 'Login')}
        />
      </ButtonContainer>
    </CustomForm>
  );
};

export default AdminLogin;
