import { Box } from '@mui/material';
import React from 'react';
import { Waypoint } from 'react-waypoint';
import { GameRankingQuery } from '../../../graphql/types';
import ParticipantRankItem, { RankContext } from '../ParticipantRankItem';

const Ranking: React.FC<{
  ranking: GameRankingQuery['gameById']['ranking']['edges'];
  loadingMore: boolean;
  loadMore: () => void;
  size: 'default' | 'large';
  gameContext: RankContext;
}> = ({ ranking, loadingMore, loadMore, size, gameContext }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '1rem',
        overflow: 'auto',
        gap: '10px',
      }}
    >
      {ranking.map((entry) => (
        <ParticipantRankItem
          key={entry.node.id}
          entry={entry.node}
          size={size}
          gameContext={gameContext}
        />
      ))}
      {!loadingMore && <Waypoint onEnter={loadMore} bottomOffset={-100} />}
    </Box>
  );
};

export default Ranking;
