import { Box } from '@mui/material';
import React, { ReactNode } from 'react';
import background from '../../images/backgrounds/stars.png';

const RoutesLayout = React.forwardRef<HTMLDivElement, { children?: ReactNode }>(
  (props, ref) => {
    return (
      <Box
        ref={ref}
        sx={{
          display: 'flex',
          height: '100%',
          backgroundImage: `url(${background})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        {props.children}
      </Box>
    );
  },
);

export default RoutesLayout;
