import { useTranslation } from 'react-i18next';
import { Box, SvgIconProps, Typography } from '@mui/material';
import { GameOptions } from '../../../../graphql/types';
import React, { useMemo } from 'react';
import SpeedIcon from '@mui/icons-material/Speed';
import BoltIcon from '@mui/icons-material/Bolt';
import CheckIcon from '@mui/icons-material/Check';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import LockIcon from '@mui/icons-material/Lock';

type Rule = [React.ReactElement<SvgIconProps>, string];

const RuleItem = ({ rule }: { rule: Rule }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '80px',
      }}
    >
      <Box
        sx={{
          height: '40px',
          width: '40px',
          background: 'linear-gradient(180deg, #DE9528 0%, #A65408 100%)',
          boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
          borderRadius: '50%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {rule[0]}
      </Box>
      <Typography
        variant="body1"
        sx={{ fontSize: '0.75rem', textAlign: 'center' }}
      >
        {rule[1]}
      </Typography>
    </Box>
  );
};

const RulesSet: React.FC<{ gameOptions: GameOptions }> = ({ gameOptions }) => {
  const { t } = useTranslation();

  const rules: { [rule: string]: Rule } = useMemo(() => {
    return {
      speedBonus: [<SpeedIcon />, t('label.speedBonus')],
      quickResponse: [<BoltIcon />, t('label.quickResponse')],
      slowResponse: [
        <CheckIcon />,
        t('label.slowResponse', 'Answer validation'),
      ],
      hasWrongAnswerPenalty: [
        <ErrorOutlineIcon />,
        t('label.hasWrongAnswerPenalty'),
      ],
      hasOneTryPerQuestion: [<LockIcon />, t('label.hasOneTryPerQuestion')],
    };
  }, [t]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
      <Typography
        variant="body1"
        sx={{ textAlign: 'center', fontWeight: '700', fontSize: '0.875rem' }}
      >
        {t('label.rules', 'You will play with the following rules:')}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexWrap: 'wrap',
          gap: 'inherit',
        }}
      >
        {
          <RuleItem
            rule={
              gameOptions.quickResponse
                ? rules.quickResponse
                : rules.slowResponse
            }
          />
        }
        {gameOptions.withSpeedBonus && <RuleItem rule={rules.speedBonus} />}
        {gameOptions.hasWrongAnswerPenalty && (
          <RuleItem rule={rules.hasWrongAnswerPenalty} />
        )}
        {gameOptions.hasOneTryPerQuestion && (
          <RuleItem rule={rules.hasOneTryPerQuestion} />
        )}
      </Box>
    </Box>
  );
};

export default RulesSet;
