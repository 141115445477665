import { css } from '@emotion/css';
import { Theme } from '@emotion/react';

// We're using the emotion css tool because of this issue (https://github.com/mui-org/material-ui/issues/14971)
export const CustomListItemStyle = (theme: Theme, active: boolean) => {
  return css`
    cursor: pointer;
    color: ${active ? theme.colors.lightBlue : theme.colors.white};
    font-weight: ${active ? 500 : 400};
    text-decoration: none;
    justify-content: center;
    :hover {
      background: ${theme.colors.darkListItemHover};
    }
  `;
};
