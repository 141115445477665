import { Box, Typography } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { MedalContainer } from './styled';

const RankingItem: React.FC<{ rank: number; participantCount: number }> = ({
  rank,
  participantCount,
}) => {
  const { t } = useTranslation();
  const rankThresholds = useMemo(
    () => [
      1,
      2,
      3,
      participantCount / 10, // top 90%
      participantCount * 0.3, // top 70%
      participantCount, // rest
    ],
    [participantCount],
  );

  const rankingMessage: Array<string> = useMemo(
    () => [
      t('label.ranking.first', 'Congratulations! You ranked first!'),
      t('label.ranking.second', 'Amazing! You ranked second!'),
      t('label.ranking.third', 'Way to go! You ranked third!'),
      t('label.ranking.top90', {
        defaultValue: 'You are in the top 90%, at rank {{rank}}!',
        rank: rank,
      }),
      t('label.ranking.top70', {
        defaultValue: 'You are in the top 70%, at rank {{rank}}!',
        rank: rank,
      }),
      t('label.ranking.rest', {
        defaultValue: 'You ranked {{rank}}... Better luck next time!',
        rank: rank,
      }),
    ],
    [t, rank],
  );

  const playerThresholdIndex = useMemo(() => {
    return rankThresholds.findIndex((threshold) => rank <= threshold);
  }, [rank, rankThresholds]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '15px',
        alignItems: 'center',
      }}
    >
      {playerThresholdIndex >= 0 && (
        <>
          <MedalContainer rank={rank} />
          <Typography variant="h5">
            {rankingMessage[playerThresholdIndex]}
          </Typography>
        </>
      )}
    </Box>
  );
};

export default RankingItem;
