import { Box, CircularProgress } from '@mui/material';
import React from 'react';

const FullscreenLoader = React.forwardRef<HTMLDivElement>((props, ref) => {
  return (
    <Box
      ref={ref}
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
      }}
    >
      <CircularProgress />
    </Box>
  );
});

export default FullscreenLoader;
