import { useContext } from 'react';
import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom';
import AdminLogin from '../components/AdminLogin/Login';
import DashboardDrawer from '../components/DashboardDrawer';
import GlobalMcqList from '../components/GlobalMcqList';
import NoMatch from '../components/NoMatch';
import QuestionManager from '../components/QuestionManager';
import RoutesLayout from '../components/RoutesLayout';
import SpaceList from '../components/SpaceList';
import { AuthContext } from '../context/auth';

const DashboardRoutes = () => {
  return (
    <Routes>
      <Route index element={<AdminLogin />} />
      <Route
        path="/"
        element={
          <RequireAuth>
            <RoutesLayout>
              <DashboardDrawer />
              <Outlet />
            </RoutesLayout>
          </RequireAuth>
        }
      >
        <Route path="spaces" element={<SpaceList />} />
        <Route path="mcqs" element={<GlobalMcqList />} />
        <Route path="mcqs/:mcqId" element={<QuestionManager />} />
      </Route>
      <Route path="*" element={<NoMatch />} />
    </Routes>
  );
};

const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const { user } = useContext(AuthContext);
  let location = useLocation();

  if (!user || !user.isSuperAdmin) {
    return <Navigate to="/admin" state={{ from: location }} />;
  }

  return children;
};

export default DashboardRoutes;
