import styled from '@emotion/styled';

export const QuestionListItemContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isSelected',
})<{
  isSelected: boolean;
}>`
  height: 100%;
  @media (min-height: 850px) {
    min-height: 11.2rem;
  }
  min-height: 8.2rem;
  min-width: 18rem;
  max-width: 18rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  text-align: center;
  @media (min-height: 850px) {
    padding: 0.75rem 1.5rem;
  }
  padding: 1rem;
  &:not(:last-child) {
    margin-right: 1rem;
  }
  background-color: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  position: relative;
  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 0.625rem;
    border-width: ${({ isSelected }) => (isSelected ? '2px' : '1px')};
    border-style: solid;
    border-color: ${({ theme, isSelected }) =>
      isSelected ? theme.colors.lightBlue : theme.colors.grey};
  }
`;

export const ChoiceRightValue = styled.p`
  font-weight: bold;
  font-size: ${({ theme }) => theme.fontSize.normal};
  color: ${({ theme }) => theme.colors.activeColor};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 15rem;
`;
