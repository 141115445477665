import { useQuery } from '@apollo/client';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Waypoint } from 'react-waypoint';
import { TurnAnswersDocument } from '../../../../graphql/types';
import ParticipantAnswer from './ParticipantAnswer';

const TurnSummary: React.FC<{
  turnId: number;
  withScore?: boolean;
}> = ({ turnId, withScore }) => {
  const { t } = useTranslation();

  const { data, fetchMore, loading } = useQuery(TurnAnswersDocument, {
    variables: { id: turnId },
    notifyOnNetworkStatusChange: true,
  });

  const answers = data?.turnById.answers;

  if (!answers) {
    return (
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          height: '50px',
          overflow: 'hidden',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (answers.totalCount === 0) {
    return (
      <Typography variant="body1">
        {t(
          'label.noParticipantAnswer',
          'No participant has answered this question.',
        )}
      </Typography>
    );
  }

  const loadingMore = loading;
  const loadMore = () => {
    if (answers.pageInfo.hasNextPage && answers.pageInfo.endCursor) {
      fetchMore({
        variables: {
          cursor: answers.pageInfo.endCursor,
        },
      });
    }
  };

  return (
    <Box width="100%">
      {answers?.edges.map((answer, index) => (
        <ParticipantAnswer
          key={answer.node.id}
          answer={answer.node}
          withScore={withScore}
          rank={index + 1}
        />
      ))}
      {!loadingMore && <Waypoint onEnter={loadMore} bottomOffset={-100} />}
    </Box>
  );
};

export default TurnSummary;
