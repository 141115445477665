import styled from '@emotion/styled';
import { Box } from '@mui/material';

export type CountBarStates = 'empty' | 'filling' | 'full';

export const CountBar = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'variant',
})<{
  variant: CountBarStates;
}>`
  position: relative;
  width: 80%;
  max-width: 800px;
  min-width: 300px;
  height: 50px;
  background-color: ${({ theme, variant }) => {
    switch (variant) {
      case 'filling':
        return `${theme.colors.activeColor}1A`;
      case 'full':
        return `${theme.colors.successGreen}CC`;
      case 'empty':
      default:
        return `${theme.colors.grey}1A`;
    }
  }};
  border: 1px solid
    ${({ theme, variant }) => {
      switch (variant) {
        case 'filling':
          return theme.colors.activeColor;
        case 'full':
          return theme.colors.successGreen;
        case 'empty':
        default:
          return theme.colors.grey;
      }
    }};
  border-radius: 10px;
  display: flex;
  align-items: center;
`;

export const CountGauge = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'filling',
})<{
  filling: number;
}>`
  height: 100%;
  width: ${({ filling }) => {
    return `${filling}%`;
  }};
  border-radius: ${({ filling }) => {
    if (filling < 100) {
      return '10px 0px 0px 10px';
    }
    return '10px';
  }};
  background-color: rgba(255, 141, 0, 0.5);
`;

export const CountBarText = styled.p`
  position: absolute;
  padding-left: 15px;
`;

export const CountNumber = styled.span`
  font-weight: 600;
  font-size: 20px;
`;
