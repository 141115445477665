import { useApolloClient } from '@apollo/client';
import jwtDecode from 'jwt-decode';
import { createContext, useCallback, useMemo, useReducer } from 'react';

type DecodedToken = {
  userId: number;
  name: string;
  spaceId: number;
  isAdmin: boolean;
  isSuperAdmin?: boolean;
  iat: number;
  exp: number;
};

export type AuthenticatedUser = Pick<
  DecodedToken,
  'userId' | 'name' | 'spaceId' | 'isAdmin' | 'isSuperAdmin'
>;

export type PlayerVolumeSettings = {
  volume: number;
  muted: boolean;
};

const token = localStorage.getItem('accessToken');

const initialState: {
  user: AuthenticatedUser | null;
  playerSettings: PlayerVolumeSettings | null;
} = {
  user: null,
  playerSettings: null,
};

if (token) {
  const decodedToken: DecodedToken = jwtDecode(token);

  // Expiration timestamp is in seconds and not in milliseconds
  if (new Date(decodedToken.exp * 1000) < new Date()) {
    localStorage.removeItem('accessToken');
  } else {
    const localPlayerSettings = localStorage.getItem(
      'tracktl-quiz-player-settings',
    );
    initialState.user = {
      userId: decodedToken.userId,
      name: decodedToken.name,
      spaceId: decodedToken.spaceId,
      isAdmin: decodedToken.isAdmin,
      isSuperAdmin: decodedToken.isSuperAdmin ?? undefined,
    };
    initialState.playerSettings = localPlayerSettings
      ? JSON.parse(localPlayerSettings)
      : {
          volume: 70,
          muted: false,
        };
  }
}

const AuthContext = createContext<{
  user: AuthenticatedUser | null;
  playerSettings: PlayerVolumeSettings;
  login: (userData: string) => void;
  setPlayerSettings: (settings: PlayerVolumeSettings) => void;
  logout: () => void;
}>({
  user: null,
  playerSettings: { volume: 70, muted: false },
  login: (userData: string) => {},
  setPlayerSettings: (settings: PlayerVolumeSettings) => {},
  logout: () => {},
});

const authReducer = (
  state: any,
  action: {
    type: string;
    payload: {
      user?: AuthenticatedUser;
      playerSettings?: PlayerVolumeSettings;
    } | null;
  },
) => {
  switch (action.type) {
    case 'LOGIN':
      return {
        ...state,
        user: action.payload!.user,
      };
    case 'LOGOUT':
      return {
        ...state,
        user: null,
      };
    case 'SET_PLAYER_SETTINGS':
      return {
        ...state,
        player: action.payload!.playerSettings,
      };
    default:
      return state;
  }
};

const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(authReducer, initialState);
  const client = useApolloClient();

  const login = useCallback((userData: string) => {
    localStorage.setItem('accessToken', userData);
    localStorage.setItem(
      'tracktl-quiz-player-settings',
      JSON.stringify({
        volume: 70,
        muted: false,
      }),
    );
    const decodedToken: DecodedToken = jwtDecode(userData);
    dispatch({
      type: 'LOGIN',
      payload: {
        user: {
          userId: decodedToken.userId,
          name: decodedToken.name,
          spaceId: decodedToken.spaceId,
          isAdmin: decodedToken.isAdmin,
          isSuperAdmin: decodedToken.isSuperAdmin,
        },
      },
    });

    dispatch({
      type: 'SET_PLAYER_SETTINGS',
      payload: {
        playerSettings: {
          volume: 70,
          muted: false,
        },
      },
    });
  }, []);

  const setPlayerSettings = useCallback((settings: PlayerVolumeSettings) => {
    localStorage.setItem(
      'tracktl-quiz-player-settings',
      JSON.stringify(settings),
    );
    dispatch({
      type: 'SET_PLAYER_SETTINGS',
      payload: {
        playerSettings: settings,
      },
    });
  }, []);

  const logout = useCallback(() => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('tracktl-quiz-player-settings');
    client.clearStore();
    dispatch({ type: 'LOGOUT', payload: null });
  }, [client]);

  const value = useMemo(() => {
    return {
      user: state.user,
      playerSettings: state.playerSettings,
      login,
      setPlayerSettings,
      logout,
    };
  }, [state.user, state.playerSettings, login, setPlayerSettings, logout]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export { AuthProvider, AuthContext };
