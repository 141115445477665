import { Box } from '@mui/material';

const QuestionPictureContainer: React.FC<{
  picture: string;
  maxHeight?: number;
}> = ({ picture, maxHeight = 500 }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        width: '100%',
        maxHeight: `${maxHeight}px`,
        aspectRatio: '2.1',
        borderRadius: '10px',
        border: '1px solid #7F8FA4',
        boxSizing: 'border-box',
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          backgroundImage: `url(${picture})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          filter: 'blur(20px)',
          position: 'absolute',
          top: '0',
          left: '0',
          width: '100%',
          height: '100%',
        }}
      ></Box>
      <Box
        sx={{
          display: 'flex',
          backgroundImage: `url(${picture})`,
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          height: '100%',
          width: '100%',
          zIndex: '2',
        }}
      />
    </Box>
  );
};

export default QuestionPictureContainer;
