const postMedia = (file: File): Promise<[number, { url: string }]> => {
  const formData = new FormData();
  const destination = file.type.includes('audio') ? 'audio' : 'images';
  formData.append(destination, file);
  return fetch(`https://quiz-upload.tracktl.in/${destination}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
    },
    body: formData,
  }).then((response) => {
    const statusCode = response.status;
    const responseJSON = response.json();
    return Promise.all([statusCode, responseJSON]);
  });
};

export default postMedia;
