import styled from '@emotion/styled';
import Drawer from '@mui/material/Drawer';

const drawerWidth = ({ open }: { open: boolean }) => {
  return open ? '17.5rem' : '100px';
};

export const CustomDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})<{ open: boolean }>`
  width: ${drawerWidth};

  ${({ open }) =>
    open
      ? ''
      : `
    & .MuiListItemText-root, & .MuiTypography-root {
      display: none;
    }
  `}

  & .MuiDrawer-paper {
    width: ${drawerWidth};
    background: ${({ theme }) =>
      `linear-gradient(${theme.colors.midnightBlue}, ${theme.colors.darkBlue})`};
    color: ${({ theme }) => theme.colors.white};
    display: flex;
    justify-content: space-between;
  }
`;

export const CollapseContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'open',
})<{ open: boolean }>`
  display: flex;
  padding-top: 20px;
  padding-right: ${({ open }) => (open ? '20px' : '0')};
  justify-content: ${({ open }) => (open ? 'flex-end' : 'center')};
`;
