import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import DownloadIcon from '../../images/download.svg';
import {
  CustomUploadImg,
  DropZoneContainer,
  DropZoneInfoLabel,
  DropZoneLabel,
  FileName,
  LabelStatus,
  UploadIconContainer,
  UploadStatusContainer,
} from './styled';

type UploadState = 'idle' | 'ready' | 'error';

type ImageFileType = {
  type: 'image';
  width?: number;
  height?: number;
  size?: number;
};
type AudioFileType = {
  type: 'audio';
  size?: number;
  width?: never;
  height?: never;
};

type AcceptedTypes = [ImageFileType?, AudioFileType?];

const DropZone: React.FC<{
  onFileDropped: (file: File) => void;
  acceptedTypes: AcceptedTypes;
}> = ({ onFileDropped, acceptedTypes }) => {
  const { t } = useTranslation();
  const [selectedFile, setSelectedFile] = useState<File>();
  const [uploadState, setUploadState] = useState<UploadState>('idle');

  const [audioValidation] = useState(
    acceptedTypes.find((at) => at?.type === 'audio') || null,
  );
  const [imageValidation] = useState(
    acceptedTypes.find((at) => at?.type === 'image') || null,
  );

  const [error, setError] = useState('');

  const checkFile = useCallback(
    (file: File) => {
      const fileExtension = file.name.slice(file.name.lastIndexOf('.'));
      const fileType = file.type;
      if (!fileType.includes('audio') && !fileType.includes('image')) {
        setError('File type not supported');
        return false;
      }
      if (fileType.includes('audio')) {
        if (
          !audioValidation ||
          (fileExtension !== '.mp3' &&
            fileExtension !== '.mp4' &&
            fileExtension !== '.m4a')
        ) {
          setError(
            t(
              'error.label.wrongAudioFileFormat',
              'Only mp3, mp4 & m4a files are allowed',
            ),
          );
          return false;
        }
      }
      if (fileType.includes('image')) {
        if (
          !imageValidation ||
          (fileExtension !== '.png' &&
            fileExtension !== '.jpg' &&
            fileExtension !== '.jpeg')
        ) {
          setError(
            t(
              'error.label.wrongImageFileFormat',
              'Only png & jpg files are allowed',
            ),
          );
          return false;
        }
      }

      if (file.size > 20000000) {
        setError(
          t('error.label.fileTooLarge', {
            defaultValue: 'Your file is too large {{size}}Mo/20Mo',
            size: Math.round((file.size / 1000000) * 10) / 10,
          }),
        );
        return false;
      }

      return true;
    },
    [t, audioValidation, imageValidation],
  );

  const onDrop = (acceptedFiles: Array<File>) => {
    if (acceptedFiles.length === 0) {
      setUploadState('idle');
      return;
    }

    setSelectedFile(acceptedFiles[0]);
    if (!checkFile(acceptedFiles[0])) {
      setUploadState('error');
      return;
    }

    setUploadState('ready');
    onFileDropped(acceptedFiles[0]);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <>
      {uploadState === 'idle' ? (
        <DropZoneContainer {...getRootProps()} isDragActive={isDragActive}>
          <input {...getInputProps()} />
          <UploadIconContainer>
            <CustomUploadImg alt="upload" src={DownloadIcon} />
          </UploadIconContainer>
          <Box
            display="flex"
            flex="1"
            flexDirection="column"
            alignItems="center"
          >
            <DropZoneLabel>
              {audioValidation &&
                !imageValidation &&
                t('label.dropSong', 'Drop your musical extract here.')}
              {imageValidation &&
                !audioValidation &&
                t('label.dropImage', 'Drop your image here.')}
              {audioValidation &&
                imageValidation &&
                t('label.dropMedia', 'Drop your audio or picture file here.')}
            </DropZoneLabel>
            <Box>
              {audioValidation && (
                <DropZoneInfoLabel>
                  {t(
                    'label.dropSongInfo',
                    'Audio file: .mp3, mp4 or m4a. Must weigh less than 20MB.',
                  )}
                </DropZoneInfoLabel>
              )}
              {imageValidation && (
                <DropZoneInfoLabel>
                  {t(
                    'label.dropImageInfo',
                    'Image file: .png or .jpg. Must weigh less than than 20MB.',
                  )}
                  {(imageValidation?.width || imageValidation?.height) &&
                    t('label.dropImageDimensions', {
                      defaultValue:
                        ' Recommended dimensions are {{width}}x{{height}} pixels',
                      width: imageValidation?.width,
                      height: imageValidation?.height,
                    })}
                </DropZoneInfoLabel>
              )}
            </Box>
          </Box>
        </DropZoneContainer>
      ) : (
        <UploadStatusContainer status={uploadState}>
          <div>
            <FileName>{selectedFile?.name}</FileName>
            <LabelStatus status={uploadState}>
              {uploadState === 'ready' &&
                t(
                  'dropzone.label.ready',
                  'Your file will be uploaded on save.',
                )}
              {uploadState === 'error' && error}
            </LabelStatus>
          </div>
          <div>
            {(uploadState === 'ready' || uploadState === 'error') && (
              <IconButton
                aria-label="cancel"
                onClick={() => onDrop([])}
                size="large"
              >
                <CloseIcon />
              </IconButton>
            )}
          </div>
        </UploadStatusContainer>
      )}
    </>
  );
};

export default DropZone;
