import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import GenericButton from '../GenericButton';
import { ModalBody, ModalFooter, ModalHeader, PageContainer } from './styled';

export interface ModalOptions {
  label: string;
  warningMessage?: string;
  confirmText?: string;
  cancelText?: string;
  context?: 'primary' | 'secondary' | 'success' | 'danger';
  hoverColor?: string;
  onConfirm: () => void;
  handleClose: () => void;
}

const ModalConfirmation = React.forwardRef<HTMLDivElement, ModalOptions>(
  (
    {
      label,
      warningMessage,
      confirmText,
      cancelText,
      context,
      onConfirm,
      handleClose,
    },
    ref,
  ) => {
    const { t } = useTranslation();

    return (
      <PageContainer ref={ref}>
        <ModalHeader>
          <Typography variant="h4">{label}</Typography>
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </ModalHeader>
        <ModalBody>
          {warningMessage && (
            <Typography variant="body1">{warningMessage}</Typography>
          )}
        </ModalBody>
        <ModalFooter>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="end"
            justifyContent="flex-end"
            marginTop="40px"
          >
            <GenericButton
              variant="text"
              onClick={handleClose}
              name="cancel"
              label={cancelText ?? t('button.label.cancel', 'Cancel')}
            />
            <GenericButton
              context={context}
              variant="contained"
              onClick={onConfirm}
              name="confirm"
              label={confirmText ?? t('button.label.yes', 'Yes')}
            />
          </Box>
        </ModalFooter>
      </PageContainer>
    );
  },
);

export default ModalConfirmation;
