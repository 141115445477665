import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { BasicParticipationFragment } from '../../../graphql/types';
import GoldCrown from '../../../images/gold_crown.svg';
import SilverMedal from '../../../images/silver_medal.svg';
import BronzeMedal from '../../../images/bronze_medal.svg';
import RankUp from '../../../images/rank_change_up_small.svg';
import RankDown from '../../../images/rank_change_down_small.svg';
import RankSame from '../../../images/rank_unchanged_small.svg';
import {
  RankImage,
  ParticipantContainer,
  ParticipantName,
  ParticipantRank,
  ParticipantScore,
  RankChangeImage,
} from './styled';
import { Box } from '@mui/material';
import SpeedIcon from '@mui/icons-material/Speed';
import AnswerSpeed from '../../AnswerSpeed';

export type RankContext = 'turnPlay' | 'turnEnd' | 'gameEnd';

const ParticipantRankItem: React.FC<{
  entry: BasicParticipationFragment;
  size: 'default' | 'large';
  gameContext: RankContext;
}> = ({ entry, size, gameContext: display }) => {
  const { t } = useTranslation();

  const RankDisplay = useMemo(() => {
    switch (entry.rank) {
      case 0:
        return (
          <ParticipantRank rank={4} size={size}>
            --
          </ParticipantRank>
        );
      case 1:
        return (
          <RankImage
            alt="crown"
            src={GoldCrown}
            rank={entry.rank}
            size={size}
          />
        );
      case 2:
        return (
          <RankImage
            alt="silver_medal"
            src={SilverMedal}
            rank={entry.rank}
            size={size}
          />
        );
      case 3:
        return (
          <RankImage
            alt="bronze_medal"
            src={BronzeMedal}
            rank={entry.rank}
            size={size}
          />
        );
      default:
        return (
          <ParticipantRank rank={entry.rank} size={size}>
            {entry.rank}
          </ParticipantRank>
        );
    }
  }, [entry.rank, size]);

  const RankChange = useMemo(() => {
    const rankChange = entry.previousRank - entry.rank;

    if (rankChange < 0) {
      return <RankChangeImage alt="rank_down" src={RankDown} size={size} />;
    } else if (rankChange > 0) {
      return <RankChangeImage alt="rank_up" src={RankUp} size={size} />;
    } else {
      return <RankChangeImage alt="rank_same" src={RankSame} size={size} />;
    }
  }, [entry.rank, entry.previousRank, size]);

  return (
    <ParticipantContainer rank={entry.rank} size={size}>
      <Box
        sx={{
          width: size === 'large' ? '65px' : '50px',
          height: size === 'large' ? '60px' : '50px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {RankDisplay}
      </Box>
      {display === 'turnEnd' && entry.previousRank !== 0 && RankChange}
      <ParticipantName rank={entry.rank} size={size}>
        {entry.player.name}
      </ParticipantName>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end',
        }}
      >
        <ParticipantScore rank={entry.rank} size={size}>
          {t('label.plural.pts', {
            defaultValue: '{{score}} pts',
            score: entry.score,
          })}
        </ParticipantScore>
        {display === 'gameEnd' && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: '5px',
            }}
          >
            <SpeedIcon sx={{ fontSize: size }} />
            <AnswerSpeed
              speed={entry.averageSpeed!}
              size={size === 'default' ? 'small' : 'medium'}
            />
          </Box>
        )}
      </Box>
    </ParticipantContainer>
  );
};

export default ParticipantRankItem;
