import { createTheme } from '@mui/material/styles';

// A custom theme for this app
export const emotionTheme = {
  colors: {
    white: '#FFFFFF',
    black: '#000000',
    transparentBlack: '#000000AA',
    darkBlue: '#263152',
    midnightBlue: '#141A2B',
    dark: '#141A2B',
    lightGrey: '#FBFBFB',
    lightSoftGrey: '#F5F5F5',
    softGrey: '#EFEFEF',
    grey: '#DEDEDE',
    mediumGrey: '#C7C7C7',
    deepGrey: '#8F8F8F',
    darkGrey: '#373737',
    lightBlue: '#00AAFF',
    lightBlueHover: '#0099E6',
    lightBluePressed: '#0088CC',
    transparentLightBlue: '#00AAFFAA',
    tracktlPurple: '#8D6EFF',
    successGreen: '#2ADB9A',
    dangerRed: '#F1425F',
    dangerRedHover: '#E33E59',
    dangerRedPressed: '#D93B55',
    secondaryButtonHover: '#FAFAFA',
    secondaryButtonPressed: '#F5F5F5',
    activeColor: '#FF8D00',
    gold: '#F3C03C',
    silver: '#BFBFBF',
    bronze: '#CD7F32',
    disabledGrey: '#C7C7C7',
    disabledDarkGrey: '#525252',
    darkListItemHover: '#EFEFEF1A', // 10% opaacity
    darkListItemPress: '#EFEFEF33', // 20% opaacity
    trackTlOrange: '#FFB319',
  },
  fontSize: {
    xxxlarge: '64px',
    50: '50px',
    48: '48px',
    xxlarge: '36px',
    xlarge: '30px',
    large: '24px',
    20: '20px',
    18: '18px',
    normal: '16px',
    small: '14px',
    xsmall: '13px',
    xxsmall: '12px',
  },
  fontWeight: {
    extraLight: 100,
    thin: 200,
    light: 300,
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
    extraBold: 800,
    black: 900,
  },
};

export const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#00AAFF',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#8D6EFF',
    },
    success: {
      main: '#2ADB9A',
    },
    warning: {
      main: '#FF8E53',
    },
    error: {
      main: '#F1425F',
    },
    text: {
      primary: '#FFFFFF',
      secondary: '#7F8FA4',
      disabled: '#C7C7C7',
    },
    background: {
      default: '#141A2B',
    },
  },
  typography: {
    fontFamily: 'Poppins',
    button: {
      fontWeight: 700,
      fontSize: '0.875rem',
      borderRadius: 50,
    },
    h1: {
      fontWeight: 300,
    },
    h2: {
      fontSize: '2.25rem',
      fontWeight: 700,
    },
    h4: {
      fontSize: '1.875rem',
      fontWeight: 700,
    },
    h5: {
      fontSize: '1.5rem',
      fontWeight: 700,
    },
    subtitle1: {
      fontSize: '0.5rem',
      fontWeight: 400,
      color: '#7F8FA4',
    },
    subtitle2: {
      fontSize: '1rem',
      color: '#7F8FA4',
    },
    body2: {
      color: '#EFEFEF',
    },
  },
  shape: {
    borderRadius: 10,
  },
  components: {
    MuiAlert: {
      styleOverrides: {
        filledInfo: {
          backgroundColor: 'rgba(0, 170, 255, 0.3)',
        },
        filledError: {
          backgroundColor: 'rgba(241, 66, 95, 0.3)',
        },
        filledSuccess: {
          backgroundColor: 'rgba(42, 216, 154, 0.3)',
        },
        filledWarning: {
          backgroundColor: 'rgba(255, 142, 83, 0.3)',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          backgroundColor: '#141A2B',
        },
      },
    },
  },
});
