import styled from '@emotion/styled';
import { Slider } from '@mui/material';

export const VolumeContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-left: 1px solid ${({ theme }) => theme.colors.grey};
  margin-left: 0.75rem;
  margin-right: 0.75rem;
`;

export const VolumeSlider = styled(Slider)`
  min-width: 5rem;
  max-width: 5rem;
  display: flex;
  align-items: center;
  text-align: center;
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  color: ${({ theme }) => theme.colors.lightBlue};
  & .MuiSlider-root {
    height: 0.5rem;
  }
  & .MuiSlider-thumb {
    :after {
      left: 0px;
      top: 0px;
      right: 0px;
      bottom: 0px;
    }
    height: 1.2rem;
    width: 1.2rem;
    margin-top: -1px;
    margin-left: -5px;
    border: 2px solid ${({ theme }) => theme.colors.white};
    box-shadow: 0 0 5px 1px #00000033;
  }
  & .MuiSlider-track {
    height: 0.5rem;
  }
  & .MuiSlider-rail {
    height: 0.5rem;
    background-color: ${({ theme }) => theme.colors.grey};
  }
`;

export const TimeSlider = styled(Slider)`
  display: flex;
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  color: transparent;
  padding: 0;
  & .MuiSlider-root {
    height: 0.5rem;
  }
  & .MuiSlider-thumb {
    :after {
      left: 0px;
      top: 0px;
      right: 0px;
      bottom: 0px;
    }
    height: 1.2rem;
    width: 1.2rem;
    margin-top: -1px;
    margin-left: -5px;
    background-color: ${({ theme }) => theme.colors.tracktlPurple};
    border: 2px solid ${({ theme }) => theme.colors.white};
    box-shadow: 0 0 5px 1px #00000033;
  }
  & .MuiSlider-track {
    height: 0.5rem;
    ${({ theme }) =>
      `background: linear-gradient(to right, ${theme.colors.lightBlue}, ${theme.colors.tracktlPurple})`};
  }
  & .MuiSlider-rail {
    height: 0.5rem;
    background-color: ${({ theme }) => theme.colors.grey};
  }
`;
