import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SwapIcon from '@mui/icons-material/SwapHoriz';
import { Box, IconButton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ReadSpacesQuery } from '../../../graphql/types';
import TracktlLogo from '../../../images/logo_c_white.svg';
import GenericButton from '../../GenericButton';

type PartialSpace = ReadSpacesQuery['spaces']['edges'][number]['node'];

const SpaceListItem: React.FC<{
  space: PartialSpace;
  onNavigate: () => void;
  onEdit: () => void;
  onDelete: () => void;
}> = ({ space, onNavigate, onEdit, onDelete }) => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderRadius: '10px',
        padding: '20px',
        gap: '10px',
        ':hover': {
          backgroundColor: '#efefef1a',
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          height: '150px',
          width: '150px',
          backgroundImage: `url(${space.image ?? TracktlLogo})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          borderRadius: '10px',
          backgroundRepeat: 'no-repeat',
          backgroundOrigin: 'content-box',
        }}
      />
      <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
        <Typography variant="h5">{space.name}</Typography>
        <Typography variant="body1">{space.url}</Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {space.games.totalCount} {t('label.games', 'games')} -{' '}
          {space.players.totalCount} {t('label.players', 'players')}
        </Typography>
      </Box>
      <Box>
        <GenericButton
          onClick={onNavigate}
          label="Accéder"
          startIcon={<SwapIcon />}
          size="small"
        />
        <IconButton aria-label="edit" onClick={onEdit}>
          <EditIcon />
        </IconButton>
        <IconButton aria-label="delete" onClick={onDelete}>
          <DeleteIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default SpaceListItem;
