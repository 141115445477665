import GamesIcon from '@mui/icons-material/Games';
import { Box, Divider, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ClientGameFragment } from '../../../graphql/types';
import ClientLobbyImage from '../../../images/client_lobby.svg';
import RulesSet from './RulesSet';
import TipsMap from './TipsMap';

const ClientLobby: React.FC<{
  game: ClientGameFragment;
}> = ({ game }) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        padding: '1rem',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            minWidth: '200px',
            width: '20rem',
            maxWidth: '70%',
            maxHeight: '166px',
            aspectRatio: '1.5',
            backgroundImage: `url(${ClientLobbyImage})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        ></Box>
        <Box>
          <Typography variant="h5" sx={{ textAlign: 'center' }}>
            {game.name}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '10px',
              color: 'text.secondary',
            }}
          >
            <GamesIcon />
            {t('label.plural.questions', {
              defaultValue: '{{ questionCount }} questions',
              questionCount: game.turnCount,
            })}
          </Typography>
        </Box>
      </Box>
      <Typography variant="h5" sx={{ textAlign: 'center' }}>
        {t('label.welcome', 'Welcome!')}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
          padding: '10px',
          backgroundColor: '#FFFFFF1A',
          borderRadius: '20px',
        }}
      >
        <RulesSet gameOptions={game.options} />
        <Divider />
        <TipsMap withSpeedBonus={game.options.withSpeedBonus ?? false} />
      </Box>
      <Typography variant="subtitle2" sx={{ textAlign: 'center' }}>
        {t('label.gameStartingSoon', 'The game will start soon')}
      </Typography>
    </Box>
  );
};

export default ClientLobby;
