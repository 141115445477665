import { useQuery } from '@apollo/client';
import { Box, Modal, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../../context/auth';
import { ClientResultDocument } from '../../../graphql/types';
import { useGameId } from '../../../hooks/useGameId';
import { millisecondsToSeconds } from '../../AnswerSpeed';
import FullscreenLoader from '../../FullscreenLoader';
import GenericButton from '../../GenericButton';
import NoSpace from '../../NoSpace';
import RankingModal from '../RankingModal';
import RankingItem from './RankingItem';
import SpeedIcon from '@mui/icons-material/Speed';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';

const ClientResult: React.FC = () => {
  const { t } = useTranslation();
  const gameId = useGameId();
  const [isRankingModalOpen, setRankingModalOpen] = useState(false);
  const handleOpen = () => setRankingModalOpen(true);
  const handleClose = () => setRankingModalOpen(false);
  const authContext = useContext(AuthContext);

  const { data, loading, error } = useQuery(ClientResultDocument, {
    variables: { id: gameId },
  });

  const leaveGame = () => {
    authContext.logout();
  };

  const game = data?.gameById;

  if (loading) {
    return <FullscreenLoader />;
  }

  if (error || !game || !game.me) {
    return <NoSpace />;
  }

  const formattedSpeed = millisecondsToSeconds(game.me.averageSpeed!);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: '100%',
          padding: '90px 30px 50px',
          textAlign: 'center',
          overflow: 'auto',
        }}
      >
        <Typography variant="h2" sx={{ color: 'white' }}>
          {t('label.gameIsFinished', 'The game is finished!')}
        </Typography>
        <RankingItem
          rank={game.me.rank}
          participantCount={game.ranking.totalCount ?? 0}
        />
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
          <Box sx={{ display: 'flex', gap: '15px', alignItems: 'center' }}>
            <LeaderboardIcon />
            <Typography variant="h6" sx={{ textAlign: 'left' }}>
              {t('label.playerSummary', {
                defaultValue: 'Your score is: {{score}} points.',
                score: game.me.score,
              })}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', gap: '15px', alignItems: 'center' }}>
            <SpeedIcon />
            <Typography variant="h6" sx={{ textAlign: 'left' }}>
              {t('label.playerAverageSpeed', {
                defaultValue:
                  'You answered in average in {{ averageSpeed }} seconds.',
                averageSpeed: `${formattedSpeed[0]}.${formattedSpeed[1]}`,
              })}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <GenericButton
            variant="text"
            label="Voir le classement"
            onClick={handleOpen}
          />
          <GenericButton
            onClick={leaveGame}
            label={t('action.goBackToHome', 'Go back to home')}
          />
        </Box>
      </Box>
      <Modal open={isRankingModalOpen} onClose={handleClose} hideBackdrop>
        <RankingModal
          gameId={gameId}
          me={game.me!}
          handleClose={handleClose}
          gameContext="gameEnd"
        />
      </Modal>
    </>
  );
};

export default ClientResult;
