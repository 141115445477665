import { useLazyQuery, useMutation } from '@apollo/client';
import { Box, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { AuthContext } from '../../context/auth';
import {
  GameByCodeDocument,
  JoinGameDocument,
  RegisterDocument,
} from '../../graphql/types';
import { useAuthRedirect } from '../../hooks/useAuthRedirect';
import background from '../../images/backgrounds/stars.png';
import TrackTl from '../../images/logo_c_white.svg';
import CoverPlaceholder from '../../images/no_game_cover.png';
import { snackbarErrorConfig } from '../../utils/Snackbars/snackbar.configs';
import GenericButton from '../GenericButton';
import SessionCodeInput from './SessionCodeInput';
import SessionNameInput from './SessionNameInput';

const ClientLogin: React.FC = () => {
  const { t } = useTranslation();
  const authContext = useContext(AuthContext);
  let navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { code } = useParams();

  const [gameCode, setGameCode] = useState<string | null>(code ?? null);

  const [getGameByCode, { loading, data, error }] =
    useLazyQuery(GameByCodeDocument);

  // useEffect to getgameByCode when code changes
  useEffect(() => {
    if (gameCode) {
      getGameByCode({ variables: { code: gameCode } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameCode]);

  const clearGameCode = () => {
    setGameCode(null);
  };

  useEffect(() => {
    if (error) {
      enqueueSnackbar(
        t(
          'error.label.didNotFindGameByCode',
          "Sorry, we couldn't find a game matching this code.",
        ),
        snackbarErrorConfig as any,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, data, error]);

  const [register] = useMutation(RegisterDocument);
  const [joinGame] = useMutation(JoinGameDocument);

  useAuthRedirect();

  const enterGame = async (name: string) => {
    if (!data?.gameByCode) {
      return;
    }
    try {
      const registerResponse = await register({
        variables: {
          name,
          spaceId: data.gameByCode.space.id,
        },
      });
      if (registerResponse && registerResponse.data) {
        authContext.login(registerResponse.data.register.accessToken);
        await joinGame({
          variables: {
            id: data.gameByCode.id,
          },
        });
        navigate(`/games/${data.gameByCode.id}/play`);
      }
    } catch (error: any) {
      enqueueSnackbar(error.message, snackbarErrorConfig);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        padding: '2.5rem 1rem',
        backgroundImage: `url(${background})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'flex-start',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            height: '40%',
            aspectRatio: '1',
            backgroundImage: `url(${TrackTl})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        ></Box>
        <Typography variant="h4">{t('label.blindtest', 'QUIZ')}</Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flex: data ? 3 : 2,
          flexDirection: 'column',
          gap: '20px',
          alignItems: 'center',
          width: '100%',
        }}
      >
        {gameCode && data && data.gameByCode ? (
          <>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'flex-start',
                width: '100%',
                gap: '10px',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  minWidth: '200px',
                  width: '20rem',
                  maxWidth: '70%',
                  aspectRatio: '2.2',
                  backgroundImage: `url(${
                    data.gameByCode.image ?? CoverPlaceholder
                  })`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  borderRadius: '10px',
                }}
              />
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: 'inherit',
                  alignItems: 'center',
                  gap: '15px',
                }}
              >
                <Box sx={{ width: '100%' }}>
                  <Typography variant="h4" sx={{ textAlign: 'center' }}>
                    {data.gameByCode.name}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ color: 'primary.main', textAlign: 'center' }}
                  >
                    {data.gameByCode.code}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <SessionNameInput enterSession={enterGame} />
            <GenericButton
              onClick={clearGameCode}
              variant="text"
              label={t('action.cancel', 'Cancel')}
              size="small"
            />
          </>
        ) : (
          <SessionCodeInput setInputCode={setGameCode} />
        )}
      </Box>
    </Box>
  );
};

export default ClientLogin;
