import { Box, Typography } from '@mui/material';
import React, { useContext, useMemo, useState } from 'react';
import {
  AdminGameByIdQuery,
  CloseGameDocument,
  EndTurnDocument,
  StartTurnDocument,
} from '../../../graphql/types';
import AudioPlayer from '../../AudioPlayer';
import QuestionChoiceItem from '../../QuestionChoiceItem';
import AnswerStats from './AnswerStats';
import AnswerCountGauge from './AnswerCountGauge';
import { ParticipantsAnswerContainer, QuestionTitle } from './styled';
import TurnSummary from './TurnSummary';
import FullscreenLoader from '../../FullscreenLoader';
import { useMutation } from '@apollo/client';
import GenericButton from '../../GenericButton';
import { useTranslation } from 'react-i18next';
import QuestionPictureContainer from '../../QuestionPictureContainer';
import { AuthContext } from '../../../context/auth';

const AdminGamePlay: React.FC<{
  game: AdminGameByIdQuery['gameById'];
}> = ({ game }) => {
  const { t } = useTranslation();
  const currentTurn = game.currentTurn;
  const currentQuestion = currentTurn?.question;
  const currentChoices = currentQuestion?.choices;
  const { playerSettings } = useContext(AuthContext);
  const [playerVolume, setPlayerVolume] = useState(playerSettings.volume);

  const rightAnswer = useMemo(() => {
    if (currentChoices) {
      const choice = currentChoices.find((choice) => choice.isCorrect);
      return choice?.value || '';
    } else {
      return '';
    }
  }, [currentChoices]);

  const [startTurn, startTurnTools] = useMutation(StartTurnDocument);
  const [closeGame, closeGameTools] = useMutation(CloseGameDocument);
  const [endTurn, endTurnTools] = useMutation(EndTurnDocument);

  if (!currentTurn) {
    return <FullscreenLoader />;
  }

  const revealAnswer = async () => {
    await endTurn({
      variables: currentTurn.id
        ? {
            id: currentTurn.id,
          }
        : undefined,
    });
  };

  const handleAudioTransition = async () => {
    let i = playerVolume;
    return await new Promise((resolve) => {
      const fadeInterval = setInterval(() => {
        if (i > 10) {
          i = i - 10;
          setPlayerVolume(i);
        } else {
          i = 0;
          clearInterval(fadeInterval);
          resolve(true);
        }
      }, 200);
    });
  };

  const startNextTurn = async () => {
    const initialVolume = playerVolume;
    if (
      currentQuestion &&
      currentQuestion.media &&
      /(.mp3)|(.mp4)|(.m4a)/.test(currentQuestion.media)
    ) {
      await handleAudioTransition();
    }
    if (currentTurn.index !== game.turnCount) {
      await startTurn({
        variables: {
          gameId: game.id,
        },
      });
      setTimeout(() => {
        setPlayerVolume(initialVolume);
      }, 500);
    } else {
      await closeGame({
        variables: {
          id: game.id,
        },
      });
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        overflow: 'auto',
        paddingX: '20px',
        alignContent: 'space-between',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          width: '100%',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: '1rem',
        }}
      >
        <Typography variant="h2" sx={{ fontSize: 70 }}>
          {game.currentTurn?.index}
        </Typography>
        <Typography variant="h2" sx={{ color: 'text.secondary' }}>
          /{game.turnCount}
        </Typography>
      </Box>
      <QuestionTitle>{currentTurn?.question.title}</QuestionTitle>
      {currentTurn?.endedAt && (
        <Typography
          variant="h3"
          sx={{ color: 'success.main', textAlign: 'center' }}
        >
          {rightAnswer}
        </Typography>
      )}
      {currentTurn?.question?.media && (
        <Box
          sx={{
            display: 'flex',
            flex: 3,
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
          }}
        >
          {/(.mp3)|(.mp4)|(.m4a)/.test(currentTurn.question.media) ? (
            <AudioPlayer
              src={currentTurn.question.media}
              autoplay
              volume={playerVolume}
              onVolumeChanged={(volume) => setPlayerVolume(volume)}
            />
          ) : (
            <QuestionPictureContainer picture={currentTurn.question.media} />
          )}
        </Box>
      )}
      {!game.currentTurn?.endedAt ? (
        <>
          <Box
            sx={{
              flex: 2,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: '50% auto',
                gap: '30px',
                flex: 1,
              }}
            >
              {currentTurn?.question.choices.map((choice) => (
                <QuestionChoiceItem key={choice.id} choice={choice} />
              ))}
            </Box>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center', flex: 1 }}>
            <AnswerCountGauge game={game} />
          </Box>
          <Box
            sx={{
              marginBottom: '1.5rem',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <GenericButton
              onClick={revealAnswer}
              activationDelay={5000}
              name="endTurn"
              disabled={endTurnTools.loading}
              label={t('button.label.displayAnswer', 'Reveal Answer')}
            />
          </Box>
        </>
      ) : (
        <>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              flex: 3,
            }}
          >
            <AnswerStats
              participantCount={game.ranking.totalCount}
              answersCount={currentTurn.answers.totalCount}
              correctAnswersCount={currentTurn.correctAnswers.totalCount}
            />
            <ParticipantsAnswerContainer>
              <TurnSummary turnId={currentTurn.id} withScore />
            </ParticipantsAnswerContainer>
          </Box>
          <Box
            sx={{
              marginBottom: '1.5rem',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <GenericButton
              onClick={startNextTurn}
              activationDelay={5000}
              name="nextQuestion"
              disabled={startTurnTools.loading || closeGameTools.loading}
              label={
                currentTurn.index !== game.turnCount
                  ? t('label.nextQuestion', 'Next Question')
                  : t('label.goToResults', 'Results')
              }
            />
          </Box>
        </>
      )}
    </Box>
  );
};

export default AdminGamePlay;
