import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import QRCode from 'react-qr-code';

const LiveShowGameLobby: React.FC<{ gameCode: string }> = ({ gameCode }) => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        alignItems: 'center',
        paddingTop: '50px',
      }}
    >
      <Typography variant="h2" sx={{ fontSize: '64px' }}>
        {t('call.gameWillStart', 'The game is about to start, join us!')}
      </Typography>
      <Box
        sx={{ display: 'flex', flexDirection: 'row', width: '100%', flex: 1 }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            flex: 2,
            gap: '10px',
          }}
        >
          <Typography variant="h2">
            {t('call.qrCodeScan', 'Scan me!')}
          </Typography>
          <Box
            sx={{
              padding: '20px',
              backgroundColor: '#FFFFFF',
              borderRadius: '5px',
            }}
          >
            <QRCode
              value={`https://quiz-app.tracktl.in/${gameCode}`}
              size={300}
            />
          </Box>
        </Box>
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography
            variant="h2"
            sx={{
              color: 'primary.main',
              textTransform: 'uppercase',
            }}
          >
            {t('label.or', 'or')}
          </Typography>
        </Box>
        <Box
          sx={{
            flex: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '24px',
            textAlign: 'center',
          }}
        >
          <div>
            <Typography variant="h2">
              {t('label.howToJoin.firstPartAbridged', 'Go to')}
            </Typography>
            <Typography
              variant="h2"
              sx={{ color: 'primary.main', fontSize: '40px' }}
            >
              quiz-app.tracktl.in
            </Typography>
          </div>
          <div>
            <Typography variant="h2">
              {t('label.howToJoin.secondPart')}
            </Typography>
            <Typography
              variant="h2"
              sx={{ color: 'primary.main', fontSize: '48px' }}
            >
              {' '}
              {gameCode}
            </Typography>
          </div>
        </Box>
      </Box>
    </Box>
  );
};

export default LiveShowGameLobby;
